import { Col, Container, Row, Spinner } from 'reactstrap';

const Loading = () => {
    return <Container 
    style={{
        position: 'fixed',
        top: 0, left: 0,
        right: 0, width: '100vw',
        height: '100vh',
        backgroundColor: '#7f7f7f42',
        margin: 'unset',
        maxWidth: 'unset',
        zIndex: 10000000,

    }}
    >
        <Row className="justify-content-center align-items-center" style={{ margin: 'auto', height: '100%' }}>
            <Col className="text-center">
                <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
                <p>Đang tải ...</p>
            </Col>
        </Row>
    </Container>
}

export default Loading