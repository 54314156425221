import React from 'react'

type Props = {
    status: number
}

const UserStatus = ({ status }: Props) => {
    return (
        <>
            {status == 0 && (<span className="badge text-uppercase bg-warning-subtle text-warning">Dừng hoạt động</span>)}
            {status == 1 && (<span className="badge text-uppercase bg-success-subtle text-success">Hoạt động</span>)}
        </>
    )
}

export default UserStatus