import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import ProfileReducer from "./auth/profile/reducer";


// Dashboard Ecommerce
import DashboardEcommerceReducer from "./dashboardEcommerce/reducer";
import CommonReducer from "./common/reducer"
// User
import UserReducer from "./user/reducer";
// Principal
import PrincipalReducer from "./principal/reducer";
//Calendar
import CalendarReducer from "./calendar/reducer";
// Tasks
import TasksReducer from "./tasks/reducer";
// Student
import StudentReducer from "./student/reducer"


const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Profile: ProfileReducer,
    User: UserReducer,
    Common: CommonReducer,
    Calendar: CalendarReducer,
    Tasks: TasksReducer,
    Principal: PrincipalReducer,
    Student: StudentReducer
});

export default rootReducer;