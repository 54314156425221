import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPrincipal as getPrincipalInfo, getPrincipals as getListPrincipal } from "helpers/api/principal";

export const getPrincipal = createAsyncThunk("user/getPrincipal", async (userId: number) => {
  try {
    const response = await getPrincipalInfo(userId);
    return response.data.principal;
  } catch (error) {
    return error;
  }
});

export const getPrincipals = createAsyncThunk("user/getPrincipals", async (params: any) => {
  try {
    const response = await getListPrincipal(params);
    return response.data;
  } catch (error) {
    return error;
  }
});
