import { createSlice } from "@reduxjs/toolkit";
import { getTaskList, addNewTask, updateTask, deleteTask, updateCardData, deleteKanban, getTasks, addCardData, getTask, addComment, updateStatusTask } from './thunk';
import { getTaskConfig } from "helpers/function";
export const initialState = {
    taskList: [],
    tasks: [],
    task: {},
    loading: false
};
const TasksSlice = createSlice({
    name: 'Tasks',
    initialState,
    reducers: {
        forceUpdateTasks(state, {payload}) {
            console.log('state, payload', state, payload);
            state.tasks = payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTaskList.fulfilled, (state: any, action: any) => {
            state.taskList = action.payload;
            state.isTaskCreated = false;
            state.isTaskSuccess = true;
        });
        builder.addCase(getTaskList.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
            state.isTaskCreated = false;
            state.isTaskSuccess = true;
        });
        builder.addCase(addNewTask.fulfilled, (state: any, action: any) => {
            state.taskList.push(action.payload);
            state.isTaskCreated = true;
            state.isTaskAdd = true;
            state.isTaskAddFail = false;
        });
        builder.addCase(addNewTask.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
            state.isTaskAdd = false;
            state.isTaskAddFail = true;
        });
        builder.addCase(updateTask.fulfilled, (state: any, action: any) => {
            state.taskList = state.taskList.map((task: any) =>
                task.id === action.payload.id
                    ? { ...task, ...action.payload }
                    : task
            );
            state.isTaskUpdate = true;
            state.isTaskUpdateFail = false;
        });
        builder.addCase(updateTask.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
            state.isTaskUpdate = false;
            state.isTaskUpdateFail = true;
        });
        builder.addCase(deleteTask.fulfilled, (state: any, action: any) => {
            state.taskList = state.taskList.filter((task: any) => task.id.toString() !== action.payload.task.toString());
            state.isTaskDelete = true;
            state.isTaskDeleteFail = false;
        });
        builder.addCase(deleteTask.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
            state.isTaskDelete = false;
            state.isTaskDeleteFail = true;
        });
        // Kanban Board
        builder.addCase(getTasks.pending, (state: any, action: any) => {
            state.loading = true
        });
        builder.addCase(getTasks.fulfilled, (state: any, action: any) => {
            state.tasks = action.payload;
            state.loading = false
        });
        builder.addCase(getTasks.rejected, (state: any, action: any) => {
            state.error = action.payload ? action.payload?.error : null;
        });
        builder.addCase(addCardData.fulfilled, (state: any, action: any) => {
            const existingTaskList = state.tasks.find(
                (kanbanList: any) => kanbanList.id === action.payload.kanId // Tìm để fill vào board
            );

            if (existingTaskList) {
                state.tasks = state.tasks.map((kanbanList: any) => {
                    if (kanbanList.id === action.payload.kanId) {
                        const updatedTaskList = {
                            ...kanbanList,
                            badge: kanbanList.badge++,
                            cards: [...kanbanList?.cards ?? [], action.payload],
                        };
                        return updatedTaskList;
                    }
                    return kanbanList;
                });
            } else {
                state.tasks = [...state.tasks, {
                    badge: 1,
                    color: "success",
                    name: getTaskConfig(action.payload.kanId, 'key', 'label'),
                    id: action.payload.kanId,
                    cards: [action.payload]
                }];
            }
        });
        builder.addCase(addCardData.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(updateCardData.fulfilled, (state: any, action: any) => {

            state.tasks = state.tasks.map((kanbanList: any) => {
                if (kanbanList.id === action.payload.kanId && kanbanList.id !== action.payload.oldKanId) {
                    return {
                        ...kanbanList,
                        cards: kanbanList?.cards ? [...kanbanList?.cards, { ...action.payload }] : [{ ...action.payload }]
                    }
                }
                if (kanbanList.id === action.payload.kanId && kanbanList.id === action.payload.oldKanId) {
                    let index = kanbanList.cards.findIndex((card: any) => card.id === action.payload.id);
                    if (index !== -1) {
                        kanbanList.cards[index] = action.payload;
                    }
                    return kanbanList
                }
                if (kanbanList.id === action.payload?.oldKanId) {
                    return {
                        ...kanbanList,
                        cards: kanbanList.cards.filter((card: any) => card.id != action.payload.id)
                    }
                }
                return kanbanList
            })
        });
        builder.addCase(updateCardData.rejected, (state: any, action: any) => {
            state.error = action.payload || null;
        });
        builder.addCase(deleteKanban.fulfilled, (state: any, action: any) => {
            state.tasks = state.tasks.map((kanbanList: any) => {
                if (kanbanList.id == action.payload.kanId) {
                    const updatedTaskList = {
                        ...kanbanList,
                        badge: kanbanList.badge - 1,
                        cards: kanbanList.cards?.filter((task: any) => {
                            return task.id.toString() !== action.payload.cardId.toString();
                        }),
                    };
                    return updatedTaskList;
                } else {
                    return kanbanList;

                }
            })
        });
        builder.addCase(deleteKanban.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(getTask.pending, (state: any, action: any) => {
            state.loading = true;
        });
        builder.addCase(getTask.fulfilled, (state: any, action: any) => {
            state.task = action.payload || {};
            state.loading = false;
        });
        builder.addCase(addComment.fulfilled, (state: any, action: any) => {
            state.task = {
                ...state.task,
                comments: [...state.task.comments, action.payload]
            }
        });
        builder.addCase(updateStatusTask.pending, (state: any) => {
            state.loading = true;
        });
        // builder.addCase(updateStatusTask.fulfilled, (state: any, action: any) => {
        //     if (action.payload.triggerTo === 'task') {
        //         state.task = action.payload.task;
        //     }
        //     state.loading = false;
        // });
    }
});

export const { forceUpdateTasks } = TasksSlice.actions


export const getStoreTask = (state: any) => state.Tasks?.task
export const getStoreTaskLoading = (state: any) => state.Tasks.loading

export default TasksSlice.reducer;