import { ROLE_ADMIN, ROLE_SALE, STUDENT_STATUSES } from 'common/constant'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserSession } from 'slices/auth/profile/reducer'
import { getStoreBranchs, getStoreUsers, resetUsers } from 'slices/common/reducer'
import { getListBranch, getListSchoolByType, getListUserByBranchAndRole } from 'slices/thunks'
import { IBaseOption } from 'types/Common'
import { IUser } from 'types/User/User'

const useRole = ({ branchId }: { branchId?: string }) => {
    const dispatch: any = useDispatch();
    const userSession = useSelector(getUserSession) as IUser
    const listSale = useSelector(getStoreUsers<IBaseOption>)
    const listOptionStoreBranch = useSelector(getStoreBranchs)
    
    let defaultSaleOption: any = ''
    const listOptionSale = useMemo(() => {
        if (ROLE_SALE.id == userSession.role_id) {
            defaultSaleOption = userSession.id
            return [{ value: userSession.id, label: userSession.name }]
        } else {
            return listSale
        }
    }, [listSale, userSession])

    const disableSaleOption = useMemo(() => {
        if (ROLE_SALE.id == userSession.role_id) {
            return true
        }
        return false
    }, [userSession])

    const listOptionStatus = useMemo(() => {
        return STUDENT_STATUSES.map(status => ({ ...status, value: status.id }))
    }, [])

    const listOptionBranch = useMemo(() => {
        if(ROLE_ADMIN.id == userSession.role_id) {
            return listOptionStoreBranch
        } else {
            return listOptionStoreBranch.filter(branch => branch.value === userSession.branch_id)
        }
    }, [listOptionStoreBranch, userSession])

    useEffect(() => {
        dispatch(getListSchoolByType({}))
        dispatch(getListBranch())
    }, [])

    useEffect(() => {
        if(!branchId) {
            return
        }
        if (userSession.role_id !== ROLE_SALE.id) {
            dispatch(getListUserByBranchAndRole({
                branch_id: branchId,
                type: 'sale'
            }))
        }
    }, [userSession, branchId])

    return { listOptionSale, disableSaleOption, defaultSaleOption, listOptionStatus, listOptionBranch }
}

export default useRole