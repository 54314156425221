import { ROLE_ADMIN, ROLE_BRANCH_MANAGER, ROLE_COLLABORATORS, ROLE_MANAGER, ROLE_PRINCIPAL, ROLE_SALE } from "common/constant";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserSession } from "slices/auth/profile/reducer";
import { IUser } from "types/User/User";
enum MENUKEYS {
    // key label
    ADMINISTRATION = 'administration',
    MANAGEMENT = 'management',
    SCHOOL = 'school',
    // key menu
    DASHBOARD = 'dashboard',
    CALENDAR = 'calendar',
    TASKS = 'tasks',
    USERS = 'users',
    PRINCIPAL = 'principal',
    STUDENTS = 'students',
    BRANCH = 'branch',
    SCHOOL_MG = 'school_mg',
}
const MENU_ADMIN = [
    MENUKEYS.ADMINISTRATION,
    MENUKEYS.MANAGEMENT,
    MENUKEYS.SCHOOL,

    MENUKEYS.DASHBOARD,
    MENUKEYS.CALENDAR,
    MENUKEYS.TASKS,
    MENUKEYS.USERS,
    MENUKEYS.PRINCIPAL,
    MENUKEYS.USERS,
    MENUKEYS.STUDENTS,

    MENUKEYS.BRANCH,
    MENUKEYS.SCHOOL_MG,
]

const MENU_BRANCH_MANAGER = [
    MENUKEYS.ADMINISTRATION,
    MENUKEYS.MANAGEMENT,
    MENUKEYS.SCHOOL,

    MENUKEYS.DASHBOARD,
    MENUKEYS.CALENDAR,
    MENUKEYS.TASKS,
    MENUKEYS.USERS,
    MENUKEYS.STUDENTS,

    MENUKEYS.BRANCH,
    MENUKEYS.SCHOOL_MG,
]

const MENU_MANAGER = [
    MENUKEYS.ADMINISTRATION,
    MENUKEYS.MANAGEMENT,
    MENUKEYS.SCHOOL,

    MENUKEYS.DASHBOARD,
    MENUKEYS.CALENDAR,
    MENUKEYS.TASKS,
    MENUKEYS.USERS,
    MENUKEYS.STUDENTS
]

const MENU_SALE = [
    MENUKEYS.ADMINISTRATION,
    MENUKEYS.SCHOOL,

    MENUKEYS.DASHBOARD,
    MENUKEYS.CALENDAR,
    MENUKEYS.STUDENTS
]

const MENU_COLLABORATORS = [
    MENUKEYS.ADMINISTRATION,
    MENUKEYS.SCHOOL,

    MENUKEYS.DASHBOARD,
    MENUKEYS.CALENDAR,
    MENUKEYS.STUDENTS
]

const MENU_PRINCIPAL = [
    MENUKEYS.ADMINISTRATION,
    MENUKEYS.SCHOOL,

    MENUKEYS.DASHBOARD,
    MENUKEYS.CALENDAR,
    MENUKEYS.STUDENTS
]


const Navdata = () => {
    const userSession = useSelector(getUserSession) as IUser

    const history = useNavigate();
    const [isUser, setIsUser] = useState<boolean>(false);
    const [isStudent, setStudent] = useState<boolean>(false);
    const [isPrincipal, setPrincipal] = useState<boolean>(false);
    const [isBranch, setBranch] = useState<boolean>(false);
    const [isSchool, setSchool] = useState<boolean>(false);




    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems: any = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                const id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'users') {
            setIsUser(false);
        }
        if (iscurrentState === 'Widgets') {
            history("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
    }, [
        history,
        isUser,
    ]);

    const menuItems: any[] = [
        {
            id: MENUKEYS.ADMINISTRATION,
            label: "Quản trị",
            isHeader: true,
        },
        {
            id: MENUKEYS.DASHBOARD,
            label: "Bảng điều khiển",
            icon: "ri-dashboard-2-line",
            link: "/dashboard",
            click: function (e: any) {
                e?.preventDefault();
                setIscurrentState(MENUKEYS.DASHBOARD);
            },
        },
        {
            id: MENUKEYS.CALENDAR,
            label: "Thời khoá biểu",
            icon: "ri-calendar-event-line",
            link: "/calendar",
            click: function (e: any) {
                e?.preventDefault();
                setIscurrentState(MENUKEYS.CALENDAR);
            },
        },
        {
            id: MENUKEYS.TASKS,
            label: "Công việc",
            icon: "ri-task-line",
            link: "/tasks",
            click: function (e: any) {
                e?.preventDefault();
                setIscurrentState(MENUKEYS.TASKS);
            },
        },
        {
            id: MENUKEYS.MANAGEMENT,
            label: "Quản lý",
            isHeader: true,
        },
        {
            id: MENUKEYS.USERS,
            label: "QL Nhân viên",
            icon: "ri-group-line",
            link: "/#",
            click: function (e: any) {
                e?.preventDefault();
                setIsUser(!isUser);
                setIscurrentState(MENUKEYS.USERS);
                updateIconSidebar(e);
            },
            stateVariables: isUser,
            subItems: [
                {
                    id: "users_create",
                    label: "Thêm mới",
                    link: "/user-create",
                    parentId: MENUKEYS.USERS,
                },
                {
                    id: "users_list",
                    label: "Danh sách",
                    link: "/user-list",
                    parentId: MENUKEYS.USERS,
                },

            ],
        },
        {
            id: MENUKEYS.BRANCH,
            label: "QL Chi nhánh",
            icon: "ri-building-line",
            link: "/#",
            click: function (e: any) {
                e?.preventDefault();
                setBranch(!isBranch);
                setIscurrentState(MENUKEYS.BRANCH);
                updateIconSidebar(e);
            },
            stateVariables: isBranch,
            subItems: [
                {
                    id: "branch_create",
                    label: "Thêm mới",
                    link: "/pages-coming-soon/1",
                    parentId: MENUKEYS.BRANCH,
                },
                {
                    id: "branch_list",
                    label: "Danh sách",
                    link: "/pages-coming-soon/2",
                    parentId: MENUKEYS.BRANCH,
                },

            ],
        },

        {
            id: MENUKEYS.SCHOOL,
            label: "Trường học",
            isHeader: true,
        },
        {
            id: MENUKEYS.SCHOOL_MG,
            label: "QL Trường hoc",
            icon: "ri-school-line",
            link: "/#",
            click: function (e: any) {
                e?.preventDefault();
                // setIsUser(!isUser);
                setSchool(!isSchool)
                setIscurrentState(MENUKEYS.SCHOOL_MG);
                updateIconSidebar(e);
            },
            stateVariables: isSchool,
            subItems: [
                {
                    id: "school_create",
                    label: "Thêm mới",
                    link: "/pages-coming-soon/3",
                    parentId: MENUKEYS.SCHOOL_MG,
                },
                {
                    id: "school_list",
                    label: "Danh sách",
                    link: "/pages-coming-soon/4",
                    parentId: MENUKEYS.SCHOOL_MG,
                },

            ],
        },
        {
            id: MENUKEYS.PRINCIPAL,
            label: "QL Hiệu trưởng",
            icon: "ri-user-star-line    ",
            link: "/#",
            click: function (e: any) {
                e?.preventDefault();
                setPrincipal(!isPrincipal);
                setIscurrentState(MENUKEYS.PRINCIPAL);
                updateIconSidebar(e);
            },
            stateVariables: isPrincipal,
            subItems: [
                {
                    id: "principal_create",
                    label: "Thêm mới",
                    link: "/principal-create",
                    parentId: MENUKEYS.PRINCIPAL,
                },
                {
                    id: "principal_list",
                    label: "Danh sách",
                    link: "/principal-list",
                    parentId: MENUKEYS.PRINCIPAL,
                },

            ],
        },
        {
            id: MENUKEYS.STUDENTS,
            label: "QL Học sinh",
            icon: "ri-user-3-line",
            link: "/#",
            click: function (e: any) {
                e?.preventDefault();
                setStudent(!isStudent);
                setIscurrentState(MENUKEYS.STUDENTS);
                updateIconSidebar(e);
            },
            stateVariables: isStudent,
            subItems: [
                {
                    id: "students_create",
                    label: "Thêm mới",
                    link: "/student-create",
                    parentId: MENUKEYS.STUDENTS,
                },
                {
                    id: "students_list",
                    label: "Danh sách",
                    link: "/student-list",
                    parentId: MENUKEYS.STUDENTS,
                },

            ],
        },


    ];


    const menuItemsByRole = useMemo(() => {

        if (!userSession.role_id) {
            return []
        }
        let result
        switch (userSession.role_id) {
            case ROLE_ADMIN.id:
                result = menuItems.filter(menu => {
                    return MENU_ADMIN.includes(menu?.id)
                })
                break;
            case ROLE_BRANCH_MANAGER.id:
                result = menuItems.filter(menu => {
                    return MENU_BRANCH_MANAGER.includes(menu?.id)
                })
                break;
            case ROLE_MANAGER.id:
                result = menuItems.filter(menu => {
                    return MENU_MANAGER.includes(menu?.id)
                })
                break;
            case ROLE_SALE.id:
                result = menuItems.filter(menu => {
                    return MENU_SALE.includes(menu?.id)
                })
                break;
            case ROLE_COLLABORATORS.id:
                result = menuItems.filter(menu => {
                    return MENU_COLLABORATORS.includes(menu?.id)
                })
                break;
            case ROLE_PRINCIPAL.id:
                result = menuItems.filter(menu => {
                    return MENU_PRINCIPAL.includes(menu?.id)
                })
                break;

            default:
                result = []
                break;
        }
        return result
    }, [menuItems, userSession])

    return <React.Fragment>{menuItemsByRole}</React.Fragment>;
};
export default Navdata;