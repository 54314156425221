import { ROLE_ADMIN, ROLE_BRANCH_MANAGER, ROLE_MANAGER, ROLE_PRINCIPAL } from 'common/constant'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getUserSession } from 'slices/auth/profile/reducer'
import { getStoreRoles } from 'slices/common/reducer'
import { IUser } from 'types/User/User'

const useRole = () => {
    const userSession = useSelector(getUserSession) as IUser
    const listRole = useSelector(getStoreRoles)
    const listOptionRole = useMemo(() => {
        if (listRole.length == 0) {
            return []
        }
        const roleToAcceptCreate = listRole.filter(role => { return role.value != ROLE_ADMIN.id && role.value != ROLE_PRINCIPAL.id })
        if (ROLE_ADMIN.id == userSession.role_id) {
            return roleToAcceptCreate
        } else if (ROLE_BRANCH_MANAGER.id == userSession.role_id) {
            const roles = roleToAcceptCreate.filter((role) => {
                return role.value != ROLE_BRANCH_MANAGER.id
            })
            return roles
        } else if (ROLE_MANAGER.id == userSession.role_id) {
            const roles = roleToAcceptCreate.filter((role) => {
                return role.value != ROLE_BRANCH_MANAGER.id && role.value != ROLE_MANAGER.id
            })
            return roles
        }
        return []
    }, [listRole, userSession])

    const hideSelectBranch = useMemo(() => {
        if (ROLE_ADMIN.id == userSession.role_id) {
            return false 
        } else {
            return true
        }
    }, [userSession])

    return { listOptionRole, hideSelectBranch, defaultSelectBranch: userSession.branch_id }
}

export default useRole