import SelectSearch from 'Components/widgets/SelectSearch/SelectSearch';
import { deleteFieldEmpty, fillQueryParam, getQueryParams } from 'helpers/function';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { getStoreSchoolsType1 } from 'slices/common/reducer';
import { getListSchoolByType } from 'slices/common/thunk';

type Props = { onFilter?: (filter: any) => void }

const Filter = ({ onFilter }: Props) => {
    const [filter, setFilter] = useState<Partial<any>>({})
    const listOptionSchool1 = useSelector(getStoreSchoolsType1)
    const dispatch = useDispatch() as any
    const location = useLocation()
    const navigate = useNavigate()
    const handleOnChangeFilter = (key: string, value: string) => {
        setFilter(pre => ({ ...pre, [key]: value }))
    }
    const handleClickFilter = () => {
        onFilter && onFilter(deleteFieldEmpty(filter))
        const params = getQueryParams()
        const queryParams = fillQueryParam(deleteFieldEmpty({ ...params, ...filter }))
        navigate(location.pathname + queryParams)
    }
    useEffect(() => {
        const params = getQueryParams()
        setFilter(params)
    }, [location])

    useEffect(() => {
        dispatch(getListSchoolByType({}))
    }, [])


    return (
        <Row>
            <Col xxl={4} lg={4} md={6} xs={12}>
                <Label htmlFor="choices-privacy-status-input" className="form-label">Họ tên</Label>
                <Input type="text" className="form-control"
                    value={filter['name'] ?? ''}
                    onChange={(e) => handleOnChangeFilter('name', e.target.value)}
                    placeholder="Họ tên"
                />
            </Col>
            <Col xxl={4} lg={4} md={6} xs={12}>
                <Label htmlFor="choices-privacy-status-input" className="form-label">Trường phụ trách</Label>
                <SelectSearch
                    className="form-select"
                    defaultValue={''}
                    value={filter['school_id'] ?? ''}
                    onChange={(option: any) => {
                        handleOnChangeFilter('school_id', option?.value ?? '')
                    }}
                    options={listOptionSchool1}
                    placeholder='Trường phụ trách'
                    showClear={true}
                />
            </Col>

            <Col md={12} xxl={12} className='text-end mt-2'>
                <Button type="button" onClick={handleClickFilter} color="primary" style={{ paddingInline: 10 }}>
                    <i className="ri-equalizer-fill me-1 align-bottom"></i>{" "}
                    Filters
                </Button>
            </Col>

        </Row>
    );
}

export default Filter