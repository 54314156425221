import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Table } from "reactstrap";

import {
    Column,
    ColumnFiltersState,
    FilterFn,
    PaginationState,
    Table as ReactTable,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';
import { deleteFieldEmpty, fillQueryParam, getQueryParams } from "helpers/function";
import { IPagination } from "types/Common/Common";

// Column Filter
const Filter = ({
    column
}: {
    column: Column<any, unknown>;
    table: ReactTable<any>;
}) => {
    const columnFilterValue = column.getFilterValue();

    return (
        <>
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? '') as string}
                onChange={value => column.setFilterValue(value)}
                placeholder="Search..."
                className="w-36 border shadow rounded"
                list={column.id + 'list'}
            />
            <div className="h-1" />
        </>
    );
};

// Global Filter
const DebouncedInput = ({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number;
    onChange: (value: string | number) => void;
    debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [debounce, onChange, value]);

    return (
        <input {...props} value={value} id="search-bar-0" className="form-control search" onChange={e => setValue(e.target.value)} />
    );
};

interface TableContainerProps {
    columns?: any;
    data: any[];
    handleTaskClick?: any;
    customPageSize?: any;
    tableClass?: any;
    theadClass?: any;
    trClass?: any;
    thClass?: any;
    divClass?: any;
    SearchPlaceholder?: any;
    handleLeadClick?: any;
    handleCompanyClick?: any;
    handleContactClick?: any;
    handleTicketClick?: any;
    onPagination?: (params: PaginationState) => void;
    pagination?: IPagination
}

const TableContainer = ({
    columns,
    data,
    tableClass,
    theadClass,
    trClass,
    thClass,
    divClass,
    customPageSize,
    onPagination,
    pagination: paginationProp
}: TableContainerProps) => {
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');

    const [pagination, setPagination] = React.useState<PaginationState>({
        pageIndex: paginationProp?.current_page! - 1 || 0,
        pageSize: customPageSize || 10,
    })
    const location = useLocation()
    const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
        const itemRank = rankItem(row.getValue(columnId), value);
        addMeta({
            itemRank
        });
        return itemRank.passed;
    };


    const table = useReactTable({
        columns,
        data,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        rowCount: paginationProp?.total_record,
        onPaginationChange: setPagination,
        state: {
            columnFilters,
            globalFilter,
            pagination,
        },
        manualPagination: true,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const {
        getHeaderGroups,
        getRowModel,
        getCanPreviousPage,
        getCanNextPage,
        getPageOptions,
        setPageIndex,
        nextPage,
        previousPage,
        getState
    } = table;

    useEffect(() => {
        const params = getQueryParams()
        const queryParams = fillQueryParam(deleteFieldEmpty({ ...params, ...{ page: pagination.pageIndex + 1 } }))
        window.history.pushState({}, '', location.pathname + queryParams);
    }, [pagination])

    // console.log('getHeaderGroups()', getHeaderGroups()[0].headers[1].column.columnDef);

    return (
        <Fragment>
            <div className={divClass}>
                <Table hover className={tableClass}>
                    <thead className={`table-light ${theadClass}`}>
                        {getHeaderGroups().map((headerGroup: any) => (
                            <tr className={trClass} key={headerGroup.id}>
                                {headerGroup.headers.map((header: any) => (
                                    <th key={header.id} className={thClass}  {...{
                                        onClick: header.column.getToggleSortingHandler(),
                                    }}
                                        style={header.column.columnDef?.style ? header.column.columnDef?.style : {}}
                                    >
                                        {/* 11111 */}
                                        {/* {JSON.stringify()} */}
                                        {header.isPlaceholder ? null : (
                                            <React.Fragment>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{
                                                    asc: ' ',
                                                    desc: ' ',
                                                }
                                                [header.column.getIsSorted() as string] ?? null}
                                                {header.column.getCanFilter() ? (
                                                    <div>
                                                        <Filter column={header.column} table={table} />
                                                    </div>
                                                ) : null}
                                            </React.Fragment>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody>
                        {getRowModel().rows.map((row: any) => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell: any) => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>

            <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                <div className="col-sm">
                    <div className="text-muted">Hiển thị<span className="fw-semibold ms-1">{data.length}</span> / <span className="fw-semibold">{getState().pagination.pageSize}</span> kết quả
                    </div>
                </div>
                <div className="col-sm-auto">
                    <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                        <li className={!getCanPreviousPage() ? "page-item disabled" : "page-item"}>
                            <Link to="#" className="page-link" onClick={previousPage}><i className="ri-arrow-left-line"></i></Link>
                        </li>
                        {getPageOptions().map((item: any, key: number) => (
                            <React.Fragment key={key}>
                                <li className="page-item">
                                    <Link to="#" className={getState().pagination.pageIndex === item ? "page-link active" : "page-link"} onClick={() => setPageIndex(item)}>{item + 1}</Link>
                                </li>
                            </React.Fragment>
                        ))}
                        <li className={!getCanNextPage() ? "page-item disabled" : "page-item"}>
                            <Link to="#" className="page-link" onClick={nextPage}><i className="ri-arrow-right-line"></i></Link>
                        </li>
                    </ul>
                </div>
            </Row>
        </Fragment>
    );
};

export default TableContainer;