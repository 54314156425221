import { createAsyncThunk } from "@reduxjs/toolkit";
import { getStudent as getUserInfo, getStudents as getListStudent } from "helpers/api/student";
import { parseJson } from "helpers/function";

export const getStudent = createAsyncThunk("user/getStudent", async (userId: number) => {
  try {
    const response = await getUserInfo(userId);
    // const student = response.data.student
    // const diploma = parseJson(student.diploma)
    // const graduation_certificate = parseJson(student.graduation_certificate)
    // const school_profile = parseJson(student.school_profile)
    // return { ...student, diploma, graduation_certificate, school_profile };
    return response.data.student
  } catch (error) {
    return error;
  }
});

export const getStudents = createAsyncThunk("user/getStudents", async (params: any) => {
  try {
    const response = await getListStudent(params);
    return response.data;
  } catch (error) {
    return error;
  }
});
