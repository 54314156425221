import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getStoreBranchs } from "slices/common/reducer"
import { getListBranch } from "slices/thunks"

const useBranch = () => {
    const dispatch = useDispatch() as any
    const listOptionStoreBranch = useSelector(getStoreBranchs)

    useEffect(() => {
        dispatch(getListBranch())
    }, [])
    return { listOptionStoreBranch }
}

export default useBranch