import { TPayloadLogin } from "types/Auth/Login";
import { IUser, ICreateUser, IUpdateUser } from "types/User/User";
import { APIClient } from "../api_helper";

import * as URL from "./url";
import { AxiosResponse } from "axios";
import { ICreateStudent, IStudent, IUpdateStudent } from "types/Student/Student";

const api = new APIClient();

export const login = (data: TPayloadLogin) => api.create(URL.LOGIN_PATH, data)
export const createStudent = (data: ICreateStudent): Promise<AxiosResponse<{ student: IStudent }>> => api.create(URL.STUDENT_CREATE_PATH, data)
export const updateStudent = (data: IUpdateStudent, studentId: string): Promise<AxiosResponse<{ student: IStudent }>> => api.update(`${URL.STUDENT_UPDATE_PATH}/${studentId}`, data)
export const getStudent = (studentId: number): Promise<AxiosResponse<{ student: IStudent }>> => api.get(`${URL.STUDENT_DETAIL_PATH}/${studentId}`)
export const getStudents = (params: any): Promise<AxiosResponse<{ students: IStudent[] }>> => api.get(URL.STUDENT_LIST_PATH, params)

