import { TPayloadLogin } from "types/Auth/Login";
import { IUser, ICreateUser, IUpdateUser } from "types/User/User";
import { APIClient } from "../api_helper";

import * as URL from "./url";
import { AxiosResponse } from "axios";

const api = new APIClient();

export const login = (data: TPayloadLogin) => api.create(URL.LOGIN_PATH, data)
export const createUser = (data: ICreateUser): Promise<AxiosResponse<{ user: IUser }>> => api.create(URL.USER_CREATE_PATH, data)
export const updateUser = (data: IUpdateUser, userId: number): Promise<AxiosResponse<{ user: IUser }>> => api.update(`${URL.USER_UPDATE_PATH}/${userId}`, data)
export const getUser = (userId: number): Promise<AxiosResponse<{ user: IUser }>> => api.get(`${URL.USER_DETAIL_PATH}/${userId}`)
export const getUsers = (params: any): Promise<AxiosResponse<{ users: IUser[] }>> => api.get(URL.USER_LIST_PATH, params)
export const updatePasswordUser = (data : any): Promise<AxiosResponse<{ users: IUser[] }>> => api.put(URL.USER_CHANGE_PASSWORD_SPECIFIED, data)

