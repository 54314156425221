import SelectSearch from 'Components/widgets/SelectSearch/SelectSearch';
import { USER_STATUSES } from 'common/constant';
import { deleteFieldEmpty, fillQueryParam, getQueryParams } from 'helpers/function';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { resetUsers } from 'slices/common/reducer';
import useBranch from '../../Hook/useBranch';

type Props = { onFilter?: (filter: any) => void }
type TFilter = {
    name: string,
    status: string,
    branch_id: string,
}
const Filter = ({ onFilter }: Props) => {
    const [filter, setFilter] = useState<Partial<TFilter>>({})
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleOnChangeFilter = (key: string, value: string) => {
        setFilter(pre => ({ ...pre, [key]: value }))
    }
    const { listOptionStoreBranch } = useBranch()

    const handleClickFilter = () => {
        onFilter && onFilter(deleteFieldEmpty(filter))
        const params = getQueryParams()
        const queryParams = fillQueryParam(deleteFieldEmpty({ ...params, ...filter }))
        navigate(location.pathname + queryParams)
    }
    const resetOptionsSale = () => {
        dispatch(resetUsers())
        handleOnChangeFilter('person_in_charge', '')
    }
    useEffect(() => {
        const params = getQueryParams()
        setFilter(params)
    }, [location])
    return (
        <Row>
            <Col xxl={4} lg={4} md={6} xs={12}>
                <Label htmlFor="choices-privacy-status-input" className="form-label">Họ tên</Label>
                <Input type="text"
                    value={filter['name'] ?? ''}
                     className="form-control"
                    onChange={(e) => handleOnChangeFilter('name', e.target.value)}
                    placeholder="Họ tên"
                />
            </Col>
            <Col xxl={4} lg={4} md={6} xs={12}>
                <Label htmlFor="choices-privacy-status-input" className="form-label">Trạng thái</Label>
                <SelectSearch
                    className="form-select"
                    defaultValue={''}
                    value={filter['status'] ?? ''}
                    onChange={(option: any) => {
                        handleOnChangeFilter('status', option?.value ?? '')
                    }}
                    options={USER_STATUSES}
                    placeholder='Trạng thái'
                    showClear={true}
                />
            </Col>
            {
                listOptionStoreBranch.length > 0 && (
                    <Col xxl={4} lg={4} md={6} xs={12}>
                        <Label htmlFor="choices-privacy-status-input" className="form-label">Chi nhánh văn phòng</Label>
                        <SelectSearch
                            className="form-select"
                            defaultValue={''}
                            value={filter['branch_id'] ?? ''}
                            onChange={(option: any) => {
                                handleOnChangeFilter('branch_id', option?.value ?? '')
                                if (!option?.value) {
                                    resetOptionsSale()
                                }
                            }}
                            options={listOptionStoreBranch}
                            placeholder='Chi nhánh văn phòng'
                            showClear={true}
                        />
                    </Col>
                )
            }

            <Col md={12} xxl={12} className='text-end mt-2'>
                <Button type="button" onClick={handleClickFilter} color="primary" style={{ paddingInline: 10 }}>
                    <i className="ri-equalizer-fill me-1 align-bottom"></i>{" "}
                    Tìm kiếm
                </Button>
            </Col>

        </Row>
    );
}

export default Filter