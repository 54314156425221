import BreadCrumb from "Components/Common/BreadCrumb";
import moment from "moment";
import 'moment/locale/vi';
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";


import UserStatus from "Components/widgets/Status/UserStatus";
import TableContainer from "Components/widgets/TableContainer/TableContainer";
import { useSelector } from "react-redux";
import { getStorePaginationPrincipals, getStorePrincipals, getStorePrincipalsPending } from "slices/principal/reducer";
import { getPrincipals } from "slices/thunks";
import Filter from "./Components/Filter";

import 'react-toastify/dist/ReactToastify.css';
import { getQueryParams } from "helpers/function";
import Loader from "Components/Common/Loader";

const ListUser = () => {
  moment.locale('vi');
  const dispatch: any = useDispatch();
  const isLoadingData = useSelector(getStorePrincipalsPending)
  const listPrincipalData = useSelector(getStorePrincipals)
  const pagination = useSelector(getStorePaginationPrincipals)
  const navigate = useNavigate()
  const location = useLocation()

  const columns = useMemo(
    () => [
      {
        header: "Họ tên",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div className="d-flex align-items-center">
              <Link to={`/principal-update/${cell.row.original.id}`} className="fw-medium link-primary">{cell.getValue()}</Link>
            </div>
          </>
        ),
      },
      {
        header: "Trường phụ trách",
        accessorKey: "school_id",
        enableColumnFilter: false,
        cell: (cell: any) => cell.row.original?.school?.name,
      },
      {
        header: "Tỉnh hoạt động",
        accessorKey: "province_id",
        enableColumnFilter: false,
        cell: (cell: any) => cell.row.original?.province?.name,
      },
      {
        header: "Trạng thái",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => <UserStatus status={cell.getValue()} />
      },
      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <UncontrolledDropdown >
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm dropdown"
                tag="button"
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={(e) => {
                    e.preventDefault()
                    navigate(`/principal-update/${cellProps.row.original.id}`)
                  }}>
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                  Cập nhật
                </DropdownItem>

                {/* <DropdownItem divider />

                <DropdownItem
                  onClick={(e) => {
                    e.preventDefault()
                    onSelectUserToChangePassword(cellProps.row.original.id)
                  }}
                >
                  <i className="ri-lock-line align-bottom me-2 text-muted"></i>{" "}
                  Đổi mật khẩu
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );
  useEffect(() => {
    const params = getQueryParams()
    dispatch(getPrincipals(params))
  }, [location])

  useEffect(() => {
    document.title = "Hiệu trưởng | Danh sách hiệu trửng";
  }, [])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Danh sách hiệu trưởng" pageTitle="Hiệu trưởng" />
          {/* <Row>
            <TopData invoiceWidgets={invoiceWidgets} />
          </Row> */}
          <Card id="filter-student">
            <CardHeader className="border-0">
              <div className="d-flex flex-column gap-2 ">
                <h5 className="card-title mb-0 flex-grow-1">Tìm kiếm hiệu trưởng</h5>
                <Filter onFilter={(filter) => console.log('filter', filter)
                } />
              </div>
            </CardHeader>
          </Card>

          <Row>
            <Col lg={12}>
              <Card id="invoiceList">
                <CardHeader className="border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">Danh sách hiệu trưởng</h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        <Link
                          to="/principal-create"
                          className="btn btn-danger"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Thêm hiệu trưởng
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <div>
                    {isLoadingData ? <Loader /> : <TableContainer
                      columns={columns}
                      data={(listPrincipalData || [])}
                      pagination={pagination}
                      customPageSize={15}
                      divClass={"overflow-auto "}
                      tableClass="table-responsive "
                      thClass="text-nowrap"
                      trClass="text-nowrap"
                      theadClass="text-muted text-uppercase"
                      SearchPlaceholder='Search for customer, email, country, status or something...'
                    />
                    }

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ListUser;