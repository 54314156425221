import { toast } from 'react-toastify';

const useNotify = () => {
    const defaultnotify = (message: string) => toast(message, { position: "top-right", hideProgressBar: true, className: 'bg-primary text-white' });
    const successnotify = (message: string) => toast(message, { position: "top-center", hideProgressBar: true, closeOnClick: false, className: 'bg-success text-white' });
    const warningnotify = (message: string) => toast(message, { position: "top-center", hideProgressBar: true, closeOnClick: false, className: 'bg-warning text-white' });
    const errornotify = (message: string) => toast(message, { position: "top-center", hideProgressBar: true, closeOnClick: false, className: 'bg-danger text-white' });

    const topleftnotify = (message: string) => toast(message, { position: "top-left", hideProgressBar: true, className: 'bg-success text-white' });
    const topcenternotify = (message: string) => toast(message, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
    const toprightnotify = (message: string) => toast(message, { position: "top-right", hideProgressBar: true, className: 'bg-success text-white' });
    const bottomleftnotify = (message: string) => toast(message, { position: "bottom-left", hideProgressBar: true, className: 'bg-success text-white' });
    const bottomcenternotify = (message: string) => toast(message, { position: "bottom-center", hideProgressBar: true, className: 'bg-success text-white' });
    const bottomrightnotify = (message: string) => toast(message, { position: "bottom-right", hideProgressBar: true, className: 'bg-success text-white' });

    const offsetnotify = (message: string) => toast(message, { position: "top-right", hideProgressBar: true, className: 'bg-success text-white m-5' });
    const closeiconnotify = (message: string) => toast(message, { position: "top-right", hideProgressBar: true, className: 'bg-success text-white' });
    const durationnotify = (message: string) => toast(message, { position: "top-right", hideProgressBar: false, className: 'bg-success text-white' });

    return {
        defaultnotify,
        successnotify,
        warningnotify,
        errornotify,
        topleftnotify,
        topcenternotify,
        toprightnotify,
        bottomleftnotify,
        bottomcenternotify,
        bottomrightnotify,
        offsetnotify,
        closeiconnotify,
        durationnotify,
    }
}

export default useNotify