

//Import Icons

import { Vietnamese } from 'flatpickr/dist/l10n/vn.js';
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";

import { useFormik } from "formik";
import * as Yup from "yup";

import Flatpickr from "react-flatpickr";
//redux
import { useDispatch } from "react-redux";


import { formatDate, formatTime } from "helpers/function";
import { ICreateEvent, IUpdateEvent } from "types/Event/Event";
import {
  deleteEvent,
  addNewEvent as onAddNewEvent,
  updateEvent as onUpdateEvent
} from "../../../../slices/thunks";
import InviteMember from './InviteMember';
import SelectSearch from 'Components/widgets/SelectSearch/SelectSearch';
import { useSelector } from 'react-redux';
import { getStoreUsers } from 'slices/common/reducer';
import { IBaseOption } from 'types/Common';

type Props = {
  toggle: () => void
  isEdit?: boolean
  event?: any
  modal: boolean
  selectedNewDay?: string
}
const FormEvent = ({ toggle, isEdit, event, modal, selectedNewDay }: Props) => {
  const dispatch: any = useDispatch();
  const listMember = useSelector(getStoreUsers<IBaseOption>)
  console.log('event',event);
  
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (event && event.id) || "",
      title: (event && event.title) || "",
      description: (event && event.description) || "",
      date: selectedNewDay && !isEdit ? [selectedNewDay] : (event && event.date) || [],
      start: (event && event.start) || "",
      end: (event && event.end) || '',
      members: (event && event.members) || []
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Tên sự kiện không được trống!"),
      description: Yup.string().required("Mô tả không được trống!"),
      //   start: Yup.date().required('Start Time is required'),
      //   end: Yup.date().required('End Time is required'),
      date: Yup.array().of(Yup.date()).required('Ngày không được trống!').min(1, 'Ngày không được trống!'),
      members: Yup.array().notRequired()
    }),
    onSubmit: (values) => {

      // console.log('values',values);
      // return
      
      const start_date = formatDate(values.date[0])
      const end_date = formatDate(values.date[1] ?? values.date[0])
      const start_datetime = values.start ? `${start_date} ${formatTime(values.start)}` : ''
      const end_datetime = values.end ? `${end_date} ${formatTime(values.end)}` : ''
      const newEvent: ICreateEvent = {
        title: values["title"],
        type: '1',
        start_date,
        end_date,
        start_datetime,
        end_datetime,
        className: 'bg-primary-subtle',
        description: values["description"],
        members: values.members,
        user_ids_invite: values.members.map((user: IBaseOption) => user.value).toString()
      };
      if (isEdit) {
        // update event
        // newEvent['id'] = event.id 
        const payload: IUpdateEvent = {
          ...newEvent,
          id: event.id
        }
        // console.log('newEvent',newEvent);
        // return

        dispatch(onUpdateEvent({ event: payload, eventId: event.id }));
        validation.resetForm();
      } else {
        // console.log('newEvent',newEvent);
        // return
        // save new event
        dispatch(onAddNewEvent(newEvent));
        validation.resetForm();
      }
      toggle()

    },
  });

  const handleDeleteEvent = () => {
    dispatch(deleteEvent(event.id))
    toggle();
  }
  console.log('validation.values.members',validation.values.members);
  

  return <Modal isOpen={modal} id="event-modal" centered>
    <ModalHeader toggle={toggle} tag="h5" className="p-3 bg-info-subtle modal-title">
      {!!isEdit ? event.title : "Thêm sự kiện"}
    </ModalHeader>
    <ModalBody>
      <Form
        className={"needs-validation"}
        name="event-form"
        id="form-event"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        {/* {!!isEdit ? (
          <div className="text-end">
            <Link
              to="#"
              className="btn btn-sm btn-soft-primary"
              id="edit-event-btn"
              onClick={(e) => {
                e.preventDefault();
                // submitOtherEvent();
                return false;
              }}>
              Edit
            </Link>
          </div>
        ) : null} */}

        <Row className="event-form">
          <Col xs={12}>
            <div className="mb-3">
              <Label className="form-label">Tên sự kiện</Label>
              <Input
                className={"d-block"}
                placeholder="Tên sự kiện"
                type="text"
                name="title"
                id="event-title"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.title || ""} />
              {validation.touched.title && validation.errors.title ? (
                <FormFeedback type="invalid" className="d-block">{validation.errors.title}</FormFeedback>)
                : null}
            </div>
          </Col>
          <Col xs={12}>
            <div className="mb-3">
              <Label>Ngày</Label>
              <div className={"input-group"}>
                <Flatpickr
                  className="form-control"
                  id="event-start-date"
                  name="date"
                  placeholder="Chọn ngày"
                  value={validation.values.date || ""}
                  options={{
                    mode: "range",
                    dateFormat: "d/m/Y",
                    locale: Vietnamese
                  }}
                  onChange={(date: any) => {

                    validation.setFieldValue("date", date)
                  }}
                />
                <span className="input-group-text">
                  <i className="ri-calendar-event-line"></i>
                </span>
              </div>
              {validation.touched.date && validation.errors.date ? (
                <FormFeedback type="invalid" className="d-block">{validation.errors.date} </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col xs={6}>
            <div className="mb-3">
              <Label>Thời gian bắt đầu</Label>
              <div className="input-group">
                <Flatpickr className="form-control"
                  name="start"
                  value={validation.values.start || ""}
                  onChange={(date: any) => validation.setFieldValue("start", date[0])}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    time_24hr: true
                  }} />
                <span className="input-group-text"> <i className="ri-calendar-event-line"></i> </span>
              </div>
              {validation.touched.start && validation.errors.start ? (
                <FormFeedback type="invalid" className="d-block">{validation.errors.start} </FormFeedback>
              ) : null}
            </div>
          </Col>

          <Col xs={6}>
            <div className="mb-3">
              <Label>Thời gian kết thúc</Label>
              <div className="input-group">
                <Flatpickr className="form-control input-group"
                  name="end"
                  value={validation.values.end || ""}
                  onChange={(date: any) => validation.setFieldValue("end", date[0])}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    time_24hr: true

                  }} />
                <span className="input-group-text"> <i className="ri-calendar-event-line"></i> </span>
              </div>
              {validation.touched.end && validation.errors.end ? (
                <FormFeedback type="invalid" className="d-block">{validation.errors.end} </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col xs={12}>
            <div className="mb-3">
              <Label className="form-label">Người tham gia</Label>
              <SelectSearch 
              options={listMember}
              placeholder='Người tham gia'
              value={validation.values.members}
              onChange={(option) => validation.setFieldValue("members", option)}  
              isMulti
              />
              {/* {validation.touched.description && validation.errors.description ? (
                <FormFeedback type="invalid" className="d-block">{validation.errors.description}</FormFeedback>
              ) : null} */}
            </div>
          </Col>
         
          <Col xs={12}>
            <div className="mb-3">
              <Label className="form-label">Mô tả</Label>
              <textarea
                className={"form-control d-block"}
                id="event-description"
                name="description"
                placeholder="Nhập mô tả"
                rows={3}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description}></textarea>
              {validation.touched.description && validation.errors.description ? (
                <FormFeedback type="invalid" className="d-block">{validation.errors.description}</FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <div className="hstack gap-2 justify-content-end">
          {!!isEdit && (
            <button type="button" className="btn btn-soft-danger" id="btn-delete-event" onClick={handleDeleteEvent}>
              <i className="ri-close-line align-bottom"></i> Xóa
            </button>
          )}
          {true &&
            <button type="submit" className="btn btn-success" id="btn-save-event">
              {!isEdit ? "Thêm sự kiện" : "Cập nhật"}
            </button>}
        </div>
      </Form>
    </ModalBody>
  </Modal>
}
export default FormEvent