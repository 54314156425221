import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { getUserSession } from 'slices/auth/profile/reducer';
import { IUser } from 'types/User/User';

const Section = () => {
    const userSession = useSelector(getUserSession) as IUser

    return (
        <React.Fragment>
            <Row className="mb-3 pb-1">
                <Col xs={12}>
                    <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                        <div className="flex-grow-1">
                            <h4 className="fs-16 mb-1">Xin chào, {userSession.name}!</h4>
                            <p className="text-muted mb-0">Đây là những báo cáo hoạt động hôm nay</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Section;