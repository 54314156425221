import { TASK_STATUSES } from 'common/constant'
import { memo, useMemo } from 'react'

type Props = {
    status: string
}

const TaskStatus = ({ status }: Props) => {

    const result = useMemo(() => {
        return TASK_STATUSES.find(task => task.id == status)
    }, [status])
    return (
        <>
            <span className="badge badge bg-secondary-subtle text-secondary">{result?.label}</span>
        </>
    )
}

export default memo(TaskStatus)