import React from 'react';
import { Card, CardBody, Input, Label } from 'reactstrap';
import { ITask } from 'types/Task/Task';
type Props = {
    task: ITask
}
const Summary = ({ task }: Props) => {
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="">
                        <h6 className="mb-3 fw-semibold text-uppercase">{task.title}</h6>
                        <div dangerouslySetInnerHTML={{ __html: task.description }} />

                        {/* <div className="pt-3 border-top border-top-dashed mt-4">
                            <h6 className="mb-3 fw-semibold text-uppercase">Tasks Tags</h6>
                            <div className="hstack flex-wrap gap-2 fs-15">
                                <div className="badge fw-medium bg-info-subtle text-info">UI/UX</div>
                                <div className="badge fw-medium bg-info-subtle text-info">Dashboard</div>
                                <div className="badge fw-medium bg-info-subtle text-info">Design</div>
                            </div>
                        </div> */}
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default Summary;