import BreadCrumb from "Components/Common/BreadCrumb";
import moment from "moment";
import 'moment/locale/vi';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";


import UserStatus from "Components/widgets/Status/UserStatus";
import TableContainer from "Components/widgets/TableContainer/TableContainer";
import { updatePasswordUser } from "helpers/api/user";
import { getQueryParams, transferRoleById } from "helpers/function";
import useNotify from "helpers/hooks/useNotify";
import { useSelector } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { getUserSession } from "slices/auth/profile/reducer";
import { getUsers } from "slices/thunks";
import { getStorePaginationUsers, getStoreUsers, getStoreUsersPending } from "slices/user/reducer";
import { IUser } from "types/User/User";
import { invoiceWidgets } from "../Mock/data";
import ChangePasswordModal from "./Components/ChangePasswordModal";
import TopData from "./Components/TopData";
import Filter from "./Components/Filter";
type TStateChangPasswordModal = {
  isShow: boolean,
  userId: number
}
const ListUser = () => {
  moment.locale('vi');
  const [changePasswordModal, setChangePasswordModal] = useState<TStateChangPasswordModal>({ isShow: false, userId: 0 });
  const userSession = useSelector(getUserSession) as IUser
  const dispatch: any = useDispatch();
  const isLoadingData = useSelector(getStoreUsersPending)
  const listUserData = useSelector(getStoreUsers)
  const pagination = useSelector(getStorePaginationUsers)
  const navigate = useNavigate()
  const location = useLocation()
  const { errornotify, successnotify } = useNotify()

  const onSelectUserToChangePassword = (userId: number) => {
    setChangePasswordModal({
      isShow: true,
      userId
    })
  };
  const onCloseChangePassword = useCallback(
    () => {
      setChangePasswordModal({
        isShow: false,
        userId: 0
      })
    },
    [],
  )

  const handleChangePassword = async (password: string) => {

    const payload = {
      user_id: changePasswordModal.userId,
      password: password,
      password_confirmation: password
    }
    try {
      const res = await updatePasswordUser(payload) as any
      if (res.status) {
        successnotify(res?.message)
        onCloseChangePassword()
      } else {
        errornotify(res?.message)
      }
    } catch (error: any) {
      errornotify(error?.message)
      return error
    }
  }

  const handleValidDate = (date: any) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const columns = useMemo(
    () => [
      {
        header: "Mã nhân viên",
        accessorKey: "code",
        enableColumnFilter: false,
        cell: (cell: any) => {
          if (userSession.id !== cell.row.original.id) {
            return <Link to={`/user-update/${cell.row.original.id}`} className="fw-medium link-primary">{cell.getValue()}</Link>;
          }
          return cell.getValue()
        },
      },
      {
        header: "Họ tên",
        accessorKey: "name",
        enableColumnFilter: false,
        style: { minWidth: 200, display: 'block' },
        cell: (cell: any) => (
          <>
            <div className="d-flex align-items-center">
              {/* {cell.row.original.img ? <img
                src={process.env.REACT_APP_API_URL + "/images/users/" + cell.row.original.img}
                alt=""
                className="avatar-xs rounded-circle me-2"
              /> :
                <div className="flex-shrink-0 avatar-xs me-2">
                  <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                    {cell.row.original.name.charAt(0) + cell.row.original.name.split(" ").slice(-1).toString().charAt(0)}
                  </div>
                </div>} */}
              {cell.getValue()}
            </div>
          </>
        ),
      },
      {
        header: "CCCD/CMT",
        accessorKey: "id_number",
        enableColumnFilter: false,
      },
      {
        header: "Số điện thoại",
        accessorKey: "phone",
        enableColumnFilter: false,
      },
      {
        header: "Ngày sinh",
        accessorKey: "date_of_birth",
        enableColumnFilter: false,
        style: { minWidth: 150, display: 'block' },
        cell: (cell: any) => (
          <>
            {handleValidDate(cell.getValue())}
          </>
        ),
      },
      {
        header: "Chức vụ",
        accessorKey: "role_id",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {transferRoleById(cell.getValue())}
          </>
        ),
      },
      {
        header: "Chi nhánh/văn phòng",
        accessorKey: "branch_name",
        enableColumnFilter: false,
      },
      {
        header: "Tỉnh hoạt động",
        accessorKey: "province_name",
        enableColumnFilter: false,
      },
      {
        header: "Trạng thái",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => <UserStatus status={cell.getValue()} />
      },
      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <UncontrolledDropdown >
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm dropdown"
                tag="button"
                disabled={userSession.id == cellProps.row.original.id}
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={(e) => {
                    e.preventDefault()
                    navigate(`/user-update/${cellProps.row.original.id}`)
                  }}>
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                  Cập nhật
                </DropdownItem>

                {/* <DropdownItem href="/#">
                  <i className="ri-download-2-line align-bottom me-2 text-muted"></i>{" "}
                  Download
                </DropdownItem> */}

                <DropdownItem divider />

                <DropdownItem
                  onClick={(e) => {
                    e.preventDefault()
                    onSelectUserToChangePassword(cellProps.row.original.id)
                  }}
                >
                  <i className="ri-lock-line align-bottom me-2 text-muted"></i>{" "}
                  Đổi mật khẩu
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    const params = getQueryParams()
    dispatch(getUsers(params))
  }, [location])

  useEffect(() => {
    document.title = "Nhân viên | Danh sách nhân viên";
  }, [])


  if (isLoadingData) return 'Đang tải...'
  return (
    <React.Fragment>
      <div className="page-content">
        {
          changePasswordModal.isShow && (
            <ChangePasswordModal
              show={changePasswordModal.isShow}
              userId={changePasswordModal.userId}
              onSaveClick={(password: string) => handleChangePassword(password)}
              onCloseClick={onCloseChangePassword}
            />
          )
        }

        <Container fluid>
          <BreadCrumb title="Danh sách nhân viên" pageTitle="Nhân viên" />
          <Row>
            {/* <TopData invoiceWidgets={invoiceWidgets} /> */}
            <Card id="filter-users">
              <CardHeader className="border-0">
                <div className="d-flex flex-column gap-2 ">
                  <h5 className="card-title mb-0 flex-grow-1">Tìm kiếm nhân viên</h5>
                  <Filter />
                </div>
              </CardHeader>
            </Card>
          </Row>

          <Row>
            <Col lg={12}>
              <Card id="invoiceList">
                <CardHeader className="border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">Danh sách nhân viên</h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        <Link
                          to="/user-create"
                          className="btn btn-danger"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Thêm nhân viên
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  {/* {isInvoiceSuccess && invoices?.length ? ( */}
                  <TableContainer
                    columns={columns}
                    data={(listUserData || [])}
                    pagination={pagination}
                    customPageSize={15}
                    divClass={"overflow-auto "}
                    tableClass="table-responsive "
                    thClass="text-nowrap"
                    trClass="text-nowrap"
                    theadClass="text-muted text-uppercase "
                  />
                  {/* ) : (<Loader error={error} />)
                    } */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ListUser;