import { createSlice } from "@reduxjs/toolkit";

export const initialState :any= {
  user: {},
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
  response: {}
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    apiError(state : any, action : any) {
      state.error = action.payload.data;
      state.loading = true;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    loginSuccess(state, action) {
      state.user = action.payload
      state.loading = false;
      state.errorMsg = false;
      state.error = ''
    },
    loginFalse(state, {payload}) {
      state.user = {}
      state.loading = false;
      state.error = payload
      state.errorMsg = true;
    },
    logoutUserSuccess(state, action) {
      state.isUserLogout = true
      state.user = {}
    },
    loading(state) {
      state.loading = true;
    },
    reset_login_flag(state : any) {
      state.error = null
      state.loading = false;
      state.errorMsg = false;
    },
  },
});

export const {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  reset_login_flag,
  loginFalse,
  loading
} = loginSlice.actions


export default loginSlice.reducer;