import BreadCrumb from "Components/Common/BreadCrumb";
import moment from "moment";
import 'moment/locale/vi';
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";


import TableContainer from "Components/widgets/TableContainer/TableContainer";
import config from "config";
import { useSelector } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { getStorePaginationStudents, getStoreStudentPending, getStoreStudents } from "slices/student/reducer";
import { getStudents } from "slices/thunks";
import Filter from "./Components/Filter";

import StudentStatus from "Components/widgets/Status/StudentStatus";
import { getQueryParams } from "helpers/function";
import Avatar from "Components/widgets/Avatar/Avatar";
const ListStudent = () => {
  moment.locale('vi');
  const dispatch: any = useDispatch();
  const isLoadingData = useSelector(getStoreStudentPending)
  const listStudentData = useSelector(getStoreStudents)
  const pagination = useSelector(getStorePaginationStudents)

  const navigate = useNavigate()
  const location = useLocation()

  const handleValidDate = (date: any) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const columns = useMemo(
    () => [
      {
        header: "Mã học sinh",
        accessorKey: "code",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <Link to={`/student-update/${cell.row.original.id}`} className="fw-medium link-primary">{cell.getValue()}</Link>;
        },
      },
      {
        header: "Họ tên",
        accessorKey: "name",
        enableColumnFilter: false,
        style: { minWidth: 200, display: 'block'},
        cell: (cell: any) => (
          <>
            <div className="d-flex align-items-center">
              <Avatar avatar={cell.row.original.avatar} name={cell.row.original.name}/>
              {cell.getValue()}
            </div>
          </>
        ),
      },
      {
        header: "CCCD/CMT",
        accessorKey: "id_number",
        enableColumnFilter: false,
      },
      {
        header: "Số điện thoại",
        accessorKey: "phone",
        enableColumnFilter: false,
      },
      {
        header: "Ngày sinh",
        accessorKey: "date_of_birth",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {handleValidDate(cell.getValue())}
          </>
        ),
      },
      {
        header: "Trường học",
        accessorKey: "branch_id",
        enableColumnFilter: false,
        style: { minWidth: 200,  display: 'block' },
        cell: (cell: any) => cell.row.original?.school?.name,
      },
      {
        header: "Tỉnh hoạt động",
        accessorKey: "province_id",
        enableColumnFilter: false,
        cell: (cell: any) => cell.row.original?.province?.name,

      },
      {
        header: "Cán bộ phụ trách",
        accessorKey: "branch_id",
        enableColumnFilter: false,
        cell: (cell: any) => cell.row.original?.person_in_charge?.name,
      },
      {
        header: "Trạng thái",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => <StudentStatus status={cell.getValue()} />
      },
      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <UncontrolledDropdown >
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm dropdown"
                tag="button"
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={(e) => {
                    e.preventDefault()
                    navigate(`/student-update/${cellProps.row.original.id}`)
                  }}>
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                  Cập nhật
                </DropdownItem>

                {/* <DropdownItem href="/#">
                  <i className="ri-download-2-line align-bottom me-2 text-muted"></i>{" "}
                  Download
                </DropdownItem> */}

                {/* <DropdownItem divider /> */}

                {/* <DropdownItem
                  onClick={(e) => {
                    e.preventDefault()
                    onSelectUserToChangePassword(cellProps.row.original.id)
                  }}
                >
                  <i className="ri-lock-line align-bottom me-2 text-muted"></i>{" "}
                  Đổi mật khẩu
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );
  useEffect(() => {
    const params = getQueryParams()
    dispatch(getStudents(params))
  }, [location])

  useEffect(() => {
    document.title = "Học sinh | Danh sách học sinh";
  }, [])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Danh sách học sinh" pageTitle="Học sinh" />
          {/* <Row>
            <TopData invoiceWidgets={invoiceWidgets} />
          </Row> */}
          <Card id="filter-student">
            <CardHeader className="border-0">
              <div className="d-flex flex-column gap-2 ">
                <h5 className="card-title mb-0 flex-grow-1">Tìm kiếm học sinh</h5>
                <Filter onFilter={(filter) => console.log('filter', filter)
                } />
              </div>
            </CardHeader>
          </Card>

          <Row>
            <Col lg={12}>
              <Card id="invoiceList">
                <CardHeader className="border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">Danh sách học sinh</h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        <Link
                          to="/student-create"
                          className="btn btn-danger"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Thêm học sinh
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <div>
                    {
                      isLoadingData ? 'Đang tải...' : <TableContainer
                        columns={columns}
                        data={(listStudentData || [])}
                        pagination={pagination}
                        customPageSize={15}
                        divClass={"overflow-auto "}
                        tableClass="table-responsive "
                        thClass="text-nowrap"
                        trClass="text-nowrap"
                        theadClass="text-muted text-uppercase"
                        SearchPlaceholder='Search for customer, email, country, status or something...'
                      />
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ListStudent;