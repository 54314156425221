//Include Both Helper File with needed methods

import { login } from "helpers/api/auth";
import { IResponseLogin, TPayloadLogin } from "types/Auth/Login";
import { IBaseResponse } from "types/Common";
import { profileClear } from "../profile/reducer";
import { apiError, loading, loginFalse, loginSuccess, logoutUserSuccess } from './reducer';

// const fireBaseBackend = getFirebaseBackend();

export const loginUser = (data: TPayloadLogin) => async (dispatch: any) => {
  try {
    dispatch(loading())
    const response: IBaseResponse<IResponseLogin> = await login(data) as any
    // console.log('response', response);

    if (response.status) {
      dispatch(loginSuccess(response.data))
      sessionStorage.setItem("authUser", JSON.stringify(response.data));
    } else {
      dispatch(loginFalse(response.message))
    }
    // return response;
  } catch (error) {
    return error
  }
}

export const logoutUser = () => async (dispatch: any) => {
  try {
    sessionStorage.removeItem("authUser");
    dispatch(profileClear())
    dispatch(logoutUserSuccess(true));
  } catch (error: any) {
    dispatch(apiError(error));
  }
};
