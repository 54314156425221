import { createSlice } from "@reduxjs/toolkit";
import {
    getListBranch,
    getListDistrict,
    getListManager,
    getListProvince,
    getListRole,
    getListSaleByBranch,
    getListSchoolByType,
    getListUserByBranchAndRole,
    getListWard
} from './thunk';
import { IBaseOption } from "types/Common";

export const initialState: any = {
    loading: false,
    provinces: [],
    districts: [],
    wards: [],
    roles: [],
    branchs: [],
    managers: [],
    sales: [],
    schools: [],
    users: [],
    error: {},
};

const CommonSlice = createSlice({
    name: "Common",
    initialState,
    reducers: {
        clearCommonData(state) {
            state.provinces = [];
            state.districts = [];
            state.wards = [];
            state.roles = [];
            state.branchs = [];
            state.managers = [];
            state.sales = [];
            state.schools = [];
            state.users= [];
        },
        resetUsers(state) {
            state.users= [];  
        }
    },
    extraReducers: builder => {
        builder.addCase(getListProvince.fulfilled, (state: any, { payload }) => {
            state.provinces = payload
        });
        builder.addCase(getListDistrict.fulfilled, (state: any, { payload }) => {
            state.districts = payload
        });
        builder.addCase(getListWard.fulfilled, (state: any, { payload }) => {
            state.wards = payload
        });
        builder.addCase(getListRole.fulfilled, (state: any, { payload }) => {
            state.roles = payload
        });
        builder.addCase(getListBranch.fulfilled, (state: any, { payload }) => {
            state.branchs = payload
        });
        builder.addCase(getListManager.fulfilled, (state: any, { payload }) => {
            state.managers = payload
        });
        builder.addCase(getListSaleByBranch.fulfilled, (state: any, { payload }) => {
            state.sales = payload
        });
        builder.addCase(getListSchoolByType.fulfilled, (state: any, { payload }) => {
            state.schools = payload
        });
        builder.addCase(getListUserByBranchAndRole.fulfilled, (state: any, { payload }) => {
            state.users = payload
        });
    },
});
export const getStoreProvinces = (state: any): IBaseOption[] => state.Common.provinces
export const getStoreDistricts = (state: any): IBaseOption[] => state.Common.districts
export const getStoreWards = (state: any): IBaseOption[] => state.Common.wards
export const getStoreRoles = (state: any): IBaseOption[] => state.Common.roles
export const getStoreBranchs = (state: any): IBaseOption[] => state.Common.branchs
export const getStoreManagers = (state: any): IBaseOption[] => state.Common.managers
export const getStoreSales = (state: any): IBaseOption[] => state.Common.sales
export const getStoreSchoolsType1 = (state: any): IBaseOption[] => state.Common.schools.filter((school: any) => school.type == 1)
export const getStoreSchoolsType2 = (state: any): IBaseOption[] => state.Common.schools.filter((school: any) => school.type == 2)
export const getStoreUsers = <Res>(state: any): Res[] => state.Common.users

export const { clearCommonData, resetUsers } = CommonSlice.actions

export default CommonSlice.reducer;