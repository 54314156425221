import { deleteFieldEmpty, fillQueryParam, getQueryParams } from 'helpers/function'
import { useLocation, useNavigate } from 'react-router-dom'
type TFilter = {
    task_name?: string,
    user_ids?: string,
    branch_id?: string
}
const useFilter = () => {
    const location = useLocation()
    // const dispatch = useDispatch()
    const navigate = useNavigate()

    const onFilter = (newParams: TFilter) => {
        const params = getQueryParams()
        const queryParams = fillQueryParam(deleteFieldEmpty({ ...params, ...newParams }))
        navigate(location.pathname + queryParams)
    }
    return { onFilter }
}

export default useFilter