import { Navigate } from "react-router-dom";

import DashboardEcommerce from "../pages/Authenticated/DashboardEcommerce";
import Calendar from "../pages/Authenticated/Calendar";

import Kanbanboard from "../pages/Authenticated/Tasks/KanbanBoard";
import TaskDetail from "../pages/Authenticated/Tasks/TaskDetails";


//AuthenticationInner pages
import BasicSignIn from '../plugins/AuthenticationInner/Login/BasicSignIn';
import CoverSignIn from '../plugins/AuthenticationInner/Login/CoverSignIn';
import BasicPasswReset from '../plugins/AuthenticationInner/PasswordReset/BasicPasswReset';
import BasicSignUp from '../plugins/AuthenticationInner/Register/BasicSignUp';
import CoverSignUp from "../plugins/AuthenticationInner/Register/CoverSignUp";
//pages

import Alt404 from '../plugins/AuthenticationInner/Errors/Alt404';
import Basic404 from '../plugins/AuthenticationInner/Errors/Basic404';
import Cover404 from '../plugins/AuthenticationInner/Errors/Cover404';
import Error500 from '../plugins/AuthenticationInner/Errors/Error500';
import BasicLockScreen from '../plugins/AuthenticationInner/LockScreen/BasicLockScr';
import CoverLockScreen from '../plugins/AuthenticationInner/LockScreen/CoverLockScr';
import BasicLogout from '../plugins/AuthenticationInner/Logout/BasicLogout';
import CoverLogout from '../plugins/AuthenticationInner/Logout/CoverLogout';
import CoverPasswReset from '../plugins/AuthenticationInner/PasswordReset/CoverPasswReset';
import BasicSuccessMsg from '../plugins/AuthenticationInner/SuccessMessage/BasicSuccessMsg';
import CoverSuccessMsg from '../plugins/AuthenticationInner/SuccessMessage/CoverSuccessMsg';
import BasicTwosVerify from '../plugins/AuthenticationInner/TwoStepVerification/BasicTwosVerify';
import CoverTwosVerify from '../plugins/AuthenticationInner/TwoStepVerification/CoverTwosVerify';

import Offlinepage from "../plugins/AuthenticationInner/Errors/Offlinepage";
import BasicPasswCreate from "../plugins/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import CoverPasswCreate from "../plugins/AuthenticationInner/PasswordCreate/CoverPasswCreate";

//APi Key

//login
import Login from "../pages/Authenticating/Login";
import Logout from "../plugins/Authentication/Logout";

//Charts

//Job pages

// Landing Index

// Users
import UserList from "../pages/Authenticated/Users/ListUser/ListUser";
import UserCreate from "../pages/Authenticated/Users/CreateUser/CreateUser";
import UserUpdate from "../pages/Authenticated/Users/UpdateUser/UpdateUser";

// Students
import StudentList from "../pages/Authenticated/Students/ListStudent/ListStudent";
import StudentCreate from "../pages/Authenticated/Students/CreateStudent/CreateStudent";
import StudentUpdate from "../pages/Authenticated/Students/UpdateStudent/UpdateStudent";

// Principals
import PrincipalList from "../pages/Authenticated/Principals/ListPrincipal/ListPrincipal";
import PrincipalCreate from "../pages/Authenticated/Principals/CreatePrincipal/CreatePrincipal";
import PrincipalUpdate from "../pages/Authenticated/Principals/UpdatePrincipal/UpdatePrincipal";
import ComingSoon from "pages/Authenticated/ComingSoon/ComingSoon";



const authProtectedRoutes = [
  // dashboard
  { path: "/dashboard", component: <DashboardEcommerce /> },

  // calendar
  { path: "/calendar", component: <Calendar /> },
  { path: "/tasks", component: <Kanbanboard /> },
  { path: "/tasks-detail/:taskId", component: <TaskDetail /> },


  // Users
  { path: "/user-list", component: <UserList /> },

  { path: "/user-create", component: <UserCreate /> },

  { path: "/user-update/:userId", component: <UserUpdate /> },

  // Students
  { path: "/student-list", component: <StudentList /> },

  { path: "/student-create", component: <StudentCreate /> },

  { path: "/student-update/:studentId", component: <StudentUpdate /> },

  // Principal
  { path: "/principal-list", component: <PrincipalList /> },

  { path: "/principal-create", component: <PrincipalCreate /> },

  { path: "/principal-update/:principalId", component: <PrincipalUpdate /> },
  { path: "/pages-coming-soon/:loremId", component: <ComingSoon /> },

  //Chat

  // eslint-disable-next-line react/display-name
  // {
  //   path: "/",
  //   exact: true,
  //   component: <Navigate to="/dashboard" />,
  // },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes: any = [
  // Authenticating Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  // Authenticated Page


  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: <BasicSignIn /> },
  { path: "/auth-signin-cover", component: <CoverSignIn /> },
  { path: "/auth-signup-basic", component: <BasicSignUp /> },
  { path: "/auth-signup-cover", component: <CoverSignUp /> },
  { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  { path: "/auth-pass-reset-cover", component: <CoverPasswReset /> },
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/auth-lockscreen-cover", component: <CoverLockScreen /> },
  { path: "/auth-logout-basic", component: <BasicLogout /> },
  { path: "/auth-logout-cover", component: <CoverLogout /> },
  { path: "/auth-success-msg-basic", component: <BasicSuccessMsg /> },
  { path: "/auth-success-msg-cover", component: <CoverSuccessMsg /> },
  { path: "/auth-twostep-basic", component: <BasicTwosVerify /> },
  { path: "/auth-twostep-cover", component: <CoverTwosVerify /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },

  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },

];

export { authProtectedRoutes, publicRoutes };
