import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';

import { yupResolver } from "@hookform/resolvers/yup";
import Button from 'Components/widgets/Button/Button';
import ErrorInput from 'Components/widgets/Form/ErrorInput';
import Select from 'Components/widgets/Select/Select';
import { updatePrincipal } from 'helpers/api/principal';
import useNotify from 'helpers/hooks/useNotify';
import { UseFormRegisterReturn, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getStoreDistricts, getStoreProvinces, getStoreSchoolsType1, getStoreWards } from 'slices/common/reducer';
import { getListDistrict, getListProvince, getListSchoolByType, getListWard } from 'slices/common/thunk';
import { getStorePrincipal, getStorePrincipalPending } from 'slices/principal/reducer';
import { getPrincipal } from 'slices/thunks';
import { IPrincipal, IUpdatePrincipal } from 'types/Principal/Principal';
import * as yup from "yup";
import SelectSearch from 'Components/widgets/SelectSearch/SelectSearch';
const createSchema = () => {
    return yup.object().shape({
        email: yup.string().email("Email không hợp lệ").required('Email không được để trống !'),
        province_id: yup.string().required('Tỉnh/thành phố không được để trống !'),
        district_id: yup.string().required('Quận/huyện không được để trống !'),
        ward_id: yup.string().required('Xã phường không được để trống !'),
        name: yup.string().required('Họ tên không được để trống !'),
        status: yup.string().required('Trạng thái không được để trống !'),
        school_id: yup.string().required('Trường học không được để trống !'),
        address: yup.string().notRequired()
    })
}

const CreateUser = () => {
    const navigate = useNavigate()
    const dispatch: any = useDispatch();
    const schema = createSchema();
    const params = useParams() as { principalId: string }
    const { errornotify, successnotify } = useNotify()
    const listOptionSchool1 = useSelector(getStoreSchoolsType1)

    const {
        register,
        formState: { errors },
        control,
        handleSubmit,
        setError,
        setValue,
        getValues,
        clearErrors
    } = useForm({
        resolver: yupResolver(schema),
    });

    const listOptionProvince = useSelector(getStoreProvinces)
    const listOptionDistrict = useSelector(getStoreDistricts)
    const listOptionWard = useSelector(getStoreWards)
    const principalInfo = useSelector(getStorePrincipal) as IPrincipal
    const isLoading = useSelector(getStorePrincipalPending)


    const provinceIdSelected = useWatch({ control, name: 'province_id' })
    const districtIdSelected = useWatch({ control, name: 'district_id' })

    const onGoBack = () => {
        navigate(-1)
    }
    const registerRs = (fieldName: any, options = {}) => {
        type NewType = UseFormRegisterReturn;

        const registeredField: Partial<NewType> = register(fieldName, options);
        const ref = registeredField.ref;
        delete registeredField.ref;
        return { ...registeredField, innerRef: ref };
    }

    const onSave = async (values: IUpdatePrincipal) => {

        try {
            const res = await updatePrincipal(values, Number(params.principalId));
            if (res.status) {
                successnotify("Cập nhật hiệu trưởng thành công")
                navigate('/principal-list')
            } else {
                errornotify("Cập nhật hiệu trưởng thất bại ")
            }
        } catch (error: any) {
            const { errors } = error
            Object.keys(errors).forEach((item: any) => {
                const message = errors[item]
                setError(item, { message: message[0] });
            });
        }
    }

    useEffect(() => {
        if (params.principalId) {
            dispatch(getPrincipal(Number(params.principalId)))
        }
    }, [params])

    useEffect(() => {

        if (Object.keys(principalInfo).length > 0) {
            for (const [key, value] of Object.entries(principalInfo)) {
                setValue(key as any, value)
            }
        }
    }, [principalInfo])

    useEffect(() => {
        dispatch(getListProvince())
        dispatch(getListSchoolByType({}))
    }, [])


    useEffect(() => {
        if (provinceIdSelected) {
            dispatch(getListDistrict(Number(provinceIdSelected)))
        }

    }, [provinceIdSelected])

    useEffect(() => {
        if (districtIdSelected) {
            dispatch(getListWard(Number(districtIdSelected)))
        }
    }, [districtIdSelected])


    useEffect(() => {
        document.title = "Hiệu trưởng | cập nhật";
    }, [])

    if (isLoading) return "Đang tải ..."

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Cập nhật hiệu trưởng" pageTitle="Hiệu trưởng" />
                    <Form
                        onSubmit={handleSubmit(onSave as any)}
                    >
                        <Row>
                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label required" htmlFor="project-title-input">Họ tên</Label>
                                                    <Input {...registerRs("name")} type="text" className="form-control"
                                                        placeholder="Họ tên" invalid={!!errors.name} />
                                                    {errors.name && (
                                                        <FormFeedback type="invalid">{errors.name.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label required" htmlFor="project-title-input">Email</Label>
                                                    <Input {...registerRs("email")} type="email" className="form-control"
                                                        placeholder="Email" invalid={!!errors.email} />
                                                    {errors.email && (
                                                        <FormFeedback type="invalid">{errors.email.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label required" htmlFor="project-title-input">Trường phụ trách</Label>
                                                    <SelectSearch
                                                        invalid={!!errors.school_id}
                                                        value={getValues('school_id')!}
                                                        onChange={(option: any) => {
                                                            if (option?.value) {
                                                                setValue('school_id', option.value)
                                                                clearErrors('school_id')
                                                            } else {
                                                                setValue('school_id', '')
                                                            }
                                                        }}
                                                        placeholder='Trường phụ trách'
                                                        options={listOptionSchool1}
                                                    />
                                                    {
                                                        errors.school_id && (
                                                            <ErrorInput>
                                                                {errors.school_id.message}
                                                            </ErrorInput>
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="choices-priority-input" className="form-label required">Tỉnh/thành phố</Label>
                                                    <Select register={register('province_id')}
                                                        defaultValue={principalInfo.province_id}
                                                        className="form-select"
                                                        options={[{
                                                            label: 'Chọn Tỉnh thành', value: ''
                                                        }, ...listOptionProvince]}
                                                        invalid={!!errors.province_id} />
                                                    {errors.province_id && (
                                                        <ErrorInput>{errors.province_id.message}</ErrorInput>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="choices-status-input required" className="form-label required">Quận/huyện</Label>
                                                    <Select register={register('district_id')}
                                                        className="form-select"
                                                        defaultValue={principalInfo.district_id}
                                                        options={[{
                                                            label: 'Chọn Quận/Huyện', value: ''
                                                        }, ...listOptionDistrict]}
                                                        invalid={!!errors.district_id} />
                                                    {errors.district_id && (
                                                        <ErrorInput>{errors.district_id.message}</ErrorInput>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="choices-status-input required" className="form-label required">Xã/phường</Label>
                                                    <Select register={register('ward_id')}
                                                        className="form-select"
                                                        defaultValue={principalInfo.ward_id}
                                                        options={[{
                                                            label: 'Chọn xã/phường', value: ''
                                                        }, ...listOptionWard]}
                                                        invalid={!!errors.ward_id} />
                                                    {errors.ward_id && (
                                                        <ErrorInput>{errors.ward_id.message}</ErrorInput>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="project-title-input">Địa chỉ</Label>
                                                    <Input {...registerRs("address")} type="text" className="form-control"
                                                        placeholder="Địa chỉ" invalid={!!errors.address} />
                                                    {errors.address && (
                                                        <FormFeedback type="invalid">{errors.address.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={4}>
                                <div className="card">
                                    <CardBody>
                                        <div className="d-flex gap-2 justify-content-between text-end mb-4">
                                            <Button type="button" iconLeft='arrow-left' className="flex-grow-1 btn btn-danger w-sm" onClick={onGoBack}>
                                                Huỷ
                                            </Button>
                                            <Button type="submit" iconRight='save' className="flex-grow-1 btn btn-success w-sm">
                                                Cập nhật
                                            </Button>
                                        </div>
                                        <div>
                                            <Label htmlFor="choices-privacy-status-input" className="form-label required">Trạng thái</Label>
                                            <Select register={register('status')}
                                                defaultValue={principalInfo.status}
                                                className="form-select"
                                                options={[{
                                                    label: 'Hoạt động', value: '1',

                                                }, {
                                                    label: 'Không hoạt động', value: '0',
                                                }]}
                                                invalid={!!errors.status} />
                                            {errors.status && (
                                                <span>{errors.status.message}</span>
                                            )}
                                        </div>
                                    </CardBody>
                                </div>



                            </Col>
                        </Row>
                    </Form>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default CreateUser;