// Authen 
export const LOGIN_PATH = '/admin/login'

// upload
export const COMMON_UPLOAD_PATH = '/upload'

// Master
export const COMMON_PROVINCE_PATH = '/admin/get-list-province'
export const COMMON_DISTRICT_PATH = '/admin/get-list-district'
export const COMMON_WARD_PATH = '/admin/get-list-ward'
export const COMMON_ROLE_PATH = '/admin/get-role'
export const COMMON_BRANCH_PATH = '/admin/get-branch'
export const COMMON_MANAGER_PATH = '/admin/get-manager'
export const COMMON_SALE_BY_BRANCH_PATH = "/admin/custom/get-sale-by-branch-and-role"
export const COMMON_SCHOOL_PATH = "/admin/get-list-school"
export const COMMON_USERS_PATH = "/admin/custom/get-user-by-branch-and-role"


//User
export const USER_CREATE_PATH = '/admin/users'
export const USER_UPDATE_PATH = USER_CREATE_PATH
export const USER_DETAIL_PATH = USER_CREATE_PATH
export const USER_LIST_PATH = USER_CREATE_PATH
export const USER_CHANGE_PASSWORD_SPECIFIED = '/admin/change-password-for-user-specified'

// Principal
export const PRINCIPAL_CREATE_PATH = '/admin/principals'
export const PRINCIPAL_UPDATE_PATH = PRINCIPAL_CREATE_PATH
export const PRINCIPAL_DETAIL_PATH = PRINCIPAL_CREATE_PATH
export const PRINCIPAL_LIST_PATH = PRINCIPAL_CREATE_PATH

// Student
export const STUDENT_CREATE_PATH = '/admin/students'
export const STUDENT_UPDATE_PATH = STUDENT_CREATE_PATH
export const STUDENT_DETAIL_PATH = STUDENT_CREATE_PATH
export const STUDENT_LIST_PATH = STUDENT_CREATE_PATH

// Task
export const TASK_CREATE_PATH = '/admin/tasks'
export const TASK_UPDATE_PATH = TASK_CREATE_PATH
export const TASK_DELETE_PATH = TASK_CREATE_PATH
export const TASK_DETAIL_PATH = TASK_CREATE_PATH
export const TASK_CHANGE_STATUS_PATH = '/admin/custom/tasks/change-status'
export const TASK_LIST_PATH = TASK_CREATE_PATH

// Task
export const COMMENT_CREATE_PATH = '/admin/task-comments'

// Event
export const EVENT_CTEATE_PATH = '/admin/events'
export const EVENT_UPDATE_PATH = EVENT_CTEATE_PATH
export const EVENT_DELETE_PATH = EVENT_CTEATE_PATH
export const EVENT_LIST_PATH = EVENT_CTEATE_PATH







