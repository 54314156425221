import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
  // getEvents as getEventsApi,
  addNewEvent as addNewEventApi,
  // updateEvent as updateEventApi,
  // deleteEvent as deleteEventApi,
  getCategories as getCategoriesApi,
  getUpCommingEvent as getUpCommingEventApi
} from "../../helpers/fakebackend_helper";

import {
  createEvent as createNewEventApi,
  updateEvent as updateEventApi,
  deleteEvent as deleteEventApi,
  getEvents as getEventsApi,

} from "../../helpers/api/event"
import { ICreateEvent, IQueryEvents, IUpdateEvent } from "types/Event/Event";
import { formatDateToISOString } from "helpers/function";

export const getEvents = createAsyncThunk("calendar/getEvents", async (params: IQueryEvents) => {
  try {
    const response = await getEventsApi(params);
    return response.data.events.map(event => ({...event,
      members: event.users.map(user => ({value: user.id, label: user.name})),
      start: formatDateToISOString(event.start_datetime || event.start_date),
      end: formatDateToISOString(event.end_datetime ||event.end_date),
      className: 'bg-primary-subtle'
    }));
  } catch (error) {
    return error;
  }
});

export const addNewEvent = createAsyncThunk("calendar/addNewEvent", async (event:ICreateEvent) => {
  try {
    const response = await createNewEventApi(event);
    const eventRes = response.data.event
    const transferRes = {
      ...event, 
      ...eventRes,
      start: formatDateToISOString(eventRes.start_datetime || eventRes.start_date),
      end: formatDateToISOString(eventRes.end_datetime ||eventRes.end_date)
    }
    return transferRes;
  } catch (error) {
    return error;
  }
});

export const updateEvent = createAsyncThunk("calendar/updateEvent", async ({event, eventId}:{event:IUpdateEvent, eventId: string}) => {
  try {
    const response = await updateEventApi(event, eventId);
    const eventRes = response.data.event
    const transferRes = {
      ...event, 
      ...eventRes,
      start: formatDateToISOString(eventRes.start_datetime || eventRes.start_date),
      end: formatDateToISOString(eventRes.end_datetime ||eventRes.end_date)
    }
    return transferRes;
  } catch (error) {
    return error;
  }
});

export const deleteEvent = createAsyncThunk("calendar/deleteEvent", async (eventId:string) => {
  try {
     await deleteEventApi(eventId);
    return eventId;
  } catch (error) {
    return error;
  }
});

export const getCategories = createAsyncThunk("calendar/getCategories", async () => {
  try{
    const response = getCategoriesApi();
    return response;
  }catch (error) {
    return error;
  }
})

export const getUpCommingEvent = createAsyncThunk("calendar/getUpCommingEvent", async () => {
  try{
    const response = getUpCommingEventApi()
    return response;
  }catch (error) {
    return error;
  }
})

export const resetCalendar = createAsyncThunk("calendar/resetCalendar", async () => {
  try{
    const response = '';
    return response;
  }catch (error) {
    return error;
  }
})