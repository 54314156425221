import React, { useEffect, useMemo } from 'react';
import { Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';

import { yupResolver } from "@hookform/resolvers/yup";
import Button from 'Components/widgets/Button/Button';
import ErrorInput from 'Components/widgets/Form/ErrorInput';
import Select from 'Components/widgets/Select/Select';
import { OPTIONS_GENDER, REGEX_PHONE, ROLE_ADMIN, ROLE_BRANCH_MANAGER, ROLE_MANAGER } from 'common/constant';
import { updateUser } from 'helpers/api/user';
import useNotify from 'helpers/hooks/useNotify';
import { UseFormRegisterReturn, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getStoreBranchs, getStoreDistricts, getStoreManagers, getStoreProvinces, getStoreWards } from 'slices/common/reducer';
import { getListBranch, getListDistrict, getListManager, getListProvince, getListRole, getListWard } from 'slices/common/thunk';
import { getUser } from 'slices/thunks';
import { getStoreUser, getStoreUserPending } from 'slices/user/reducer';
import { IUpdateUser, IUser } from 'types/User/User';
import * as yup from "yup";
import useRole from '../Hook/useRole';
const createSchema = () => {
    return yup.object().shape({
        email: yup.string().email("Email không hợp lệ").required('Email không được để trống !'),
        date_of_birth: yup.string().required('Ngày sinh không được để trống !'),
        province_id: yup.string().required('Tỉnh/thành phố không được để trống !'),
        district_id: yup.string().required('Quận/huyện không được để trống !'),
        ward_id: yup.string().required('Xã phường không được để trống !'),
        phone: yup.string().test('isValidPhone', 'Số điện thoại phải là số và có độ dài 10 ký tự', value => {
            if (!value) return true;
            return REGEX_PHONE.test(value) && value.length === 10;
        }).required('Số điện thoại không được để trống !'),
        gender: yup.string().required('Giới tính không được bỏ trống !'),
        name: yup.string().required('Họ tên không được để trống !'),
        id_number: yup.string().required('Mật khẩu không được để trống !'),
        status: yup.string().required('Mật khẩu không được để trống !'),
        branch_id: yup.string().required('Văn phòng/chi nhánh không được để trống !'),
        role_id: yup.string().required('Chức vụ không được để trống !'),
        manager_id: yup.string().notRequired()
    })
}

const CreateUser = () => {
    const navigate = useNavigate()
    const dispatch: any = useDispatch();
    const schema = createSchema();
    const { errornotify, successnotify } = useNotify()
    const params = useParams() as { userId: string }

    const listOptionProvince = useSelector(getStoreProvinces)
    const listOptionDistrict = useSelector(getStoreDistricts)
    const listOptionWard = useSelector(getStoreWards)
    const listOptionBranch = useSelector(getStoreBranchs)
    const listOptionManager = useSelector(getStoreManagers)
    const userInfo = useSelector(getStoreUser) as IUser

    const isLoading = useSelector(getStoreUserPending)

    const {
        register,
        formState: { errors },
        control,
        handleSubmit,
        setError,
        setValue
    } = useForm({
        resolver: yupResolver(schema),
    });
    const { listOptionRole, hideSelectBranch } = useRole()



    const provinceIdSelected = useWatch({ control, name: 'province_id' })
    const districtIdSelected = useWatch({ control, name: 'district_id' })

    const roleIdSelected = useWatch({ control, name: 'role_id' })
    const branchIdSelected = useWatch({ control, name: 'branch_id' })

    const onGoBack = () => {
        navigate(-1)
    }
    const registerRs = (fieldName: any, options = {}) => {
        type NewType = UseFormRegisterReturn;

        const registeredField: Partial<NewType> = register(fieldName, options);
        const ref = registeredField.ref;
        delete registeredField.ref;
        return { ...registeredField, innerRef: ref };
    }

    const onSave = async (values: IUpdateUser) => {
        if (disableSelectManager) {
            values.manager_id = null
        } else {
            if (!values.manager_id) {
                setError('manager_id', { message: 'Bạn phải chọn người quản lý trực tiếp.' })
                return
            }
        }
        try {
            values.role_id = Number(values.role_id)
            const res = await updateUser(values, Number(params.userId)) as any;
            if (res.status) {
                successnotify("Tạo người dùng thành công")
                navigate('/user-list')
            } else {
                errornotify(res?.message || "Tạo người dùng thất bại")
            }
        } catch (error: any) {
            const { errors } = error
            Object.keys(errors).forEach((item: any) => {
                const message = errors[item]
                setError(item, { message: message[0] });
            });
        }
    }

    const disableSelectManager = useMemo((): boolean => {
        if (!roleIdSelected) {
            return true
        }
        if ([ROLE_ADMIN.id, ROLE_BRANCH_MANAGER.id, ROLE_MANAGER.id].includes(Number(roleIdSelected))) {
            return true
        }
        setValue('manager_id', '')
        return false
    }, [roleIdSelected])

    useEffect(() => {
        if (params.userId) {
            dispatch(getUser(Number(params.userId)))
        }
    }, [params])

    useEffect(() => {

        if (Object.keys(userInfo).length > 0) {
            for (const [key, value] of Object.entries(userInfo)) {
                setValue(key as any, value)
            }
        }
    }, [userInfo])


    useEffect(() => {
        dispatch(getListProvince())
        dispatch(getListBranch())
        dispatch(getListRole())
    }, [])

    useEffect(() => {
        setValue('manager_id', '')
        if (branchIdSelected) {
            dispatch(getListManager(branchIdSelected))
        }
    }, [branchIdSelected])

    useEffect(() => {
        if (provinceIdSelected) {
            dispatch(getListDistrict(Number(provinceIdSelected)))
        }

    }, [provinceIdSelected])

    useEffect(() => {
        if (districtIdSelected) {
            dispatch(getListWard(Number(districtIdSelected)))
        }
    }, [districtIdSelected])


    useEffect(() => {
        document.title = "Nhân viên | Cập nhật";
    }, [])
    if (isLoading) return "Đang tải ..."
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Cập nhật nhân viên" pageTitle="Nhân viên" />
                    <Form
                        onSubmit={handleSubmit(onSave as any)}

                    >
                        <Row>
                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="project-title-input">Mã nhân viên</Label>
                                                    <Input disabled {...registerRs("code")} type="text" className="form-control"
                                                        placeholder="Mã nhân viên" />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label required" htmlFor="project-title-input">Họ tên</Label>
                                                    <Input {...registerRs("name")} type="text" className="form-control"
                                                        placeholder="Họ tên" invalid={!!errors.name} />
                                                    {errors.name && (
                                                        <FormFeedback type="invalid">{errors.name.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label required" htmlFor="project-title-input">Ngày sinh</Label>
                                                    {/* <Flatpickr
                                                        className="form-control"
                                                        name='date_of_birth'
                                                        {...register('date_of_birth') as any}
                                                        id="datepicker-publish-input"
                                                        placeholder="Chọn ngày sinh"
                                                        lang='vi'
                                                        options={{
                                                            altInput: true,
                                                            altFormat: "F j, Y",
                                                            mode: "single",
                                                            dateFormat: "d.m.y",
                                                        }}
                                                    />
                                                    {errors.date_of_birth && (
                                                        <span>{errors.date_of_birth.message}</span>
                                                    )} */}
                                                    <Input
                                                        {...registerRs('date_of_birth')}
                                                        type={"date"}
                                                        className="form-control pe-5"
                                                        placeholder="Enter Password"
                                                        invalid={!!errors.date_of_birth}
                                                    />
                                                    {errors.date_of_birth && (
                                                        <FormFeedback type="invalid">{errors.date_of_birth.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label required" htmlFor="project-title-input">Số điện thoại</Label>
                                                    <Input {...registerRs("phone")} type="text" className="form-control"
                                                        placeholder="Số điện thoại"
                                                        invalid={!!errors.phone} />
                                                    {
                                                        errors.phone && (
                                                            <ErrorInput>
                                                                {errors.phone.message}
                                                            </ErrorInput>
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label required" htmlFor="project-title-input">CCCD/CMT</Label>
                                                    <Input {...registerRs("id_number")} type="text" className="form-control"
                                                        placeholder="CCCD/CMT" invalid={!!errors.id_number} />
                                                    {errors.id_number && (
                                                        <FormFeedback type="invalid">{errors.id_number.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label required" htmlFor="project-title-input">Email</Label>
                                                    <Input {...registerRs("email")} type="email" className="form-control"
                                                        placeholder="Email" invalid={!!errors.email} />
                                                    {errors.email && (
                                                        <FormFeedback type="invalid">{errors.email.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label required" htmlFor="project-title-input">Giới tính</Label>
                                                    <Select register={register('gender')}
                                                        defaultValue={userInfo.gender}
                                                        className="form-select"
                                                        options={OPTIONS_GENDER}
                                                        invalid={!!errors.gender} />
                                                    {errors.gender && (
                                                        <ErrorInput>{errors.gender.message}</ErrorInput>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="choices-priority-input" className="form-label required">Tỉnh/thành phố</Label>
                                                    <Select register={register('province_id')}
                                                        defaultValue={userInfo.province_id}
                                                        className="form-select"
                                                        options={[{
                                                            label: 'Chọn Tỉnh thành', value: ''
                                                        }].concat(listOptionProvince as any)}
                                                        invalid={!!errors.province_id} />
                                                    {errors.province_id && (
                                                        <ErrorInput>{errors.province_id.message}</ErrorInput>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="choices-status-input required" className="form-label required">Quận/huyện</Label>
                                                    <Select register={register('district_id')}
                                                        className="form-select"
                                                        defaultValue={userInfo.district_id}
                                                        options={[{
                                                            label: 'Chọn Quận/Huyện', value: ''
                                                        }].concat(listOptionDistrict as any)}
                                                        invalid={!!errors.district_id} />
                                                    {errors.district_id && (
                                                        <ErrorInput>{errors.district_id.message}</ErrorInput>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="choices-status-input required" className="form-label required">Xã/phường</Label>
                                                    <Select register={register('ward_id')}
                                                        className="form-select"
                                                        defaultValue={userInfo.ward_id}
                                                        options={[{
                                                            label: 'Chọn xã/phường', value: ''
                                                        }].concat(listOptionWard as any)}
                                                        invalid={!!errors.ward_id} />
                                                    {errors.ward_id && (
                                                        <ErrorInput>{errors.ward_id.message}</ErrorInput>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="project-title-input">Địa chỉ</Label>
                                                    <Input {...registerRs("address")} type="text" className="form-control"
                                                        placeholder="Địa chỉ" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <div className="card">
                                    <CardBody>
                                        <div className="mb-3">
                                            <Label htmlFor="choices-categories-input" className="form-label required">Văn phòng/chi nhánh</Label>
                                            <Select register={register('branch_id')}
                                                defaultValue={userInfo.branch_id}
                                                disabled={hideSelectBranch}
                                                className="form-select"
                                                options={[{
                                                    label: 'Chọn Văn phòng/chi nhánh', value: ''
                                                }].concat(listOptionBranch as any)} />
                                            {errors.branch_id && (
                                                <ErrorInput>{errors.branch_id.message}</ErrorInput>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <Label htmlFor="choices-categories-input" className="form-label required">Chức vụ</Label>
                                            <Select register={register('role_id')}
                                                defaultValue={userInfo.role_id}
                                                className="form-select"
                                                options={[{
                                                    label: 'Chọn chức vụ', value: ''
                                                }].concat(listOptionRole as any)} />
                                            {errors.role_id && (
                                                <ErrorInput>{errors.role_id.message}</ErrorInput>
                                            )}
                                        </div>
                                        {
                                            !disableSelectManager && (
                                                <div className="mb-3">
                                                    <Label htmlFor="choices-categories-input" className="form-label">Quản lý trực tiếp</Label>
                                                    <Select register={register('manager_id')}
                                                        defaultValue={userInfo.manager_id}
                                                        className="form-select"
                                                        options={[{
                                                            label: 'Chọn quản lý trực tiếp', value: ''
                                                        }].concat(listOptionManager as any)} />
                                                    {errors.manager_id && (
                                                        <ErrorInput>{errors.manager_id.message}</ErrorInput>
                                                    )}
                                                </div>
                                            )
                                        }
                                    </CardBody>
                                </div>
                            </Col>

                            <Col lg={4}>
                                <div className="card">
                                    <CardBody>
                                        <div className="d-flex gap-2 justify-content-between text-end mb-4">
                                            <Button type="button" iconLeft='arrow-left' className="flex-grow-1 btn btn-danger w-sm" onClick={onGoBack}>
                                                Huỷ
                                            </Button>
                                            <Button type="submit" iconRight='save' className="flex-grow-1 btn btn-success w-sm">
                                                Cập nhật
                                            </Button>
                                        </div>
                                        <div>
                                            <Label htmlFor="choices-privacy-status-input" className="form-label required">Trạng thái</Label>
                                            <Select register={register('status')}
                                                defaultValue={userInfo.status}
                                                className="form-select"
                                                options={[{
                                                    label: 'Hoạt động', value: '1',

                                                }, {
                                                    label: 'Không hoạt động', value: '0',
                                                }]}
                                                invalid={!!errors.status} />
                                            {errors.status && (
                                                <span>{errors.status.message}</span>
                                            )}
                                        </div>
                                    </CardBody>
                                </div>



                            </Col>
                        </Row>
                    </Form>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default CreateUser;