import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

//Import Icons
import FeatherIcon from "feather-icons-react";

import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  Row
} from "reactstrap";


import BootstrapTheme from "@fullcalendar/bootstrap";
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
//redux
import { useDispatch, useSelector } from "react-redux";

import BreadCrumb from "Components/Common/BreadCrumb";
import DeleteModal from "Components/Common/DeleteModal";

//Simple bar
import listPlugin from '@fullcalendar/list';
import SimpleBar from "simplebar-react";
import UpcommingEvents from './UpcommingEvents';

import { DatesSetArg } from "@fullcalendar/core";
import { deleteFieldEmpty, fillQueryParam, formatTime, getQueryParams, formatDate } from "helpers/function";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import {
  getListUserByBranchAndRole,
  deleteEvent as onDeleteEvent,
  getCategories as onGetCategories,
  getEvents as onGetEvents,
  getUpCommingEvent as onGetUpCommingEvent
} from "../../../slices/thunks";
import FormEvent from "./Components/FormEvent";
import Loading from "Components/widgets/Loading/Loading";

const Calender = () => {
  const dispatch: any = useDispatch();
  const [event, setEvent] = useState<any>({});
  const [modal, setModal] = useState<boolean>(false);
  const [selectedNewDay, setSelectedNewDay] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteEvent, setDeleteEvent] = useState<string>('');

  const navigate = useNavigate()
  const location = useLocation()

  const calendarDataProperties = createSelector(
    (state: any) => state.Calendar,
    (state: any) => ({
      events: state?.events,
      categories: state?.categories,
      isEventUpdated: state?.isEventUpdated,
      isLoading: state?.loading
    })
  );
  // Inside your component
  const {
    events, categories, isEventUpdated, isLoading
  } = useSelector(calendarDataProperties);

  useEffect(() => {
    dispatch(getListUserByBranchAndRole({type: 'all', customRespon(user) {
      return {
        value: user.id,
        label: user.name,
      }
    },}))
    dispatch(onGetCategories());
    dispatch(onGetUpCommingEvent());
    new Draggable(document.getElementById("external-events") as HTMLElement, {
      itemSelector: ".external-event",
    });
  }, [dispatch]);

  useEffect(() => {
    const params = getQueryParams()
    dispatch(onGetEvents(params));
  }, [location])

  useEffect(() => {
    if (isEventUpdated) {
      setIsEdit(false);
      setEvent({});
    }
  }, [dispatch, isEventUpdated]);

  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modal) {
      setModal(false);
      setEvent(null);
      setIsEdit(false);
    } else {
      setModal(true);
    }
  };
  /**
   * Handling date click on calendar
   */

  const handleDateClick = (arg: any) => {
    const date = arg["date"];
    setSelectedNewDay(date);
    toggle();
  };

  const fmd = (date: any) => {
    const d = moment(date).utc();

    const formattedDate = d.format('DD MMMM, YYYY');

    return formattedDate;
  };
  /**
   * Handling click on event on calendar
   */

  const handleEventClick = (arg: any) => {
    const event = arg.event;
    
    const st_date = event.start;
    const ed_date = event.end;
    const r_date =
      ed_date == null
        ? fmd(st_date)
        : fmd(st_date) + " to " + fmd(ed_date);
    const er_date = ed_date === null ? [st_date] : [st_date, ed_date];

    setEvent({
      id: event.id,
      title: event.title,
      start: formatTime(event._def.extendedProps.start_datetime),
      end: formatTime(event._def.extendedProps.end_datetime),
      className: event.classNames,
      description: event._def.extendedProps.description,
      date: er_date,
      datetag: r_date,
      members: event._def.extendedProps.members
    });
    setDeleteEvent(event.id);
    setIsEdit(true);
    toggle();
  };
  /**
   * On delete event
   */
  const handleDeleteEvent = () => {
    dispatch(onDeleteEvent(deleteEvent));
    setDeleteModal(false);
  };

  const handleEventCalendarChange = (e: DatesSetArg) => {
    const params = getQueryParams()

    const filter = {
      start_date: formatDate(e.startStr),
      end_date: formatDate(e.endStr)
    }

    const queryParams = fillQueryParam(deleteFieldEmpty({ ...params, ...filter }))
    navigate(location.pathname + queryParams)
  }

  // /**
  //  * On category darg event
  //  */
  // const onDrag = (event: any) => {
  //   event.preventDefault();
  // };

  /**
   * On calendar drop event
   */
  // const onDrop = (event: any) => {
  //   const date = event["date"];
  //   const day = date.getDate();
  //   const month = date.getMonth();
  //   const year = date.getFullYear();

  //   const currectDate = new Date();
  //   const currentHour = currectDate.getHours();
  //   const currentMin = currectDate.getMinutes();
  //   const currentSec = currectDate.getSeconds();
  //   const modifiedDate = new Date(
  //     year,
  //     month,
  //     day,
  //     currentHour,
  //     currentMin,
  //     currentSec
  //   );

  //   const draggedEl = event.draggedEl;
  //   const draggedElclass = draggedEl.className;
  //   if (
  //     draggedEl.classList.contains("external-event") &&
  //     draggedElclass.indexOf("fc-event-draggable") === -1
  //   ) {
  //     const modifiedData = {
  //       id: Math.floor(Math.random() * 1000),
  //       title: draggedEl.innerText,
  //       start: modifiedDate,
  //       className: draggedEl.className,
  //     };
  //     // dispatch(onAddNewEvent(modifiedData));
  //   }
  // };

  useEffect(() => {
    document.title = "Thời khoá biểu | Tổng quan";
  }, [])

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => { setDeleteModal(false) }} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Tổng quan" pageTitle="Thời khoá biểu" />
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={3}>
                  <Card className="card-h-100">
                    <CardBody>
                      <button
                        className="btn btn-primary w-100"
                        id="btn-new-event"
                        onClick={toggle}
                      >
                        <i className="mdi mdi-plus"></i> Thêm sự kiện
                      </button>

                      <div id="external-events" style={{ display: 'none' }}>
                        <br />
                        <p className="text-muted">
                          Drag and drop your event or click in the calendar
                        </p>
                        {categories &&
                          categories.map((category: any) => (
                            <div
                              className={`bg-${category.type}-subtle external-event fc-event text-${category.type}`}
                              key={"cat-" + category.id}
                              draggable
                              onDrag={(event: any) => {
                                // onDrag(event);
                              }}
                            >
                              <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                              {category.title}
                            </div>
                          ))}
                      </div>
                    </CardBody>
                  </Card>
                  <div>
                    <h5 className="mb-1">Sự kiện gần đây</h5>
                    <p className="text-muted">Không có sự kiện nào bị bỏ lỡ</p>
                    <SimpleBar
                      className="pe-2 me-n1 mb-3"
                      style={{ height: "400px" }}
                    >
                      <div id="upcoming-event-list">
                        {events &&
                          (events || []).map((event: any, key: any) => (
                            <React.Fragment key={key}>
                              <UpcommingEvents event={event} />
                            </React.Fragment>
                          ))}
                      </div>
                    </SimpleBar>
                  </div>

                  <Card>
                    <CardBody className="bg-info-subtle">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <FeatherIcon
                            icon="calendar"
                            className="text-info icon-dual-info"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h6 className="fs-15">Chào mừng bạn!</h6>
                          <p className="text-muted mb-0">
                            Sự kiện đa đặt xuất hiện ở đây. Nhấp vào sự kiện để xem chi tiết và quản lý sự kiện của người nộp đơn.
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl={9}>
                  <Card className="card-h-100">
                    <CardBody>
                      <FullCalendar
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          interactionPlugin,
                          listPlugin
                        ]}
                        datesSet={handleEventCalendarChange}
                        locales={allLocales}
                        locale={'vi'}
                        initialView="dayGridMonth"
                        slotDuration={"00:15:00"}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                        }}
                        events={events}
                        editable={true}
                        droppable={false}
                        selectable={true}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        drop={() => { }}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {
                isLoading && (
                  <Loading/>
                )
              }

              <div style={{ clear: "both" }}></div>
              {
                modal && (
                  <FormEvent
                    toggle={toggle}
                    isEdit={isEdit}
                    modal={modal}
                    event={event}
                    selectedNewDay={selectedNewDay}
                  />
                )
              }

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Calender.propTypes = {
  events: PropTypes.any,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default Calender;