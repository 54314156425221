import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';

import { yupResolver } from "@hookform/resolvers/yup";
import Button from 'Components/widgets/Button/Button';
import ErrorInput from 'Components/widgets/Form/ErrorInput';
import Select from 'Components/widgets/Select/Select';
import { OPTIONS_GENDER, REGEX_PHONE } from 'common/constant';
import FeatherIcon from "feather-icons-react";
import useNotify from 'helpers/hooks/useNotify';
import Dropzone from "react-dropzone";
import { UseFormRegisterReturn, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getStoreDistricts, getStoreProvinces, getStoreSchoolsType1, getStoreSchoolsType2, getStoreWards } from 'slices/common/reducer';
import { getListDistrict, getListProvince, getListWard } from 'slices/common/thunk';


import SelectSearch from 'Components/widgets/SelectSearch/SelectSearch';
import config from 'config';
import { uploadFile } from 'helpers/api/common';
import { createStudent } from 'helpers/api/student';
import { ICreateStudent } from 'types/Student/Student';
import * as yup from "yup";
import useRole from '../Hook/useRole';
import GalleryUpload from './Component/GalleryUpload';
const createSchema = () => {
    return yup.object().shape({
        date_of_birth: yup.string().required('Ngày sinh không được để trống !'),
        province_id: yup.string().required('Tỉnh/thành phố không được để trống !'),
        id_number: yup.string().required("CCCD/CMT không được để trống!"),
        district_id: yup.string().required('Quận/huyện không được để trống !'),
        ward_id: yup.string().required('Xã phường không được để trống !'),
        phone: yup.string().test('isValidPhone', 'Số điện thoại phải là số và có độ dài 10 ký tự', value => {
            if (!value) return true;
            return REGEX_PHONE.test(value) && value.length === 10;
        }).required('Số điện thoại không được để trống !'),
        gender: yup.string().required('Giới tính không được bỏ trống !'),
        name: yup.string().required('Họ tên không được để trống !'),
        place_of_birth: yup.string().notRequired(),
        status: yup.string().required('Mật khẩu không được để trống !'),
        passport: yup.string().notRequired(),
        passport_date: yup.string().notRequired(),
        father_name: yup.string().notRequired(),
        mother_name: yup.string().notRequired(),
        father_phone: yup
            .string()
            .notRequired().test('isValidPhone', 'Số điện thoại phải là số và có độ dài 10 ký tự', value => {
                if (!value) return true;
                return REGEX_PHONE.test(value) && value.length === 10;
            }),
        mother_phone: yup
            .string()
            .notRequired().test('isValidPhone', 'Số điện thoại phải là số và có độ dài 10 ký tự', value => {
                if (!value) return true;
                return REGEX_PHONE.test(value) && value.length === 10;
            }),
        person_in_charge: yup.string().notRequired(),
        avatar: yup.string().notRequired(),
        diploma: yup.array().notRequired(),
        graduation_certificate: yup.array().notRequired(),
        school_profile: yup.array().notRequired(),
        country_register: yup.string().notRequired(),
        school_register_id: yup.string().notRequired(),
        major_register: yup.string().notRequired(),
        school_id: yup.string().required('Trường học không được để trống !'),
        branch_id: yup.string().notRequired()
    })
}

const CreateStudent = () => {

    const handleAcceptedAvatar = async (file: File[]) => {
        const resFile = await uploadFile(file[0])
        setValue('avatar', resFile.data.path)
    }

    const navigate = useNavigate()
    const dispatch: any = useDispatch();
    const schema = createSchema();
    const { errornotify, successnotify } = useNotify()
    const {
        register,
        formState: { errors },
        control,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        getValues
    } = useForm({
        resolver: yupResolver(schema),
    });

    const listOptionProvince = useSelector(getStoreProvinces)
    const listOptionDistrict = useSelector(getStoreDistricts)
    const listOptionWard = useSelector(getStoreWards)
    const listOptionSchool1 = useSelector(getStoreSchoolsType1)
    const listOptionSchool2 = useSelector(getStoreSchoolsType2)



    const provinceIdSelected = useWatch({ control, name: 'province_id' })
    const districtIdSelected = useWatch({ control, name: 'district_id' })
    const avatarPreview = useWatch({ control, name: 'avatar' })
    const highSchoolDiplomaPreview = useWatch({ control, name: 'diploma' }) as string[]
    const highSchoolDiplomaDraftPreview = useWatch({ control, name: 'graduation_certificate' }) as string[]
    const highSchoolTranscriptPreview = useWatch({ control, name: 'school_profile' }) as string[]
    const branchIdSelected = useWatch({ control, name: 'branch_id' }) as string
    const personInChageIdSelected = useWatch({ control, name: 'person_in_charge' }) as string
    const { listOptionStatus, listOptionSale, listOptionBranch } = useRole({ branchId: branchIdSelected })
    
    const onGoBack = () => {
        // navigate(-1)
        navigate('/student-list')
    }
    const registerRs = (fieldName: any, options = {}) => {
        type NewType = UseFormRegisterReturn;

        const registeredField: Partial<NewType> = register(fieldName, options);
        const ref = registeredField.ref;
        delete registeredField.ref;
        return { ...registeredField, innerRef: ref };
    }

    const onSave = async (values: ICreateStudent) => {
        try {
            values.study_process = [];
            values.work_progress = [];
            values.family_member = [];
            // Check is null, then set default to empty array
            values.diploma = values.diploma ?? []
            values.graduation_certificate = values.graduation_certificate ?? []
            values.school_profile = values.school_profile ?? []
            values.avatar = values.avatar ?? null
            const res = await createStudent(values);
            if (res.status) {
                successnotify("Tạo học sinh thành công")
                navigate('/student-list')
            } else {
                errornotify("Tạo học sinh thất bại ")
            }
        } catch (error: any) {
            const { errors } = error
            Object.keys(errors).forEach((item: any) => {
                const message = errors[item]
                setError(item, { message: message[0] });
            });
        }
    }

    const handleUpdateUploadedData = (keyForm: string | any, newDatas: string[], currDatas?: string[]) => {
        const newPreviews = [...(currDatas ? currDatas : []), ...(newDatas)]
        setValue(keyForm, newPreviews)
    }
    const handleDeleteUploadedData = (keyForm: string | any, deleteKeyData: string, currDatas: string[]) => {
        const newPreviews = currDatas && currDatas.filter(item => item !== deleteKeyData)
        setValue(keyForm, newPreviews)
    }

    useEffect(() => {
        dispatch(getListProvince())
        // dispatch(getListSchoolByType({}))
    }, [])

    useEffect(() => {
        if (provinceIdSelected) {
            dispatch(getListDistrict(Number(provinceIdSelected)))
            setValue('district_id', '')
        }

    }, [provinceIdSelected])

    useEffect(() => {
        if (districtIdSelected) {
            dispatch(getListWard(Number(districtIdSelected)))
            setValue('ward_id', '')
        }
    }, [districtIdSelected])

    useEffect(() => {
        setValue('person_in_charge', null)
    }, [branchIdSelected])


    useEffect(() => {
        document.title = "Học sinh | tạo mới";
    }, [])
    // console.log('disableSaleOption', disableSaleOption);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Thêm mới học sinh" pageTitle="Học sinh" />
                    <Form
                        onSubmit={handleSubmit(onSave as any)}
                    >
                        <Row>
                            <Col lg={9}>
                                <Card>
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">Thông tin học sinh</h5>
                                    </div>
                                    <CardBody>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label required" htmlFor="project-title-input">Họ tên</Label>
                                                    <Input {...registerRs("name")} type="text" className="form-control"
                                                        placeholder="Họ tên" invalid={!!errors.name} />
                                                    {errors.name && (
                                                        <ErrorInput >{errors.name.message}</ErrorInput>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label required" htmlFor="project-title-input">Giới tính</Label>
                                                    <Select register={register('gender')}
                                                        defaultValue={'male'}
                                                        className="form-select"
                                                        options={OPTIONS_GENDER}
                                                        invalid={!!errors.gender} />
                                                    {errors.gender && (
                                                        <ErrorInput>{errors.gender.message}</ErrorInput>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label required" htmlFor="project-title-input">Ngày sinh</Label>
                                                    <Input
                                                        {...registerRs('date_of_birth')}
                                                        type={"date"}
                                                        className="form-control pe-5"
                                                        invalid={!!errors.date_of_birth}
                                                    />
                                                    {errors.date_of_birth && (
                                                        <FormFeedback type="invalid">{errors.date_of_birth.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="project-title-input">Nơi sinh</Label>
                                                    <Input {...registerRs("place_of_birth")} type="text" className="form-control"
                                                        placeholder="Nơi sinh" invalid={!!errors.place_of_birth} />
                                                    {errors.place_of_birth && (
                                                        <FormFeedback type="invalid">{errors.place_of_birth.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>


                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="choices-priority-input" className="form-label required">Tỉnh/thành phố</Label>
                                                    <SelectSearch
                                                        invalid={!!errors.province_id}
                                                        value={getValues('province_id')}
                                                        onChange={(option: any) => {
                                                            if (option?.value) {
                                                                setValue('province_id', option.value)
                                                                clearErrors('province_id')
                                                            } else {
                                                                setValue('province_id', '')
                                                            }
                                                        }}
                                                        placeholder='Tỉnh/thành phố' options={listOptionProvince}
                                                    />
                                                    {errors.province_id && (
                                                        <ErrorInput>{errors.province_id.message}</ErrorInput>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="choices-status-input required" className="form-label required">Quận/huyện</Label>
                                                    <SelectSearch
                                                        invalid={!!errors.district_id}
                                                        value={getValues('district_id')}
                                                        onChange={(option: any) => {
                                                            if (option?.value) {
                                                                setValue('district_id', option.value)
                                                                clearErrors('district_id')
                                                            } else {
                                                                setValue('district_id', '')
                                                            }
                                                        }}
                                                        placeholder='Quận/Huyện' options={listOptionDistrict}
                                                    />
                                                    {errors.district_id && (
                                                        <ErrorInput>{errors.district_id.message}</ErrorInput>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="choices-status-input required" className="form-label required">Xã/phường</Label>
                                                    <SelectSearch
                                                        invalid={!!errors.ward_id}
                                                        value={getValues('ward_id')}
                                                        onChange={(option: any) => {
                                                            if (option?.value) {
                                                                setValue('ward_id', option.value)
                                                                clearErrors('ward_id')
                                                            } else {
                                                                setValue('ward_id', '')
                                                            }
                                                        }}
                                                        placeholder='Xã/phường' options={listOptionWard}
                                                    />
                                                    {errors.ward_id && (
                                                        <ErrorInput>{errors.ward_id.message}</ErrorInput>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="project-title-input">Địa chỉ</Label>
                                                    <Input {...registerRs("address")} type="text" className="form-control"
                                                        placeholder="Địa chỉ" />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label required" htmlFor="project-title-input">Số điện thoại</Label>
                                                    <Input {...registerRs("phone")} type="text" className="form-control"
                                                        placeholder="Số điện thoại"
                                                        invalid={!!errors.phone} />
                                                    {
                                                        errors.phone && (
                                                            <ErrorInput>
                                                                {errors.phone.message}
                                                            </ErrorInput>
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label required" htmlFor="project-title-input">CCCD/CMT</Label>
                                                    <Input {...registerRs("id_number")} type="text" className="form-control"
                                                        placeholder="CCCD/CMT" invalid={!!errors.id_number} />
                                                    {
                                                        errors.id_number && (
                                                            <ErrorInput>
                                                                {errors.id_number.message}
                                                            </ErrorInput>
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="project-title-input">Số hộ chiếu</Label>
                                                    <Input {...registerRs("passport")} type="text" className="form-control"
                                                        placeholder="Số hộ chiếu" invalid={!!errors.passport} />
                                                    {errors.passport && (
                                                        <ErrorInput>{errors.passport.message}</ErrorInput>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="project-title-input">Ngày cấp</Label>
                                                    <Input
                                                        {...registerRs('passport_date')}
                                                        type={"date"}
                                                        className="form-control pe-5"
                                                        invalid={!!errors.passport_date}
                                                    />
                                                    {errors.passport_date && (
                                                        <ErrorInput>{errors.passport_date.message}</ErrorInput>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label required" htmlFor="project-title-input">Trường học hiện tại</Label>
                                                    <SelectSearch
                                                        invalid={!!errors.school_id}
                                                        value={getValues('school_id')!}
                                                        onChange={(option: any) => {
                                                            if (option?.value) {
                                                                setValue('school_id', option.value)
                                                                clearErrors('school_id')
                                                            } else {
                                                                setValue('school_id', '')
                                                            }
                                                        }}
                                                        placeholder='Trường học hiện tại'
                                                        options={listOptionSchool1}
                                                    />
                                                    {
                                                        errors.school_id && (
                                                            <ErrorInput>
                                                                {errors.school_id.message}
                                                            </ErrorInput>
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">Thông tin phụ huynh</h5>
                                    </div>
                                    <CardBody>

                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="project-title-input">Họ tên bố</Label>
                                                    <Input {...registerRs("father_name")} type="text" className="form-control"
                                                        placeholder="Họ tên bố"
                                                        invalid={!!errors.father_name} />
                                                    {
                                                        errors.father_name && (
                                                            <ErrorInput>
                                                                {errors.father_name.message}
                                                            </ErrorInput>
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="project-title-input">Số điện thoại</Label>
                                                    <Input {...registerRs("father_phone")} type="text" className="form-control"
                                                        placeholder="Số điện thoại"
                                                        invalid={!!errors.father_phone} />
                                                    {
                                                        errors.father_phone && (
                                                            <ErrorInput>
                                                                {errors.father_phone.message}
                                                            </ErrorInput>
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="project-title-input">Họ tên mẹ</Label>
                                                    <Input {...registerRs("mother_name")} type="text" className="form-control"
                                                        placeholder="Họ tên mẹ"
                                                        invalid={!!errors.mother_name} />
                                                    {
                                                        errors.mother_name && (
                                                            <ErrorInput>
                                                                {errors.mother_name.message}
                                                            </ErrorInput>
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-labels" htmlFor="project-title-input">Số điện thoại</Label>
                                                    <Input {...registerRs("mother_phone")} type="text" className="form-control"
                                                        placeholder="Số điện thoại"
                                                        invalid={!!errors.mother_phone} />
                                                    {
                                                        errors.mother_phone && (
                                                            <ErrorInput>
                                                                {errors.mother_phone.message}
                                                            </ErrorInput>
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">Thông tin du học</h5>
                                    </div>
                                    <CardBody>

                                        <Row>
                                            {/* <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="project-title-input">Quốc gia</Label>
                                                    <Input {...registerRs("country_register")} type="text" className="form-control"
                                                        placeholder="Quốc gia"
                                                        invalid={!!errors.country_register} />
                                                    {
                                                        errors.country_register && (
                                                            <ErrorInput>
                                                                {errors.country_register.message}
                                                            </ErrorInput>
                                                        )
                                                    }
                                                </div>
                                            </Col> */}
                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="project-title-input">Tên trường</Label>
                                                    <SelectSearch
                                                        invalid={!!errors.school_register_id}
                                                        value={getValues('school_id')!}
                                                        onChange={(option: any) => {
                                                            if (option?.value) {
                                                                setValue('school_register_id', option.value)
                                                                clearErrors('school_register_id')
                                                            } else {
                                                                setValue('school_register_id', '')
                                                            }
                                                        }}
                                                        placeholder='Tên trường'
                                                        options={listOptionSchool2}
                                                    />
                                                    {
                                                        errors.school_register_id && (
                                                            <ErrorInput>
                                                                {errors.school_register_id.message}
                                                            </ErrorInput>
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="project-title-input">Ngành học đăng kí</Label>
                                                    <Input {...registerRs("major_register")} type="text" className="form-control"
                                                        placeholder="Ngành học đăng kí"
                                                        invalid={!!errors.major_register} />
                                                    {
                                                        errors.major_register && (
                                                            <ErrorInput>
                                                                {errors.major_register.message}
                                                            </ErrorInput>
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </div>
                                <Card>
                                    <CardHeader >
                                        <h5 className="card-title mb-0">Thông tin giấy tờ</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <div>
                                            <p className="form-label">Bằng tốt nhiệp THPT </p>
                                            <GalleryUpload
                                                onRemovePreview={(image) => {
                                                    handleDeleteUploadedData('diploma', image, highSchoolDiplomaPreview)
                                                }} onUploaded={(images) => {
                                                    handleUpdateUploadedData('diploma', images, highSchoolDiplomaPreview)
                                                }}
                                                previews={highSchoolDiplomaPreview} />

                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <div>
                                            <p className="form-label">Giấy chứng nhận tốt nghiệp </p>
                                            <GalleryUpload
                                                onRemovePreview={(image) => {
                                                    handleDeleteUploadedData('graduation_certificate', image, highSchoolDiplomaDraftPreview)
                                                }} onUploaded={(images) => {
                                                    handleUpdateUploadedData('graduation_certificate', images, highSchoolDiplomaDraftPreview)
                                                }} previews={highSchoolDiplomaDraftPreview} />

                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <div>
                                            <p className="form-label">Học bạ THPT </p>
                                            <GalleryUpload
                                                onRemovePreview={(image) => {
                                                    handleDeleteUploadedData('school_profile', image, highSchoolTranscriptPreview)
                                                }} onUploaded={(images) => {
                                                    handleUpdateUploadedData('school_profile', images, highSchoolTranscriptPreview)
                                                }} previews={highSchoolTranscriptPreview} />

                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={3}>
                                <div className="card">
                                    <CardBody>
                                        <div className="d-flex gap-2 justify-content-between text-end mb-4">
                                            <Button type="button" iconLeft='arrow-left' className="flex-grow-1 btn btn-danger w-sm" onClick={onGoBack}>
                                                Huỷ
                                            </Button>
                                            <Button type="submit" iconRight='save' className="flex-grow-1 btn btn-success w-sm">
                                                Thêm mới
                                            </Button>
                                        </div>
                                        <div className="mb-3">
                                            <Label htmlFor="choices-privacy-status-input" className="form-label required">Trạng thái</Label>
                                            <Select register={register('status')}
                                                defaultValue={'0'}
                                                className="form-select"
                                                options={listOptionStatus}
                                                invalid={!!errors.status} />
                                            {errors.status && (
                                                <span>{errors.status.message}</span>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="choices-privacy-status-input" className="form-label">Chi nhánh</Label>
                                            <SelectSearch
                                                invalid={!!errors.branch_id}
                                                value={getValues('branch_id')!}
                                                showClear
                                                onChange={(option: any) => {
                                                    if (option?.value) {
                                                        setValue('branch_id', option.value)
                                                        clearErrors('branch_id')
                                                    } else {
                                                        setValue('branch_id', '')
                                                        setValue('person_in_charge', '')
                                                    }
                                                }}
                                                placeholder='Chi nhánh'
                                                options={listOptionBranch}
                                            />
                                            {errors.branch_id && (
                                                <span>{errors.branch_id.message}</span>
                                            )}
                                        </div>
                                        {
                                            listOptionSale && (
                                                <div className="mb-3">
                                                    <Label htmlFor="choices-privacy-status-input" className="form-label">Nhân viên phụ trách</Label>
                                                    <SelectSearch
                                                        invalid={!!errors.person_in_charge}
                                                        value={personInChageIdSelected!}
                                                        disabled={!branchIdSelected}
                                                        showClear
                                                        onChange={(option: any) => {
                                                            if (option?.value) {
                                                                setValue('person_in_charge', option.value)
                                                                clearErrors('person_in_charge')
                                                            } else {
                                                                setValue('person_in_charge', '')
                                                            }
                                                        }}
                                                        placeholder='Nhân viên phụ trách'
                                                        options={listOptionSale}
                                                    />
                                                    {errors.person_in_charge && (
                                                        <span>{errors.person_in_charge.message}</span>
                                                    )}
                                                </div>
                                            )
                                        }

                                    </CardBody>
                                </div>
                                <Card>
                                    <CardHeader >
                                        <h5 className="card-title mb-0">Ảnh đại diện học sinh</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <div>
                                            {
                                                avatarPreview && (
                                                    <Card
                                                        className="dz-processing dz-image-preview dz-success dz-complete relative"
                                                        key={avatarPreview}
                                                    >
                                                        <img
                                                            data-dz-thumbnail=""
                                                            height={200}
                                                            className="w-100 object-fit-contain avatar-xxl rounded-2 "
                                                            alt={String(avatarPreview)}
                                                            src={config.api.API_URL_STORAGE + String(avatarPreview)}
                                                        />
                                                        <FeatherIcon onClick={() => setValue('avatar', '')} className='pointer  rounded-circle cursor-pointer  position-absolute bg-light' style={{ top: '10px', right: '10px' }} icon={'x-circle'} color={'red'} />
                                                    </Card>
                                                )
                                            }
                                            <Dropzone
                                                onDrop={(acceptedFiles: any) => {
                                                    handleAcceptedAvatar(acceptedFiles)

                                                }}
                                                // accept={{
                                                //     'image/jpeg': ['.jpeg', '.png']
                                                // }}
                                                maxFiles={1}
                                                noClick={false}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone dz-clickable" style={{
                                                        minHeight: "unset",
                                                        height: "max-content"
                                                    }}>
                                                        <div
                                                            className="dz-message needsclick"
                                                            {...getRootProps()}
                                                        >
                                                             <input {...getInputProps()} /> 
                                                            <div>
                                                                <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                                            </div>
                                                            <h6>Kéo thả ảnh hoặc chọn để tải lên.</h6>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>



                                        </div>
                                    </CardBody>
                                </Card>



                            </Col>
                        </Row>
                    </Form>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default CreateStudent;