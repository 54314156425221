import React, { useMemo } from 'react';
import { Button, Card, CardBody, DropdownToggle, Label, UncontrolledDropdown } from "reactstrap";

import TaskStatus from 'Components/widgets/Status/TaskStatus';
import { getDateView, transferRoleById } from 'helpers/function';
import { Link, useNavigate } from 'react-router-dom';
import { ITask } from 'types/Task/Task';
import avatar10 from "../../../../assets/images/users/avatar-10.jpg";
import { TASK_STATUSES } from 'common/constant';
import SelectSearch from 'Components/widgets/SelectSearch/SelectSearch';
import { useDispatch } from 'react-redux';
import { getTask, updateStatusTask } from 'slices/thunks';
type Props = {
    task: ITask
}
const TimeTracking = ({ task }: Props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch() as any
    const listOptionTaskStatus = useMemo(() => TASK_STATUSES.map(status => ({ value: status.id, label: status.label })), [TASK_STATUSES])
    const handleUpdateStauts = (option: any) => {
        if (!String(option.value)) { return }
        dispatch(updateStatusTask({
            taskId: task.id, statusId: option.value, callback(status) {
                if (status) {
                    dispatch(getTask(Number(task.id)))
                }
            },
        }))
    }
    return (
        <React.Fragment>
            <Card className="mb-3">
                <CardBody>
                    <div className="form-group mb-4">
                        <Label className="col-form-label required">
                            Trạng thái
                        </Label>
                        {/* <select className="form-control" name="choices-single-default" data-choices data-choices-search-false>
                            <option value="">Đổi trạng thái</option>
                            <option value="Unassigned">Unassigned</option>
                            <option value="To Do">To Do</option>
                            <option value="Inprogress">Inprogress</option>
                            <option defaultValue="In Reviews">In Reviews</option>
                            <option value="Completed">Completed</option>
                        </select> */}
                        <SelectSearch
                            value={String(task.status)!}
                            onChange={handleUpdateStauts}
                            placeholder='Trạng thái' options={listOptionTaskStatus}
                        />
                    </div>
                    <div className="table-card">
                        <table className="table mb-0">
                            <tbody>
                                <tr>
                                    <td className="fw-bold">Mã công việc</td>
                                    <td>#{task.id}</td>
                                </tr>
                                {/* <tr>
                                    <td className="fw-bold">Tên công việc</td>
                                    <td>{task.title}</td>
                                </tr> */}
                                <tr>
                                    <td className="fw-bold">Trạng thái</td>
                                    <td><TaskStatus status={task.status} /></td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Hết hạn</td>
                                    <td>{getDateView(task.end_date)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="d-flex mb-3">
                        <h6 className="card-title mb-0 flex-grow-1">Người thực hiện</h6>
                        <div className="flex-shrink-0">
                            <button type="button" className="btn btn-soft-danger btn-sm" data-bs-toggle="modal" data-bs-target="#inviteMembersModal"><i className="ri-share-line me-1 align-bottom"></i>Thay đổi</button>
                        </div>
                    </div>
                    <ul className="list-unstyled vstack gap-3 mb-0">
                        <li>
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <img src={avatar10} alt="" className="avatar-xs rounded-circle" />
                                </div>
                                <div className="flex-grow-1 ms-2">
                                    <h6 className="mb-1"><Link to="#" className='text-reset'>{task.user?.name}</Link></h6>
                                    <p className="text-muted mb-0">{transferRoleById(Number(task.user?.role_id))}</p>
                                </div>
                                <div className="flex-shrink-0">
                                    <UncontrolledDropdown>
                                        <DropdownToggle tag="button" className="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button">
                                            <i className="ri-more-fill"></i>
                                        </DropdownToggle>
                                        {/* <DropdownMenu>
                                            <div><DropdownItem><i className="ri-eye-fill text-muted me-2 align-bottom"></i>Xem</DropdownItem></div>
                                            <div><DropdownItem><i className="ri-star-fill text-muted me-2 align-bottom"></i>Favourite</DropdownItem></div>
                                            <div><DropdownItem><i className="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Delete</DropdownItem></div>
                                        </DropdownMenu> */}
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="text-end mb-3">
                <Button type="button" onClick={() => navigate(-1)} className="btn btn-primary">Quay lại</Button>
            </div>
            {/* <Card>
                <CardBody>
                    <h5 className="card-title mb-3">Attachments</h5>
                    <div className="vstack gap-2">
                        <div className="border rounded border-dashed p-2">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar-sm">
                                        <div className="avatar-title bg-light text-secondary rounded fs-24">
                                            <i className="ri-folder-zip-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="fs-13 mb-1"><Link to="#" className="text-body text-truncate d-block">App pages.zip</Link></h5>
                                    <div>2.2MB</div>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                    <div className="d-flex gap-1">
                                        <button type="button" className="btn btn-icon text-muted btn-sm fs-18"><i className="ri-download-2-line"></i></button>
                                        <UncontrolledDropdown>
                                            <DropdownToggle tag="button" className="btn btn-icon text-muted btn-sm fs-18 dropdown" type="button">
                                                <i className="ri-more-fill"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <li><DropdownItem><i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Rename</DropdownItem></li>
                                                <li><DropdownItem><i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete</DropdownItem></li>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border rounded border-dashed p-2">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar-sm">
                                        <div className="avatar-title bg-light text-secondary rounded fs-24">
                                            <i className="ri-file-ppt-2-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="fs-13 mb-1"><Link to="#" className="text-body text-truncate d-block">Velzon admin.ppt</Link></h5>
                                    <div>2.4MB</div>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                    <div className="d-flex gap-1">
                                        <button type="button" className="btn btn-icon text-muted btn-sm fs-18"><i className="ri-download-2-line"></i></button>
                                        <UncontrolledDropdown>
                                            <DropdownToggle tag="button" className="btn btn-icon text-muted btn-sm fs-18 dropdown" type="button">
                                                <i className="ri-more-fill"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <li><DropdownItem><i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Rename</DropdownItem></li>
                                                <li><DropdownItem><i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete</DropdownItem></li>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border rounded border-dashed p-2">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar-sm">
                                        <div className="avatar-title bg-light text-secondary rounded fs-24">
                                            <i className="ri-folder-zip-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="fs-13 mb-1"><Link to="#" className="text-body text-truncate d-block">Images.zip</Link></h5>
                                    <div>1.2MB</div>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                    <div className="d-flex gap-1">
                                        <button type="button" className="btn btn-icon text-muted btn-sm fs-18"><i className="ri-download-2-line"></i></button>
                                        <UncontrolledDropdown>
                                            <DropdownToggle tag="button" className="btn btn-icon text-muted btn-sm fs-18 dropdown" type="button">
                                                <i className="ri-more-fill"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <li><DropdownItem><i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Rename</DropdownItem></li>
                                                <li><DropdownItem><i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete</DropdownItem></li>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 text-center">
                            <button type="button" className="btn btn-primary">View more</button>
                        </div>
                    </div>
                </CardBody>
            </Card> */}
        </React.Fragment>
    );
};

export default TimeTracking;