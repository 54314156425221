import { APIClient } from "../api_helper";

import { AxiosResponse } from "axios";
import { TBranch, TGetUserByBranchAndRoleInput, TProvince, TRole, TSale, TSchool, TUploadFile } from "types/Common/Common";
import { IUser } from "types/User/User";
import * as URL from "./url";

const api = new APIClient();

export const getProvinces = (): Promise<AxiosResponse<{ provinces: Array<TProvince> }>> => api.get(URL.COMMON_PROVINCE_PATH)
export const getDistricts = (province_id: number | string): Promise<AxiosResponse<{ districts: Array<TProvince> }>> => api.get(URL.COMMON_DISTRICT_PATH, { province_id })
export const getWards = (district_id: number | string): Promise<AxiosResponse<{ wards: Array<TProvince> }>> => api.get(URL.COMMON_WARD_PATH, { district_id })
export const getRoles = (): Promise<AxiosResponse<{ roles: Array<TRole> }>> => api.get(URL.COMMON_ROLE_PATH)
export const getBranchs = (): Promise<AxiosResponse<{ branches: Array<TBranch> }>> => api.get(URL.COMMON_BRANCH_PATH)
export const getManagers = (branch_id: string): Promise<AxiosResponse<{ users: Array<TBranch> }>> => api.get(URL.COMMON_MANAGER_PATH, { branch_id })
export const getSaleByBranchs = (): Promise<AxiosResponse<{ sales: Array<TSale> }>> => api.get(URL.COMMON_SALE_BY_BRANCH_PATH,)
export const getSchools = (params: any): Promise<AxiosResponse<{ schools: Array<TSchool> }>> => api.get(URL.COMMON_SCHOOL_PATH, params)
// get user by branch and role
export const getUsersByBranchAndRole = (params: TGetUserByBranchAndRoleInput): Promise<AxiosResponse<{ users: Array<IUser> }>> => api.get(URL.COMMON_USERS_PATH, params)
// upload
export const uploadFile = (data: File): Promise<AxiosResponse<TUploadFile>> => api.create(URL.COMMON_UPLOAD_PATH, { file: data }, { contentType: 'multipart/form-data' })



