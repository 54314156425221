import { getFileType } from 'helpers/function'
import { memo } from 'react'
import { TUploadFile } from 'types/Common/Common'

type Props = {
    file: TUploadFile
}

const ThumbFile = ({ file }: Props) => {
    if (getFileType(file.path) == 'Docs') {
        return <span
            // height="80"
            style={{ height: 80 }}
            className="avatar-sm rounded bg-light bg-primary-subtle"
        // src={file.full_path}
        >
            <i className="ri-file-zip-fill"></i>
        </span>
    }
    if (getFileType(file.path) == 'Image') {
        return (
            <img
                height="80"
                className="avatar-sm rounded bg-light"
                alt={file.file_name}
                src={file.full_path}
            />
        )
    }
    return <div
        className="avatar-sm d-flex align-items-center justify-content-center rounded bg-danger-subtle"
    >
        <i className="ri-file-excel-fill"></i>
    </div>
}

export default memo(ThumbFile)