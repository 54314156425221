import useDebounce from 'Components/Hooks/useDebounce';
import React, { useEffect, useMemo, useState } from 'react';
import Select, { components } from "react-select";

type Props = {
    value?: string | number
    options: Array<{ label: string, value: string | number }>
    defaultValue?: string | number
    invalid?: boolean,
    onInputChange?: (e: any) => void
    isMulti?: boolean,
    delayInput?: number
    placeholder?: string,
    showClear?: boolean
} & React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>
// const formatOptionLabel = ({ value, label, customAbbreviation }: any) => (
//     <div style={{ display: "flex" }}>
//         <div>
//             <span>
//                 <img src="https://picsum.photos/200/300" alt="" />
//             </span>
//             <span>
//                 {label}
//             </span>
//         </div>
//         <div style={{ marginLeft: "10px", color: "#ccc" }}>
//             {customAbbreviation}
//         </div>
//     </div>
// );

const SelectSearch = (props: Props) => {
    const { value, options, onInputChange, defaultValue, invalid, isMulti, delayInput, placeholder, showClear = false } = props
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, delayInput ? delayInput : 500);

    const defaulValueFill = useMemo(() => {
        if (defaultValue && options.length > 0) {
            return options.find(option => option.value == defaultValue)
        }else if(isMulti) {
            return value
        }  else {
            return null
        }
    }, [defaultValue, isMulti, options])    

    const valueFill = useMemo(() => {
        if (value && options.length > 0) {
            return options.find(option => option.value == value)
        } else if(isMulti) {
            return value
        } else {
            return null
        }
    }, [value, isMulti,options])

    useEffect(() => {
        if (debouncedSearchTerm) {
            onInputChange && onInputChange(debouncedSearchTerm)
        }
    }, [debouncedSearchTerm]);

    return (
            <Select
                {...props}
                isMult={isMulti}
                value={valueFill}
                defaultValue={defaulValueFill}
                isClearable={showClear}
                // isSearchable
                onInputChange={(value: string) => setSearchTerm(value)}
                className="mb-0"
                options={options ?? []}
                // formatOptionLabel={formatOptionLabel}
                id="taginput-choices"
                styles={{
                    control: (provided: any, state: any) => ({
                        ...provided,
                        borderColor: invalid ? 'var(--vz-form-invalid-color)' : provided.borderColor,
                        '&:hover': {
                            borderColor: invalid ? 'var(--vz-form-invalid-color)' : provided.borderColor,
                        },
                        pointerEvents: props.disabled ? 'none' : ''
                    }),
                }}
                placeholder={placeholder}

            />
    )
}

export default SelectSearch