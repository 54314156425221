import Avatar from 'Components/widgets/Avatar/Avatar'
import SelectSearch from 'Components/widgets/SelectSearch/SelectSearch'
import { ROLE_ADMIN } from 'common/constant'
import { getQueryParams } from 'helpers/function'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import { getUserSession } from 'slices/auth/profile/reducer'
import { getStoreBranchs, getStoreUsers, resetUsers } from 'slices/common/reducer'
import { getListUserByBranchAndRole } from 'slices/thunks'
import { IUser } from 'types/User/User'
import useFilter from '../../Hook/useFilter'

type Props = {
    isOpen: boolean
    handleToggle: () => void
}

type TQueryParams = { user_ids: string, branch_id: string }


const SelectedUser = ({ isOpen, handleToggle }: Props) => {
    const listOptionStoreBranch = useSelector(getStoreBranchs)
    const listOptionStoreUser = useSelector(getStoreUsers<IUser>)
    const userInfo = useSelector(getUserSession) as IUser


    const [branchSelected, setBranchSelected] = useState<string>()
    const [userSelected, setUserSelected] = useState<any>({})

    const dispatch = useDispatch() as any
    const { onFilter } = useFilter()
    const isSelectOtherBranch = ROLE_ADMIN.id === userInfo.role_id

    const handleSelectUser = (userId: number, status: boolean) => {
        if (!userSelected) return
        if (status) {
            const newUserSlected = { ...userSelected, [userId]: userId }
            setUserSelected(newUserSlected)
        } else {
            delete userSelected[userId]
            const newUserSlected = { ...userSelected }
            setUserSelected(newUserSlected)
        }
    }

    const handleSearch = () => {
        const newParams = {} as TQueryParams
        newParams['user_ids'] = Object.keys(userSelected).map(userId => userId).join(',')
        if (Object.keys(userSelected).length > 0) {
        }
        newParams['branch_id'] = branchSelected ?? ''
        if (branchSelected) {
        }
        onFilter(newParams)
        handleToggle()
    }
    useEffect(() => {
        const branchId =isSelectOtherBranch ? branchSelected : userInfo.branch_id
        if (branchId) {
            dispatch(getListUserByBranchAndRole({
                branch_id: branchId,
                type: 'branch',
                customRespon: (user) => user
            }))
        }
    }, [branchSelected, dispatch, isSelectOtherBranch, userInfo])

    useEffect(() => {
        const params = getQueryParams() as TQueryParams

        if (params.branch_id) {
            setBranchSelected(params.branch_id)
        }
        if (params.user_ids) {
            const newUserSlected = {} as any
            params.user_ids.split(',').forEach((item) => {
                newUserSlected[item] = Number(item)
            })
            setUserSelected(newUserSlected)
        }
    }, [])

    return (
        <Modal id="modalForm" isOpen={isOpen} toggle={handleToggle} centered={true} size="md">
            <ModalHeader toggle={handleToggle}>
                Danh sách người thực hiện
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col className='mt-2' xxl={12} lg={12} md={12} xs={12}>
                        <Label htmlFor="choices-privacy-status-input" className="form-label">Chi nhánh văn phòng</Label>
                        <SelectSearch
                            value={isSelectOtherBranch ? branchSelected : userInfo.branch_id}
                            disabled={!isSelectOtherBranch}
                            onChange={(option: any) => {
                                setBranchSelected(option?.value)
                                setUserSelected({})
                                if (!option?.value) {
                                    dispatch(resetUsers())
                                }
                            }}
                            options={listOptionStoreBranch}
                            placeholder='Chi nhánh văn phòng'
                            showClear={true}
                        />
                    </Col>
                </Row>
                {

                    <SimpleBar style={{ maxHeight: "500px", minHeight: '500px' }} className='mt-2'>
                        {
                            listOptionStoreUser.length == 0 &&
                            <p className='text-center mt-2'>
                                Không tìm thấy người dùng nào !
                            </p>

                        }
                        {listOptionStoreUser.length > 0 && listOptionStoreUser.map((user) => {
                            return <div key={user.id} className="form-check d-flex align-items-center py-1">
                                <Input name="subItem" className="form-check-input me-3" type="checkbox"
                                    value={userSelected[user.id] ?? ''}
                                    checked={userSelected[user.id] ? true : false}
                                    onChange={(e) => {
                                        console.log('eeeee', e.target.checked);
                                        handleSelectUser(user.id, e.target.checked)
                                    }}

                                />
                                <Label className="form-check-label d-flex align-items-center" htmlFor={user.name}>
                                    <Avatar avatar={undefined} name={user.name} />
                                    <span className="flex-grow-1 ms-2">
                                        {user.name}
                                    </span>
                                </Label>
                            </div>
                        })}
                    </SimpleBar>
                }
                <div className='mt-1 d-flex justify-content-end'>
                    <Button onClick={handleSearch}>
                        Tìm kiếm
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default SelectedUser