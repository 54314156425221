

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorInput from "Components/widgets/Form/ErrorInput";
import SelectSearch from "Components/widgets/SelectSearch/SelectSearch";
import ThumbFile from "Components/widgets/ThumbFile/ThumbFile";
import { TASK_STATUSES } from "common/constant";
import config from "config";
import { Vietnamese } from 'flatpickr/dist/l10n/vn.js';
import { uploadFile } from "helpers/api/common";
import { downloadFile, getTaskConfig } from "helpers/function";
import moment from "moment";
import { useEffect, useMemo } from "react";
import Dropzone from "react-dropzone";
import Flatpickr from "react-flatpickr";
import { UseFormRegisterReturn, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { getStoreUsers } from "slices/common/reducer";
import { addCardData, getListUserByBranchAndRole, updateCardData } from "slices/thunks";
import { IBaseOption } from "types/Common";
import { TUploadFile } from "types/Common/Common";
import { ICreateTask, IKanBanTask, ITask, ITaskMedia } from "types/Task/Task";
import * as yup from "yup";

type Props = {
  modal: boolean
  isEdit: boolean
  toggle: () => void
  kanban?: IKanBanTask | null
  card: ITask | null
}
export interface IPayloadCreateTask extends ICreateTask {
  kanId: string
}
const createSchema = () => {
  return yup.object().shape({
    title: yup.string().required('Tên công việc không được để trống !'),
    description: yup.string().required('Mô tả không được để trống !'),
    assign_user_id: yup.string().notRequired(),
    status: yup.string().required("Trạng thái không được để trống!"),
    start_date: yup.string().required("Ngày bắt đầu không được để trống!"),
    end_date: yup.string().required("Ngày kết thúc không được để trống!"),
    media: yup.array().notRequired()
  })
}
const FormTask = ({ modal, toggle, isEdit, card, kanban }: Props) => {
  const schema = createSchema();
  const dispath = useDispatch() as any
  const listOptionUserAssign = useSelector(getStoreUsers<IBaseOption>)
  const listOptionTaskStatus = useMemo(() => TASK_STATUSES.map(status => ({ value: status.id, label: status.label })), [TASK_STATUSES])

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });
  watch(['media', 'start_date', 'end_date'])

  const registerRs = (fieldName: any, options = {}) => {
    type NewType = UseFormRegisterReturn;

    const registeredField: Partial<NewType> = register(fieldName, options);
    const ref = registeredField.ref;
    delete registeredField.ref;
    return { ...registeredField, innerRef: ref };
  }

  const handleToggle = () => {
    toggle && toggle()
  }

  const onSave = (values: IPayloadCreateTask) => {
    values.media = values.media ?? []
    values.kanId = kanban?.id!
    if (!isEdit) {
      console.log('values', values);
      dispath(addCardData({
        card: values, calback(status) {
          if (status) {
            handleToggle()
          }
        },
      }))
    } else {
      dispath(updateCardData({
        card: values,
        cardId: card?.id!,
        calback(status) {
          if (status) {
            handleToggle()
          }
        },
      }))
    }
  }

  const handleUpdateUploadedData = (keyForm: string | any, newDatas: TUploadFile[], currDatas?: TUploadFile[]) => {
    const newPreviews = [...(currDatas ? currDatas : []), ...(newDatas.map(item => ({ ...item, id: null, name: item.file_name })))]
    setValue(keyForm, newPreviews)
  }
  const handleDeleteUploadedData = (keyForm: string | any, deleteKeyData: string, currDatas: TUploadFile[]) => {
    const newPreviews = currDatas && currDatas.filter(item => item.path !== deleteKeyData)
    setValue(keyForm, newPreviews)
  }


  const handleAcceptedFiles = async (files: File[]) => {
    const uploadPromises = Array.from(files).map((file) => uploadFile(file));
    Promise.all(uploadPromises)
      .then((results) => {
        const newPreviews = results.map(result => result.data)
        handleUpdateUploadedData('media', newPreviews, getValues('media') as TUploadFile[])
      })
      .catch((error) => {
        // console.log('Upload error');
        // errornotify('Tải ảnh thất bại !')
      });
  }

  useEffect(() => {
    if (!isEdit && kanban) {
      const statusKanbanId = getTaskConfig(kanban.id, 'key', 'id')
      setValue('status', statusKanbanId!)
    }
  }, [isEdit, kanban, setValue])
  useEffect(() => {
    dispath(getListUserByBranchAndRole({
      type: 'all'
    }))
  }, [])

  useEffect(() => {
    if (card && isEdit) {
      for (const [key, value] of Object.entries(card)) {

        if (key === 'media') {
          const formatMedia = value.map((item: ITaskMedia): TUploadFile => ({ ...item, path: item.path, file_name: item.name, full_path: config.api.API_URL_STORAGE + item.path }))
          setValue(key as any, formatMedia)
        } else {
          setValue(key as any, value)
        }
      }
    }
  }, [isEdit, card, setValue])



  return (
    <Modal id="modalForm" isOpen={modal} toggle={handleToggle} centered={true} size="lg">
      <ModalHeader toggle={handleToggle}>
        {!!isEdit ? "Cập nhật công việc" : "Tạo công việc"}
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSave as any)}>
        <ModalBody style={{ overflowY: 'auto' }}>
          <div className="form-group mb-3">
            <Label htmlFor="taskname" className="col-form-label required">
              Tên công việc
            </Label>
            <Col lg={12}>
              <Input
                {...registerRs('title')}
                type="text"
                className="form-control"
                placeholder="Tên công việc"
                invalid={!!errors.title}
              />
              {errors.title ? (
                <ErrorInput>
                  {errors.title.message}
                </ErrorInput>
              ) : null}
            </Col>
          </div>
          <div className="form-group mb-3">
            <Label className="col-form-label required">Mô tả</Label>
            <Col lg={12}>
              {/* <textarea
                {...registerRs('description')}
                className="form-control"
                placeholder="Mô tả"
              ></textarea> */}
              <CKEditor
                editor={ClassicEditor}
                data={getValues('description')}
                onChange={(_, editor) => {
                  // console.log('change', editor.getData());
                  setValue('description', editor.getData())
                  if (!!errors.description) {
                    clearErrors('description')
                  }
                }}
              />
            </Col>
            {errors.description ? (
              <ErrorInput>
                {errors.description.message}
              </ErrorInput>
            ) : null}
          </div>

          <Row>
            <Col sm={6}>
              <div className="form-group mb-3">
                <Label className="col-form-label required">
                  Người thực hiện
                </Label>
                <SelectSearch
                  invalid={!!errors.assign_user_id}
                  value={getValues('assign_user_id')!}
                  onChange={(option: any) => {
                    if (option?.value) {
                      setValue('assign_user_id', option.value)
                      clearErrors('assign_user_id')
                    } else {
                      setValue('assign_user_id', '')
                    }
                  }}
                  onInputChange={() => {

                  }}
                  placeholder='Người thực hiện' options={listOptionUserAssign} />
                {
                  errors.assign_user_id && (
                    <ErrorInput>{errors.assign_user_id.message}</ErrorInput>
                  )
                }
              </div>
            </Col>
            <Col sm={6}>
              <div className="form-group mb-3">
                <Label className="col-form-label required">
                  Trạng thái
                </Label>
                <SelectSearch invalid={!!errors.status}
                  value={String(getValues('status'))!}
                  defaultValue={kanban ? getTaskConfig(kanban.id, 'key', 'id') : undefined}
                  onChange={(option: any) => {
                    if (option?.value) {
                      setValue('status', option.value)
                      clearErrors('status')
                    } else {
                      setValue('status', '')
                    }
                  }}
                  placeholder='Trạng thái' options={listOptionTaskStatus}
                />
                {
                  errors.status && (
                    <ErrorInput>{errors.status.message}</ErrorInput>
                  )
                }
              </div>
            </Col>
            <Col sm={6}>
              <div className="form-group mb-4">
                <Label htmlFor="date-field" className="form-label required">
                  Ngày bắt đầu
                </Label>
                <Flatpickr
                  name="start_date"
                  className="form-control required"
                  id="datepicker-publish-input"
                  placeholder="Ngày bắt đầu"
                  options={{
                    altInput: true,
                    maxDate: getValues('end_date'),
                    dateFormat: 'Y-m-d', // Định dạng dữ liệu
                    altFormat: "d M, Y", // Định dạng hiển thịị
                    locale: Vietnamese
                  }}
                  onChange={(([selectedDate]) => {
                    setValue('start_date', moment(selectedDate).format("YYYY-MM-DD"))
                    if (!!errors.start_date) {
                      clearErrors('start_date')
                    }
                  })}
                  value={getValues('start_date') || ''}
                />
                {errors.start_date && (
                  <ErrorInput>{errors.start_date.message}</ErrorInput>
                )}
              </div>
            </Col>
            <Col sm={6}>
              <div className="form-group mb-4 required">
                <Label htmlFor="date-field" className="form-label required">
                  Ngày kết thúc
                </Label>
                <Flatpickr
                  name="end_date"
                  className="form-control"
                  id="datepicker-publish-input"
                  placeholder="Ngày kết thúc"
                  // disabled={!getValues('start_date') ? true : false}
                  options={{
                    minDate: getValues('start_date'),
                    altInput: true,
                    dateFormat: 'Y-m-d', // Định dạng dữ liệu
                    altFormat: "d M, Y", // Định dạng hiển thịị
                    locale: Vietnamese
                  }}
                  // min={getValues('start_date')}
                  onChange={(([selectedDate]) => {
                    setValue('end_date', moment(selectedDate).format("YYYY-MM-DD"))
                    if (!!errors.end_date) {
                      clearErrors('end_date')
                    }
                  })}
                  value={getValues('end_date') || ''}
                />
                {errors.end_date && (
                  <ErrorInput>{errors.end_date.message}</ErrorInput>
                )}
              </div>
            </Col>
          </Row>
          {/* Media */}
          <Col sm={12} className="w-100" style={{ height: 100 }} >
            <Label htmlFor="date-field" className="form-label">
              Tài liệu
            </Label>
            <Dropzone
              onDrop={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles);
              }}
              noClick={false}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone h-100 min-h-unset dz-clickable">
                  <div
                    className="dz-message needsclick"
                    style={{ margin: 0 }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} /> 
                    <div className="">
                      <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
            <ul className="list-unstyled mb-0" id="dropzone-preview">

              {getValues('media') && getValues('media')!.map((f: TUploadFile, i: any) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <ThumbFile file={f} />
                        </Col>
                        <Col>
                          <Link
                            to="#"
                            className="text-muted font-weight-bold"
                          >
                            {f.file_name}
                          </Link>
                          <p className="mb-0">
                            <strong>{f?.file_name}</strong>
                          </p>
                        </Col>
                        <Col sm={3} className="d-flex justify-content-end gap-2">
                          <button type="button" onClick={() => downloadFile(f.full_path, f.file_name)} className="btn btn-sm btn-info"> <i className="font-14 ri-file-download-line"></i> Tải xuống</button>
                          <button type="button" onClick={() => handleDeleteUploadedData('media', f.path, getValues('media') as TUploadFile[])} className="btn btn-sm btn-danger"><i className="font-14 ri-delete-bin-line"></i> Xoá</button>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                );
              })}
            </ul>
          </Col>

          {/* Bottom */}


        </ModalBody>
        <ModalFooter className="mt-3">
          <Row>
            <Col lg={12} className="text-end">
              <button
                type="submit"
                className="btn btn-primary"
                id="updatetaskdetail"
              >
                {!!isEdit ? "Cập nhật" : "Tạo mới"}
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Form>
    </Modal >
  )
}

export default FormTask