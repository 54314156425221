import { useMemo, useState } from 'react';
import Dropzone from "react-dropzone";
import { Col, Row } from 'reactstrap';

import FeatherIcon from "feather-icons-react";
import { uploadFile } from 'helpers/api/common';
import Lightbox from "yet-another-react-lightbox";

import "yet-another-react-lightbox/styles.css";
import useNotify from 'helpers/hooks/useNotify';
import config from 'config';
type Props = {
    onUploaded?: (images: string[]) => void
    previews?: string[]
    onRemovePreview?: (path: string) => void
}

const GalleryUpload = ({ onUploaded, previews, onRemovePreview }: Props) => {
    const [index, setIndex] = useState<any>(-1);
    const { errornotify } = useNotify()
    const handleAcceptedFiles = async (files: File[]) => {
        const uploadPromises = Array.from(files).map((file) => uploadFile(file));
        Promise.all(uploadPromises)
            .then((results) => {
                const newPreviews = results.map(result => result?.data?.path)
                if (onUploaded) {
                    onUploaded(newPreviews)
                    return true;
                }
            })
            .catch((error) => {
                // console.log('Upload error');
                errornotify('Tải ảnh thất bại !')
            });
    }
    const slideGallery = useMemo(() => {
        if (previews) {
            return previews.map((item) => ({ "src": `${config.api.API_URL_STORAGE}${item}` }));
        } else {
            return []
        }
    }, [previews])

    return (
        <Row noGutters className="g-2 align-items-center">
            {
                previews && previews.map((item, key) => (
                    <Col className="col-6 col-sm-4 h-200 text-center relative">
                        <img
                            onClick={() => setIndex(key)}
                            key={item}
                            data-dz-thumbnail=""
                            className="h-100 w-100 object-fit-cover cursor-pointer  rounded bg-light"
                            alt={item}
                            src={`${config.api.API_URL_STORAGE}${item}`}
                        />
                        <FeatherIcon onClick={() => onRemovePreview && onRemovePreview(item)} className='pointer  rounded-circle cursor-pointer  position-absolute bg-light' style={{ top: '10px', right: '10px' }} icon={'x-circle'} color={'red'} />
                    </Col>
                ))
            }
            <Col className="col-6 col-sm-4 h-200">
                <Dropzone
                    onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles);
                    }}
                    noClick={false}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone h-100 min-h-unset dz-clickable">
                            <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} /> 

                                <div className="mb-3">
                                    <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                </div>
                                <h6>Kéo thả ảnh hoặc chọn để tải lên.</h6>
                            </div>
                        </div>
                    )}
                </Dropzone>
            </Col>
            <Lightbox
                index={index}
                slides={slideGallery}
                open={index >= 0}
                close={() => setIndex(-1)}
            />

        </Row>
    )
}

export default GalleryUpload