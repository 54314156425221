import { createSlice } from "@reduxjs/toolkit";
import {
  getPrincipal,
  getPrincipals
} from './thunk';
import { IPagination } from "types/Common/Common";

export const initialState: any = {
  loading: false,
  principal: {},
  principals: [],
  pagination: {},
  error: {},
};

const principalSlice = createSlice({
  name: "principal",
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(getPrincipal.pending, (state: any) => {
      state.loading = true
    });
    builder.addCase(getPrincipals.pending, (state: any) => {
      state.loading = true
    });
    builder.addCase(getPrincipal.fulfilled, (state: any, action: any) => {
      state.loading = false
      state.principal = action.payload;
    });
    builder.addCase(getPrincipals.fulfilled, (state: any, action: any) => {
      state.loading = false
      state.principals = action.payload.principals;
      state.pagination = action.payload.pagination;

    });
  },
});

export const getStorePrincipal = (state: any) => state.Principal.principal
export const getStorePrincipalPending = (state: any) => state.Principal.loading
export const getStorePrincipalsPending = (state: any) => state.Principal.loading
export const getStorePrincipals = (state: any) => state.Principal.principals
export const getStorePaginationPrincipals = (state: any): IPagination => state.Principal.pagination



export default principalSlice.reducer;