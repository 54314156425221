import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUser as getUserInfo, getUsers as getListUser } from "helpers/api/user";

export const getUser = createAsyncThunk("user/getUser", async (userId: number) => {
  try {
    const response = await getUserInfo(userId);
    return response.data.user;
  } catch (error) {
    return error;
  }
});

export const getUsers = createAsyncThunk("user/getUsers", async (params: any) => {
  try {
    const response = await getListUser(params);
    return response.data;
  } catch (error) {
    return error;
  }
});
