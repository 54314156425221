import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    createTask as addNewTasksApi,
    getTasks as getTasksApi,
    updateTask as updateTasksApi,
    deleteTasks as deleteTasksApi,
    getTask as getTaskApi,
    createComment as createCommentApi,
    updateStatusTask as updateStatusTaskApi
} from "helpers/api/tasks";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Include Both Helper File with needed methods
import { getTaskConfig } from "helpers/function";
import { ICreateComment, ICreateTask, IQueryTasks } from "types/Task/Task";
import {
    addNewTask as addNewTaskApi,
    deleteTask as deleteTaskApi,
    // deleteTasks as deleteTasksApi,
    getTaskList as getTaskListApi,
    updateTask as updateTaskApi,
    // getTasks as getTasksApi,
    // addNewTasks as addNewTasksApi,
    // updateTasks as updateTasksApi
} from "../../helpers/fakebackend_helper";
import { IPayloadCreateTask } from "pages/Authenticated/Tasks/KanbanBoard/Components/FormTask/FormTask";
import { TASK_STATUSES_KEY } from "common/constant";
export const getTaskList = createAsyncThunk("tasks/getTaskList", async () => {
    try {
        const response = getTaskListApi();
        return response;
    } catch (error) {
        return error;
    }
});
export const addNewTask = createAsyncThunk("tasks/addNewTask", async (task: any) => {
    try {
        const response = addNewTaskApi(task);
        toast.success("Task Added Successfully", { autoClose: 3000 });
        return response;
    } catch (error) {
        toast.error("Task Added Failed", { autoClose: 3000 });
        return error;
    }
});
export const updateTask = createAsyncThunk("tasks/updateTask", async (task: any) => {
    try {
        const response = updateTaskApi(task);
        toast.success("Task Updated Successfully", { autoClose: 3000 });
        return response;
    } catch (error) {
        toast.error("Task Updated Failed", { autoClose: 3000 });
        return error;
    }
});
export const deleteTask = createAsyncThunk("tasks/deleteTask", async (task: any) => {
    try {
        const response = deleteTaskApi(task);
        toast.success("Task Updated Successfully", { autoClose: 3000 });
        return { task, ...response };
    } catch (error) {
        toast.error("Task Updated Failed", { autoClose: 3000 });
        return error;
    }
});
// Kanban Board
export const getTasks = createAsyncThunk("tasks/getTasks", async (params?: IQueryTasks) => {
    try {
        const response = await getTasksApi(params!);
        // Custome response
        const transferResponse: any[] = [];
        TASK_STATUSES_KEY.forEach((status) => {
            // console.log('response.data.tasks[status]', response.data.tasks[status]);

            transferResponse.push({
                badge: response.data.tasks[status]?.length ?? 0,
                color: getTaskConfig(status, 'key', 'color'),
                cards: response.data.tasks[status] ?? [],
                name: getTaskConfig(status, 'key', 'label'),
                id: status
            })
        })
        return transferResponse;
    } catch (error) {
        return error;
    }
});
export const getTask = createAsyncThunk("tasks/getTask", async (taskId: number) => {
    try {
        // console.log(taskId);

        const response = await getTaskApi(taskId);

        return response.data.task;
    } catch (error) {
        return error;
    }
})
export const addCardData = createAsyncThunk("tasks/addCardData", async ({ card, calback }: { card: IPayloadCreateTask, calback: (status: boolean) => void }) => {
    try {
        const response = await addNewTasksApi(card);
        calback(true)
        toast.success("Tạo công việc thành công", { autoClose: 2000 })
        // console.log('checkkkkk', card.kanId ?? getTaskConfig(response.data.task.status, 'id', 'key'));

        return { ...response.data.task, kanId: card.kanId ?? getTaskConfig(response.data.task.status, 'id', 'key') };
    } catch (error) {
        toast.error("Tạo công việc thất bại", { autoClose: 2000 })
        calback(false)
        return error;
    }
})
export const updateCardData = createAsyncThunk("tasks/updateCardData", async ({ card, cardId, calback }: { card: IPayloadCreateTask, cardId: string, calback?: (status: boolean) => void }) => {
    try {
        const response = await updateTasksApi(card, cardId);
        toast.success("Cập nhật công việc thành công", { autoClose: 2000 })
        const { task } = response.data
        const kanId = getTaskConfig(task.status, 'id', 'key')
        calback && calback(true)
        return { ...task, kanId, oldKanId: card.kanId };
    } catch (error) {
        toast.error("Cập nhật công việc thất bại", { autoClose: 2000 })
        return error
    }
})
export const deleteKanban = createAsyncThunk("tasks/deleteKanban", async ({ cardId, kanId }: { cardId: string, kanId: string }) => {
    try {
        const response = await deleteTasksApi(cardId);
        if (response.status) {
            toast.success("Xoá công việc thành công", { autoClose: 2000 })
            return { cardId, kanId };
        } else {
            toast.error("Xoá công việc thất bại", { autoClose: 2000 })
        }
    } catch (error) {
        toast.error("Xoá công việc thất bại", { autoClose: 2000 })
        return error;
    }
})

export const addComment = createAsyncThunk("tasks/addComment", async ({ data, callback }: { data: ICreateComment, callback?: (status: boolean) => void }) => {
    try {
        const response = await createCommentApi(data);
        if (response.status) {
            callback && callback(true)
            return response.data.comment;
        } else {
            callback && callback(false)
        }
    } catch (error) {
        callback && callback(false)
        return error;
    }
})

export const updateStatusTask = createAsyncThunk("tasks/updateStatusTask", async ({ taskId, statusId, triggerTo, callback }: { taskId: string, statusId: string, triggerTo?: 'task' | 'tasks', callback?: (status: boolean) => void }) => {
    try {
        const response = await updateStatusTaskApi({ id: taskId, status: statusId })
        if (response.status) {
            callback && callback(true)
            // return { task: response.data.task, triggerTo };
            return response.status
        } else {
            callback && callback(false)
        }
    } catch (error) {
        callback && callback(false)
        return error;
    }
})


