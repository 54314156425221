import { createSlice } from "@reduxjs/toolkit";
import {
  getStudent,
  getStudents
} from './thunk';
import { IPagination } from "types/Common/Common";

export const initialState: any = {
  loading: false,
  student: {},
  students: [],
  pagination: {},
  error: {},
};

const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(getStudent.pending, (state: any) => {
      state.loading = true
    });
    builder.addCase(getStudents.pending, (state: any) => {
      state.loading = true
    });
    builder.addCase(getStudent.fulfilled, (state: any, action: any) => {
      state.loading = false
      state.student = action.payload;
    });
    builder.addCase(getStudents.fulfilled, (state: any, action: any) => {
      state.loading = false
      state.students = action.payload.students;
      state.pagination = action.payload.pagination;
    });
  },
});

export const getStoreStudent = (state: any) => state.Student.student
export const getStoreStudentPending = (state: any) => state.Student.loading
export const getStoreStudentsPending = (state: any) => state.Student.loading
export const getStoreStudents = (state: any) => state.Student.students
export const getStorePaginationStudents = (state: any):IPagination => state.Student.pagination



export default studentSlice.reducer;