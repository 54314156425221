import SelectSearch from 'Components/widgets/SelectSearch/SelectSearch';
import { deleteFieldEmpty, fillQueryParam, getQueryParams } from 'helpers/function';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { getStoreSchoolsType1, resetUsers } from 'slices/common/reducer';
import useRole from '../../Hook/useRole';

type Props = { onFilter?: (filter: any) => void }
type TFilter = {
    name: string,
    code: string,
    status: string,
    branch_id: string,
    school_id: string,
    person_in_charge: string
}
const Filter = ({ onFilter }: Props) => {
    const [filter, setFilter] = useState<Partial<TFilter>>({})
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleOnChangeFilter = (key: string, value: string) => {
        setFilter(pre => ({ ...pre, [key]: value }))
    }
    const { listOptionStatus, listOptionSale, listOptionBranch } = useRole({ branchId: filter.branch_id })

    const listOptionSchool1 = useSelector(getStoreSchoolsType1)
    const handleClickFilter = () => {
        onFilter && onFilter(deleteFieldEmpty(filter))
        const params = getQueryParams()
        const queryParams = fillQueryParam(deleteFieldEmpty({ ...params, ...filter }))
        navigate(location.pathname + queryParams)
    }
    const resetOptionsSale = () => {
        dispatch(resetUsers())
        handleOnChangeFilter('person_in_charge', '')
    }
    useEffect(() => {
        const params = getQueryParams()
        setFilter(params)
    }, [location])
    return (
        <Row>
            <Col xxl={4} lg={4} md={6} xs={12}>
                <Label htmlFor="choices-privacy-status-input" className="form-label">Họ tên</Label>
                <Input type="text" className="form-control"
                    onChange={(e) => handleOnChangeFilter('name', e.target.value)}
                    placeholder="Họ tên"
                />
            </Col>
            <Col xxl={4} lg={4} md={6} xs={12}>
                <Label htmlFor="choices-privacy-status-input" className="form-label">Mã học sinh</Label>
                <Input type="text" className="form-control"
                    onChange={(e) => handleOnChangeFilter('code', e.target.value)}
                    placeholder="Mã học sinh"
                />
            </Col>
            <Col xxl={4} lg={4} md={6} xs={12}>
                <Label htmlFor="choices-privacy-status-input" className="form-label">Trạng thái</Label>
                {/* <Select
                    className="form-select"
                    defaultValue={''}
                    onChange={(e) => handleOnChangeFilter('status', e.target.value)}
                    options={[{
                        label: 'Trạng thái', value: '',

                    }, {
                        label: 'Hoạt động', value: '1',

                    }, {
                        label: 'Không hoạt động', value: '0',
                    }]}
                /> */}
                <SelectSearch
                    className="form-select"
                    defaultValue={''}
                    value={filter['status'] ?? ''}
                    onChange={(option: any) => {
                        handleOnChangeFilter('status', option?.value ?? '')
                    }}
                    options={listOptionStatus}
                    placeholder='Trạng thái'
                    showClear={true}
                />
            </Col>
            <Col className='mt-2' xxl={4} lg={4} md={6} xs={12}>
                <Label htmlFor="choices-privacy-status-input" className="form-label">Chi nhánh văn phòng</Label>
                <SelectSearch
                    className="form-select"
                    defaultValue={''}
                    value={filter['branch_id'] ?? ''}
                    onChange={(option: any) => {
                        handleOnChangeFilter('branch_id', option?.value ?? '')
                        if(!option?.value) {
                            resetOptionsSale()
                        }
                    }}
                    options={listOptionBranch}
                    placeholder='Chi nhánh văn phòng'
                    showClear={true}
                />
            </Col>
            <Col className='mt-2' xxl={4} lg={4} md={6} xs={12}>
                <Label htmlFor="choices-privacy-status-input" className="form-label">Cán bộ phụ trách</Label>
                <SelectSearch
                    className="form-select"
                    defaultValue={''}
                    value={filter['person_in_charge'] ?? ''}
                    onChange={(option: any) => {
                        handleOnChangeFilter('person_in_charge', option?.value ?? '')
                    }}
                    options={listOptionSale}
                    placeholder='Cán bộ phụ trách'
                    showClear={true}
                />
            </Col>
            <Col className='mt-2' xxl={4} lg={4} md={6} xs={12}>
                <Label htmlFor="choices-privacy-status-input" className="form-label">Trường học</Label>
                <SelectSearch
                    className="form-select"
                    defaultValue={''}
                    value={filter['school_id'] ?? ''}
                    onChange={(option: any) => {
                        handleOnChangeFilter('school_id', option?.value ?? '')
                    }}
                    options={listOptionSchool1}
                    placeholder='Trường học'
                    showClear={true}
                />
            </Col>
            <Col md={12} xxl={12} className='text-end mt-2'>
                <Button type="button" onClick={handleClickFilter} color="primary" style={{ paddingInline: 10 }}>
                    <i className="ri-equalizer-fill me-1 align-bottom"></i>{" "}
                    Filters
                </Button>
            </Col>

        </Row>
    );
}

export default Filter