import { APIClient } from "../api_helper";

import { AxiosResponse } from "axios";

import * as URL from "./url";
import { IComment, ICreateComment, ICreateTask, IQueryTasks, ITask, ITasks } from "types/Task/Task";

const api = new APIClient();
// API enpoint for task
export const createTask = (data: ICreateTask): Promise<AxiosResponse<{ task: ITask }>> => api.create(URL.TASK_CREATE_PATH, data)
export const updateTask = (data: ICreateTask, taskId: string): Promise<AxiosResponse<{ task: ITask }>> => api.update(`${URL.TASK_UPDATE_PATH}/${taskId}`, data)
export const deleteTasks = (taskId: string): Promise<AxiosResponse<{}>> => api.delete(`${URL.TASK_DELETE_PATH}/${taskId}`)
export const getTask = (taskId: number): Promise<AxiosResponse<{ task: ITask }>> => api.get(`${URL.TASK_DETAIL_PATH}/${taskId}`)
export const getTasks = (params: IQueryTasks): Promise<AxiosResponse<{ tasks: ITasks | any }>> => api.get(URL.TASK_LIST_PATH, params)
export const updateStatusTask = (params: { id: string, status: string }): Promise<AxiosResponse<{ task: ITask }>> => api.put(`${URL.TASK_CHANGE_STATUS_PATH}`, params)

// API enpoint for comment in task
export const createComment = (data: ICreateComment): Promise<AxiosResponse<{ comment: IComment }>> => api.create(URL.COMMENT_CREATE_PATH, data)

