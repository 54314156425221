import React from 'react'

type Props = {
    register?: any,
    options: Array<{ label: string, value: string | number }>
    defaultValue?: any
    invalid?: boolean
} & React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>

const Select = (props: Props) => {
    const { register, options, defaultValue, invalid } = props
    return (
        <select
            {...register}
            {...props}
            defaultValue={defaultValue}
            style={invalid ? { borderColor: 'var(--vz-form-invalid-color)' } : {}}>
            {
                 options.map(option => <option value={option.value}
                    selected={defaultValue == option.value}>
                    {option.label}
                </option>)
            }
        </select>
    )
}

export default Select