
import { AxiosResponse } from "axios";
import { ICreatePrincipal, IPrincipal, IUpdatePrincipal } from "types/Principal/Principal";
import * as URL from "./url";
import { APIClient } from "../api_helper";

const api = new APIClient();

export const createPrincipal = (data: ICreatePrincipal): Promise<AxiosResponse<{ principal: IPrincipal }>> => api.create(URL.PRINCIPAL_CREATE_PATH, data)
export const updatePrincipal = (data: IUpdatePrincipal, principalId: number): Promise<AxiosResponse<{ principal: IPrincipal }>> => api.update(`${URL.PRINCIPAL_UPDATE_PATH}/${principalId}`, data)
export const getPrincipal = (principalId: number): Promise<AxiosResponse<{ principal: IPrincipal }>> => api.get(`${URL.PRINCIPAL_CREATE_PATH}/${principalId}`)
export const getPrincipals = (params: any): Promise<AxiosResponse<{ principals: IPrincipal[] }>> => api.get(URL.PRINCIPAL_LIST_PATH, params)

