import React, { useEffect } from 'react';
import withRouter from '../Components/Common/withRouter';

//redux
import { useSelector } from "react-redux";
import { createSelector } from 'reselect';
import { useLocation, useNavigate } from 'react-router-dom';

const NonAuthLayout = ({ children } : any) => {
    const navigate = useNavigate()
    const location = useLocation()
    const nonauthData = createSelector(
        (state) => state.Layout,
        (layoutModeType) => layoutModeType.layoutModeType
      );
    // Inside your component
    const layoutModeType = useSelector(nonauthData);
    
    useEffect(() => {
        if (layoutModeType === "dark") {
            document.body.setAttribute("data-bs-theme", "dark");
        } else {
            document.body.setAttribute("data-bs-theme", "light");
        }
        return () => {
            document.body.removeAttribute("data-bs-theme");
        };
    }, [layoutModeType]);
    useEffect(() => {
        const user = sessionStorage.getItem('authUser')
        if(user) {
            navigate('/dashboard')
        }
    }, [location])
    
    return (
        <div>
            {children}
        </div>
    );
};

export default withRouter(NonAuthLayout);