import ErrorInput from "Components/widgets/Form/ErrorInput";
import PropTypes from "prop-types";
import { useState } from "react";
import { Input, Label, Modal, ModalBody } from "reactstrap";

const ChangePasswordModal = ({ show, onSaveClick, onCloseClick, userId }: any) => {

    const [isShowPassword, setIsShowPassword] = useState(false)
    const [isConfirmShowPassword, setIsConfirmShowPassword] = useState(false)
    const [error, setError] = useState({
        password: '',
        confirmPassword: ''
    })
    const [newPassword, setNewPassword] = useState({
        password: '',
        confirmPassword: ''
    })
    const resetError = () => {
        setError({
            password: '',
            confirmPassword: ''
        })
    }
    const handleOnChange = (key: string, value: string) => {
        setNewPassword(pre => ({ ...pre, [key]: value }))
    }
    const handleSetError = (key: string, value: string) => {
        setError(pre => ({ ...pre, [key]: value }))
    }

    return (
        <Modal fade={true} isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalBody className="py-3 px-5">
                <div className="mt-2 text-center">
                    <i className="ri-information-line display-5 text-primary"></i>
                    <div className="mt-1 fs-15 mx-4 mx-sm-5">
                        <h4>Đổi mật khẩu</h4>
                        <p className="text-muted mx-4 mb-4">
                            Bạn đã chắc chắn đổi mật khẩu ?
                        </p>
                    </div>
                </div>
                <div className="position-relative  mb-3">
                    <Label className="form-label required" htmlFor="project-title-input">Mật khẩu mới</Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">

                        <Input
                            onChange={(e) => {
                                handleOnChange('password', e.target.value)
                                if (error) {
                                    resetError()
                                }
                            }}
                            type={!isShowPassword ? 'password' : 'text'}
                            className="form-control pe-5"
                            placeholder="Nhập mật khẩu"
                            invalid={!!error.password}
                        />
                        {
                            error.password &&
                            <ErrorInput >{error.password}</ErrorInput>
                        }
                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setIsShowPassword(pre => !pre)}><i className="ri-eye-fill align-middle"></i></button>
                    </div>

                </div>
                <div className="mb-3">
                    <Label className="form-label required" htmlFor="project-title-input">Xác nhận mật khẩu</Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                        <Input
                            onChange={(e) => {
                                handleOnChange('confirmPassword', e.target.value)
                                if (error) {
                                    resetError()
                                }
                            }}
                            type={!isConfirmShowPassword ? 'password' : 'text'}
                            className="form-control pe-5"
                            placeholder="Nhập xác nhận mật khẩu"
                            invalid={!!error.confirmPassword}
                        />
                        {
                            error.confirmPassword &&
                            <ErrorInput >{error.confirmPassword}</ErrorInput>
                        }
                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setIsConfirmShowPassword(pre => !pre)}><i className="ri-eye-fill align-middle"></i></button>
                    </div>

                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button
                        type="button"
                        className="btn w-sm btn-danger "
                        data-bs-dismiss="modal"
                        onClick={onCloseClick}
                    >
                        Huỷ
                    </button>
                    <button
                        type="button"
                        className="btn w-sm btn-primary"
                        // id="delete-record"
                        onClick={() => {
                            if (!newPassword.password || !newPassword.confirmPassword) {
                                handleSetError('password', 'Bạn phải nhập mật khẩu')
                                return
                            }
                            if (newPassword.password !== newPassword.confirmPassword) {
                                handleSetError('confirmPassword', 'Mật khẩu xác nhận không giống với mật khẩu mới !')
                                return
                            }
                            onSaveClick(newPassword.password)
                        }}
                    >
                        Đồng ý
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
};

ChangePasswordModal.propTypes = {
    onCloseClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    show: PropTypes.any,
    userId: PropTypes.number
};

export default ChangePasswordModal;