import { createSlice } from "@reduxjs/toolkit";
import {
  getUser,
  getUsers
} from './thunk';
import { IPagination } from "types/Common/Common";

export const initialState: any = {
  loading: false,
  user: {},
  users: [],
  pagination: {},
  error: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(getUser.pending, (state: any) => {
      state.loading = true
    });
    builder.addCase(getUsers.pending, (state: any) => {
      state.loading = true
    });
    builder.addCase(getUser.fulfilled, (state: any, action: any) => {
      state.loading = false
      state.user = action.payload;
    });
    builder.addCase(getUsers.fulfilled, (state: any, action: any) => {
      state.loading = false
      state.users = action.payload.users;
      state.pagination = action.payload.pagination
    });
  },
});

export const getStoreUser = (state: any) => state.User.user
export const getStoreUserPending = (state: any) => state.User.loading
export const getStoreUsersPending = (state: any) => state.User.loading
export const getStoreUsers = (state: any) => state.User.users
export const getStorePaginationUsers = (state: any): IPagination => state.User.pagination



export default userSlice.reducer;