import React, { useEffect } from 'react'
import { Container } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import TasksKanban from './MainPage'

const Kanbanboard = () => {

    useEffect(() => {
        document.title = "Công việc | Công việc";
    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Công việc" pageTitle="Công việc" />
                    <TasksKanban />
                </Container>
            </div>
        </React.Fragment>


    )
}

export default Kanbanboard