import ErrorInput from 'Components/widgets/Form/ErrorInput'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Col, Row } from 'reactstrap'
import { addComment } from 'slices/thunks'
import { ICreateComment, ITask } from 'types/Task/Task'

type Props = {
    task: ITask
}

const FormComment = ({ task }: Props) => {
    const dispath = useDispatch() as any
    const commentRef = useRef<HTMLTextAreaElement>(null)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const handleAddComment = async () => {
        setLoading(true)
        if (commentRef.current?.value) {
            const payload: ICreateComment = {
                task_id: task.id,
                comment: {
                    id: '',
                    content: commentRef.current?.value
                }
            }
            dispath(addComment({
                data: payload, callback: () => {
                    setLoading(false)
                    // commentRef.current?.value
                    if (commentRef.current) {
                        commentRef.current.value = "";
                    }
                }
            }))
        } else {
            setLoading(false)
            setError('Nhận xét không được để trống!')
        }
    }
    return (
        <Row className="g-3">
            <Col lg={12}>
                <label htmlFor="exampleFormControlTextarea1" className="form-label">Thêm nhận xét</label>
                <textarea ref={commentRef} onFocus={() => error && setError('')} className="form-control bg-light border-light" id="exampleFormControlTextarea1" rows={3} placeholder="Enter comments"></textarea>
                {error && <ErrorInput>{error}</ErrorInput>}
            </Col>
            <Col xs={12} className="text-end">
                {/* <button type="button" className="btn btn-ghost-secondary btn-icon waves-effect me-1"><i className="ri-attachment-line fs-16"></i></button> */}
                <Button type="button" onClick={handleAddComment} className="btn btn-primary" disabled={loading}>Nhận xét</Button>
            </Col>
        </Row>
    )
}

export default FormComment