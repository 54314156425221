import React, { ReactNode } from 'react'

type Props = {
    children: ReactNode
}

const ErrorInput = ({ children }: Props) => {
    return (
        <div className='cs-error-message'>{children}</div>
    )
}

export default ErrorInput