import { ROLE_ADMIN, ROLE_BRANCH_MANAGER, ROLE_COLLABORATORS, ROLE_MANAGER, ROLE_PRINCIPAL, ROLE_SALE, TASK_STATUSES, TASK_STATUS_TODO } from "common/constant";
import moment from "moment";
import momentTimezone from 'moment-timezone';
import { Entries } from "types/Common";
export function transferRoleBykey(role: string) {
    let result = '';
    switch (role) {
        case ROLE_ADMIN.key: {
            result = 'Admin'
            break;
        }
        case ROLE_BRANCH_MANAGER.key: {
            result = 'Quản lý chi nhánh'
            break;
        }
        case ROLE_MANAGER.key: {
            result = 'Trưởng phòng'
            break;
        }
        case ROLE_SALE.key: {
            result = 'Nhân viên đối nội'
            break;
        }
        case ROLE_COLLABORATORS.key: {
            result = 'Cộng tác viên'
            break;
        }
        case ROLE_PRINCIPAL.key: {
            result = 'Hiệu trưởng'
            break;
        }
        default: {
            result = ''
            break;
        }
    }
    return result;
}

export function transferRoleById(role: number) {
    let result = '';
    switch (role) {
        case ROLE_ADMIN.id: {
            result = 'Admin'
            break;
        }
        case ROLE_BRANCH_MANAGER.id: {
            result = 'Quản lý chi nhánh'
            break;
        }
        case ROLE_MANAGER.id: {
            result = 'Trưởng phòng'
            break;
        }
        case ROLE_SALE.id: {
            result = 'Nhân viên đối nội'
            break;
        }
        case ROLE_COLLABORATORS.id: {
            result = 'Cộng tác viên'
            break;
        }
        case ROLE_PRINCIPAL.id: {
            result = 'Hiệu trưởng'
            break;
        }
        default: {
            result = ''
            break;
        }
    }
    return result;
}

export function getTaskConfig(search: string, type: 'id' | 'key', res: 'id' | 'key' | 'label' | 'color') {
    if (!search) return ''
    const result = TASK_STATUSES.find((task) => task[type] == search)
    return result?.[res]
}

export function parseJson(data: string | any) {
    if (!data) return null
    return JSON.parse(data)
}

export function deleteFieldEmpty(object: any) {
    const newObj = { ...object }
    for (const [key, value] of Object.entries(newObj)) {
        if (!value || value == 'null' || value == 'undefined') {
            delete newObj[key]
        }
    }
    return newObj
}

export const fillQueryParam = (query: any) => {
    let result = '?'
    const lastItem = Object.keys(query)[Object.keys(query).length - 1]
    for (const [key, value] of Object.entries(query)) {
        const appendQuery = key == lastItem ? `${key}=${value}` : `${key}=${value}&`
        result = result + appendQuery
    }
    return result

}

export function fillQueryParams(url: string, params: Record<string, string | number | boolean>): string {
    // Tạo đối tượng URL từ URL gốc
    const urlObj = new URL(url);

    // Lặp qua các key-value pairs trong params và thêm hoặc cập nhật query parameters
    Object.keys(params).forEach(key => {
        urlObj.searchParams.set(key, String(params[key]));
    });

    // Trả về URL mới dưới dạng string
    return urlObj.toString();
}

/**
 * Hàm để lấy tất cả các query parameters từ URL
 * @param url - URL chứa các query parameters
 * @returns Đối tượng chứa tất cả các query parameters
 */
export function getQueryParams(): Record<string, string> {
    const params: Record<string, string> = {};
    const urlObj = new URL(window.location.href);
    const searchParams = new URLSearchParams(urlObj.search);

    searchParams.forEach((value, key) => {
        params[key] = value;
    });

    return params;
}

export function getFileExtension(filePath: string): string {
    // Sử dụng phương thức split để tách đường dẫn thành các phần tử dựa trên dấu chấm
    const parts = filePath.split('.');

    // Nếu mảng parts có nhiều hơn 1 phần tử, phần tử cuối cùng sẽ là đuôi mở rộng
    if (parts.length > 1) {
        return parts.pop() ?? ''; // Lấy phần tử cuối cùng của mảng
    }
    return '';
}

const excelExtensions = ['xls', 'xlsx', 'xlsm', 'xlsb', 'csv'];
const docsExtensions = ['doc', 'docx', 'odt', 'pdf', 'rtf', 'txt'];
const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg'];

export function getFileType(filePath: string) {
    if (!filePath) return ''
    const extension = getFileExtension(filePath).toLowerCase();
    if (excelExtensions.includes(extension)) {
        return 'Excel';
    } else if (docsExtensions.includes(extension)) {
        return 'Docs';
    } else if (imageExtensions.includes(extension)) {
        return 'Image';
    } else {
        return 'Unknown';
    }
}

export function downloadFile(url: string, fileName: string) {
    // Tạo một thẻ <a> tạm thời
    const a = document.createElement('a');

    // Gán thuộc tính href của thẻ <a> với URL của tài nguyên cần tải xuống
    a.href = url;
    a.target = "_blank"
    // Gán thuộc tính download của thẻ <a> với tên tệp mà bạn muốn lưu lại
    a.download = fileName;

    // Thêm thẻ <a> vào tài liệu
    document.body.appendChild(a);

    // Kích hoạt sự kiện nhấp chuột trên thẻ <a>
    a.click();

    // Xóa thẻ <a> khỏi tài liệu sau khi tải xuống
    document.body.removeChild(a);
}

export const getEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;

export function getDateView(date: string) {
    if (!date) return ''
    const result = moment(new Date(date)).format("DD MMM Y");
    return result
}
export function getDateTimeView(date: string) {
    if (!date) return ''
    const result = momentTimezone(date).utc().format("DD MMM Y | HH:mm");
    return result;
}

export function formatDateTime(date: string) {
    if(!date) return ''
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

export function formatDate(date: string) {
    if(!date) return ''
    return moment(date).format('YYYY-MM-DD')
}

export function formatDateToISOString(date: string) {
    if(!date) return ''
    return momentTimezone(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
}
export function formatTime(date: string) {
    if(!date) return ''
    if(date === '00:00:00') return '00:00:00'
    const formated = moment(date).format('HH:mm:ss');
    if(formated === 'Invalid date') return '00:00:00'
    return formated
}