import { STUDENT_STATUSES } from 'common/constant'
import { useMemo } from 'react'

type Props = {
    status: number
}

const StudentStatus = ({ status }: Props) => {
    const result = useMemo(() => {
        return STUDENT_STATUSES.find(item => Number(item.id) == status)
    }, [status])
    return (
        <>
            <span className={`badge text-uppercase ${result?.color} subtle text-dark pt-1`}>{result?.label}</span>
        </>
    )
}

export default StudentStatus