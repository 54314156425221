import { APIClient } from "../api_helper";

import { AxiosResponse } from "axios";

import { ICreateEvent, IEvent, IQueryEvents } from "types/Event/Event";
import { ITasks } from "types/Task/Task";
import * as URL from "./url";

const api = new APIClient();
// API enpoint for task
export const createEvent = (data: ICreateEvent): Promise<AxiosResponse<{ event: IEvent }>> => api.create(URL.EVENT_CTEATE_PATH, data)
export const updateEvent = (data: ICreateEvent, eventId: string): Promise<AxiosResponse<{ event: IEvent }>> => api.update(`${URL.EVENT_UPDATE_PATH}/${eventId}`, data)
export const deleteEvent = (eventId: string): Promise<AxiosResponse<{}>> => api.delete(`${URL.EVENT_DELETE_PATH}/${eventId}`)
// export const getTask = (taskId: number): Promise<AxiosResponse<{ task: ITask }>> => api.get(`${URL.TASK_DETAIL_PATH}/${taskId}`)
export const getEvents = (params: IQueryEvents): Promise<AxiosResponse<{ events: IEvent[] }>> => api.get(URL.EVENT_LIST_PATH, params)

