import React, { useEffect } from 'react';
import { Container, Col, Row } from 'reactstrap';
import BreadCrumb from 'Components/Common/BreadCrumb';
import Comments from './Comments';
import Summary from './Summary';
import TimeTracking from "./TimeTracking";
import { useDispatch } from 'react-redux';
import { getTask } from 'slices/thunks';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getStoreTask, getStoreTaskLoading } from 'slices/tasks/reducer';
import { ITask } from 'types/Task/Task';
import Loader from 'Components/Common/Loader';


const TaskDetails = () => {
    const dispatch = useDispatch() as any
    const params = useParams() as { taskId: string }
    const taskDetail = useSelector(getStoreTask) as ITask
    const loading = useSelector(getStoreTaskLoading)
    useEffect(() => {
        if (params.taskId) {
            console.log('params.taskId', params.taskId);

            dispatch(getTask(Number(params.taskId)))
        }
    }, [params.taskId])

    useEffect(() => {
        document.title = "Công việc | chi tiết công việc";
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={taskDetail.title} pageTitle="Công việc" />
                    <Row>
                        {
                            !loading ? <>
                                <Col xxl={3}>
                                    <TimeTracking task={taskDetail} />
                                </Col>
                                <Col xxl={9}>
                                    <Summary task={taskDetail} />
                                    <Comments task={taskDetail} />
                                </Col>
                            </> : <>
                                {/* Đang tải ... */}
                                <Loader />
                            </>
                        }

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TaskDetails;