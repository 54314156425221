import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from "reactstrap";


import {
  deleteKanban as OnDeleteKanban,
  getListBranch,
  getTasks as onGetTasks
} from "../../../../slices/thunks";

//redux
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import SimpleBar from "simplebar-react";
// import moment from "moment"
import DeleteModal from "Components/Common/DeleteModal";
import Spinners from "Components/Common/Spinner";
import { headData } from "common/data";
import config from "config";
import { updateStatusTask } from "helpers/api/tasks";
import { getDateView, getFileType, getQueryParams, getTaskConfig } from "helpers/function";
import { ToastContainer, toast } from "react-toastify";
import { forceUpdateTasks } from "slices/tasks/reducer";
import { IKanBanTask, ITask } from "types/Task/Task";
import FormTask from "./Components/FormTask/FormTask";
import DebouncedInput from "Components/widgets/Input/DebouncedInput";
import useFilter from "./Hook/useFilter";
import SelectedUser from "./Components/SelectedUser/SelectedUser";


//Import Breadcrumb
export interface CardData {
  id?: string;
  kanId?: string,
  title?: string,
  cardId?: string;
  botId?: any,
  text?: string;
  badge1?: any[];
  userImages?: any[];
  badgeColor?: string;
  eye?: boolean;
  que?: boolean;
  clip?: boolean;
}

const TasksKanban = () => {
  const dispatch = useDispatch<any>();
  const [kanbanTasksCards, setKanbanTasksCards] = useState<{
    isModal: boolean,
    kanban: IKanBanTask | null,
    card: ITask | null
  }>({
    isModal: false,
    kanban: null,
    card: null
  })
  const location = useLocation()
  const { tasks, loading } = useSelector((state: any) => state.Tasks)

  const [isLoading, setLoading] = useState<boolean>(loading)
  const params = getQueryParams()

  const { onFilter } = useFilter()

  useEffect(() => {

    dispatch(onGetTasks(params))
  }, [dispatch, location])

  const [cards, setCards] = useState<any>([])

  useEffect(() => {
    setCards(tasks)
  }, [tasks])



  const handleDragEnd = async (result: any) => {

    if (!result.destination) return // If dropped outside a valid drop area, do nothing

    const { source, destination } = result
    // Reorder cards within the same card line
    if (source.droppableId === destination.droppableId) {
      const line = cards.find((line: any) => line.id === source.droppableId)
      const reorderedCards = Array.from(line.cards)
      const [movedCard] = reorderedCards.splice(source.index, 1)
      reorderedCards.splice(destination.index, 0, movedCard)

      const updatedLines = cards.map((line: any) => {
        if (line.id === source.droppableId) {
          return { ...line, cards: reorderedCards }
        }
        return line
      })
      dispatch(forceUpdateTasks(updatedLines))
      // setCards(updatedLines)
    } else {
      // Move card between different card lines
      console.log('==== source', source);

      const sourceLine = cards.find((line: any) => line.id === source.droppableId)
      const destinationLine = cards.find(
        (line: any) => line.id === destination.droppableId
      )
      const sourceCards = Array.from(sourceLine.cards)
      const destinationCards = Array.from(destinationLine.cards)
      console.log('result', destinationLine);

      const [movedCard] = sourceCards.splice(source.index, 1) as any
      // update status for task
      const newMovedCard = { ...movedCard, status: getTaskConfig(destinationLine.id, 'key', 'id') }
      destinationCards.splice(destination.index, 0, newMovedCard)

      const updatedLines = cards.map((line: any) => {
        if (line.id === source.droppableId) {
          return { ...line, badge: line.badge - 1, cards: sourceCards }
        } else if (line.id === destination.droppableId) {
          return { ...line, badge: line.badge + 1, cards: destinationCards }
        }
        return line
      })
      // setCards(updatedLines)
      dispatch(forceUpdateTasks(updatedLines))
      try {
        updateStatusTask({ id: result.draggableId, status: getTaskConfig(destination.droppableId, 'key', 'id')! })
      } catch {
        toast.error("Đổi trang thái thất bại", { autoClose: 2000 })
      }
    }
  }

  const toggle = () => {
    setKanbanTasksCards({
      isModal: false,
      kanban: null,
      card: null
    })
  }

  const [card, setCard] = useState<any>()

  const handleCardEdit = (card: ITask, kanban: IKanBanTask | null) => {
    setKanbanTasksCards({ isModal: true, kanban, card })
  }

  const handleAddNewCard = (kanban: IKanBanTask | null) => {
    setKanbanTasksCards({ isModal: true, kanban, card: null })
  };

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selectUserModal, setSelectUserModal] = useState({
    isShow: false
  });

  const onClickDelete = (card: any) => {
    setCard(card);
    setDeleteModal(true);
  };

  const handleDeleteCard = () => {
    if (card) {
      dispatch(OnDeleteKanban({ cardId: card.id, kanId: card.kanId }));
      setDeleteModal(false);
    }
  };


  useEffect(() => {
    dispatch(getListBranch())
  }, [dispatch])


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCard}
        onCloseClick={() => setDeleteModal(false)}
      />

      <Card>
        <CardBody>
          <Row className="g-2">
            <div className="col-lg-auto">
              <div className="hstack gap-2">
                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createboardModal" onClick={() => handleAddNewCard(null)}>
                  <i className="ri-add-line align-bottom me-1"></i>Tạo công viêc</button>
              </div>
            </div>
            <div className="col-lg-5 col-auto">
              <div className="search-box">
                {/* <input type="text" className="form-control search" id="search-task-options" placeholder="Tìm kiếm công việc..." /> */}
                <DebouncedInput value={typeof params?.task_name == 'string' ? params?.task_name : ''} className="form-control search" placeholder="Tìm kiếm công việc..." onChange={(value) => {
                  console.log('check ', value);
                  if ((params?.task_name && !value) || (!params?.task_name && value)) {
                    onFilter({
                      task_name: String(value)
                    })
                  }
                }} />
                <i className="ri-search-line search-icon"></i>
              </div>
            </div>
            <div className="col-auto ms-sm-auto">
              <div className="avatar-group" id="newMembar">
                {([]).map((item: any, key: any) => (<div className="avatar-group-item" data-bs-toggle="tooltip" key={key} data-bs-trigger="hover" data-bs-placement="top" aria-label={item.name} data-bs-original-title={item.name}>
                  <img src={item.picture} alt="" className="rounded-circle avatar-xs" />
                </div>))}
                <div onClick={() => setSelectUserModal({ isShow: true })} data-bs-toggle="modal" className="avatar-group-item cursor-pointer" >
                  <div className="avatar-xs">
                    <div className="avatar-title rounded-circle">
                      +
                    </div>
                    {
                      params.user_ids && (
                        <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{params.user_ids.split(',').length}</span>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </CardBody>
      </Card>
      <div className="kanban-container d-flex " >
        <div className="tasks-board mb-3 d-flex" id="kanbanboard">
          {
            isLoading || loading ? <Spinners setLoading={setLoading} /> :
              <DragDropContext onDragEnd={handleDragEnd}>
                {(cards || []).map((line: IKanBanTask) => {
                  return (
                    // header line
                    <div className="tasks-list" key={line.id}>
                      <div className="d-flex mb-3">
                        <div className="flex-grow-1">
                          <h6 className="fs-14 text-uppercase fw-semibold mb-0">{line.name} <small className={`badge bg-${line.color} align-bottom ms-1 totaltask-badge`}>{line.badge}</small></h6>
                        </div>
                        {/* <div className="flex-shrink-0">
                        <UncontrolledDropdown className="card-header-dropdown float-end">
                          <DropdownToggle
                            className="text-reset dropdown-btn"
                            tag="a"
                            color="white"
                          >
                            <span className="fw-medium text-muted fs-12">Priority<i className="mdi mdi-chevron-down ms-1"></i></span>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem>Priority</DropdownItem>
                            <DropdownItem>Date Added</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div> */}
                      </div>
                      {/* data */}
                      <SimpleBar className="tasks-wrapper px-3 mx-n3">
                        <div id="unassigned-task"
                          className={line.cards?.length > 0 ? "tasks" : "tasks noTask"}
                        >
                          <Droppable droppableId={line.id}>
                            {(provided: any) => {
                              return <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={line.cards?.length === 0 ? { minHeight: 150 } : {}}
                              >
                                {line.cards?.map((card: any, index: any) => {
                                  return (
                                    <Draggable
                                      key={card.id}
                                      draggableId={String(card.id)}
                                      index={index}
                                    >
                                      {(provided: any) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          // className="card task-list"
                                          className="pb-1 task-list"
                                          id={card.name + "-task"}
                                        >
                                          <div className="card task-box" id="uptask-1">
                                            <CardBody>
                                              <UncontrolledDropdown className="float-end">
                                                <DropdownToggle
                                                  className={`arrow-none badge bg-${line.color} align-bottom ms-1 totaltask-badge`}
                                                  tag="span"
                                                  color="white"
                                                >
                                                  {/* <small className={`badge bg-${line.color} align-bottom ms-1 totaltask-badge`}> */}
                                                    <i className="ri-more-fill"></i>
                                                  {/* </small> */}
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                  <DropdownItem
                                                    className="edittask-details"
                                                    onClick={() =>
                                                      handleCardEdit(card, line)
                                                    }
                                                  >
                                                    Cập nhật
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    className="deletetask"
                                                    onClick={() =>
                                                      onClickDelete({ ...card, kanId: line.id })
                                                    }
                                                  >
                                                    Xoá
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </UncontrolledDropdown>

                                              <div className="mb-3">

                                                <h6 className="fs-15 mb-0 flex-grow-1 text-truncate task-title">
                                                  <Link
                                                    to={`/tasks-detail/${card.id}`}
                                                    className="d-block"
                                                    id="task-name"
                                                  >
                                                    {card.title}
                                                  </Link>
                                                </h6>
                                              </div>
                                              <p className="text-muted">
                                                {card.text}
                                              </p>

                                              {card.media[0]?.path && getFileType(card.media[0]?.path) == 'Image' ?
                                                <div className="tasks-img rounded mb-2" style={{
                                                  backgroundImage: `url(${config.api.API_URL_STORAGE + card.media[0]?.path})`, height: "135px", backgroundSize: "cover",
                                                  backgroundPosition: "top"
                                                }}>
                                                </div> : ""}

                                              {/* progress */}
                                              {card.prowidth ?
                                                <div className="mb-3">
                                                  <div className="d-flex mb-1">
                                                    <div className="flex-grow-1">
                                                      <h6 className="text-muted mb-0"><span className="text-secondary">{card.prowidth}</span> of 100%</h6>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                      <span className="text-muted">03 Jan, 2022</span>
                                                    </div>
                                                  </div>
                                                  <div className="progress rounded-3 progress-sm">
                                                    <div className={`progress-bar bg-${card.procolor}`} role="progressbar" style={{ width: `${card.prowidth}` }}></div>
                                                  </div>
                                                </div>
                                                : ""
                                              }
                                              {/* badge & image */}
                                              {
                                                <div className="d-flex align-items-center">
                                                  <div className="flex-grow-1">
                                                    {/* {card.badge1.map((badgeText: any, index: any) => (
                                                  <span key={index} className="badge bg-primary-subtle text-primary me-1">
                                                    {badgeText}
                                                  </span>
                                                ))} */}
                                                  </div>
                                                  <div className="flex-shrink-0">
                                                    <div className="avatar-group">
                                                      {/* {card.userImages.map((picturedata: any, idx: any) => (
                                                    <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Alexis" key={idx}>
                                                      <img src={picturedata.img} alt="" className="rounded-circle avatar-xxs" />
                                                    </Link>
                                                  ))} */}
                                                      {headData[index]?.picture ? <img
                                                        src={headData[index]?.picture || config.api.API_URL_STORAGE + card?.user.avatar}
                                                        alt={card?.user.name}
                                                        className="avatar-xs rounded-circle me-2"
                                                      /> :
                                                        <div className="flex-shrink-0 avatar-xs me-2">
                                                          <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13" >
                                                            {card?.user.name.charAt(0) + card?.user.name.split(" ").slice(-1).toString().charAt(0)}
                                                          </div>
                                                        </div>}
                                                    </div>
                                                  </div>
                                                </div>
                                              }

                                            </CardBody>
                                            {/* bottom */}
                                            <div className="card-footer border-top-dashed">
                                              <div className="d-flex">
                                                <div className="flex-grow-1">
                                                  <span className="text-muted"><i className="ri-time-line align-bottom"></i> {getDateView(card.end_date)}</span>
                                                </div>
                                                <div className="flex-shrink-0">
                                                  <ul className="link-inline mb-0">
                                                    {/* <li className="list-inline-item">
                                                  <Link to="#" className="text-muted"><i className="ri-eye-line align-bottom"></i> {card.eye}</Link>
                                                </li> */}
                                                    <li className="list-inline-item">
                                                      <Link to="#" className="text-muted"><i className="ri-question-answer-line align-bottom"></i> {card.comments?.length}</Link>
                                                    </li>
                                                    <li className="list-inline-item">
                                                      <Link to="#" className="text-muted"><i className="ri-attachment-2 align-bottom"></i> {card.media?.length}</Link>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>

                                            {card.botpro ?
                                              <div className="progress progress-sm">
                                                <div className={`progress-bar bg-${card.botprocolor}`} role="progressbar" style={{ width: `${card.botpro}` }} ></div>
                                              </div>
                                              : ""
                                            }

                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                })}
                                {provided.placeholder}
                              </div>
                            }}
                          </Droppable>
                        </div>
                      </SimpleBar>
                      <div className="my-2 mt-0">
                        <button className="btn btn-soft-info w-100" data-bs-toggle="modal" data-bs-target="#creatertaskModal" onClick={() => handleAddNewCard(line)}>Tạo công việc</button>
                      </div>
                    </div>
                  )
                })}
              </DragDropContext>
          }
        </div>
      </div>

      {
        kanbanTasksCards.isModal && (
          <FormTask modal={kanbanTasksCards.isModal} kanban={kanbanTasksCards.kanban} toggle={toggle} isEdit={!!kanbanTasksCards.card} card={kanbanTasksCards.card} />
        )
      }

      {
        selectUserModal.isShow && (
          <SelectedUser isOpen={selectUserModal.isShow} handleToggle={() => setSelectUserModal(pre => ({ isShow: !pre.isShow }))} />
        )
      }

      <ToastContainer />
    </React.Fragment>
  )
}

export default TasksKanban
