import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from 'react';
import { UseFormRegisterReturn, useForm } from "react-hook-form";
import { Alert, Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row, Spinner } from 'reactstrap';
import * as yup from "yup";
import ParticlesAuth from "../../plugins/AuthenticationInner/ParticlesAuth";
//redux
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
// Formik validation

// actions
import { loginUser } from "../../slices/thunks";

import { createSelector } from 'reselect';
import logoLight from "../../assets/images/logo-light.png";
import { TPayloadLogin } from "types/Auth/Login";
//import images

const createSchema = () => {
    return yup.object().shape({
        email: yup.string().email("Email không hợp lệ").required('Email không được để trống'),
        password: yup.string().required('Mật khẩu không được để trống')
    })
}


const Login = (props: any) => {
    const schema = createSchema();
    const dispatch: any = useDispatch();
    const navigate = useNavigate()
    const selectLayoutState = (state: any) => state;
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const loginpageData = createSelector(
        selectLayoutState,
        (state) => {
            return ({
                user: state.Login.user,
                error: state.Login.error,
                loading: state.Login.loading,
                errorMsg: state.Login.errorMsg,
                response: state.Login.response,
            })
        }
    );

    const { user, error, loading } = useSelector(loginpageData);
    const [passwordShow, setPasswordShow] = useState<boolean>(false);

    const onSave = (values: TPayloadLogin) => {
        console.log('values', values);
        dispatch(loginUser(values));
    }

    const registerRs = (fieldName: any, options = {}) => {
        type NewType = UseFormRegisterReturn;

        const registeredField: Partial<NewType> = register(fieldName, options);
        const ref = registeredField.ref;
        delete registeredField.ref;
        return { ...registeredField, innerRef: ref };
    }
    useEffect(() => {
        if (Object.keys(user).length > 0) {
            navigate('/dashboard')
        }
    }, [user])

    useEffect(() => {
        document.title = "Basic SignIn | Velzon - React Admin & Dashboard Template";
    }, [])

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={'/logo.png'} alt="" height="100" />
                                            {/* <img src={} alt="" height="20" /> */}
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Xin chào !</h5>
                                            {/* <p className="text-muted">Đăng nhâp vào NGUYEN GLOBAL</p> */}
                                        </div>
                                        {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={handleSubmit(onSave)}
                                            >

                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">Email</Label>
                                                    <Input
                                                        id="exampleEmail"
                                                        type="email"
                                                        {...registerRs('email')}
                                                        invalid={!!errors.email}
                                                        placeholder="Email"
                                                    />
                                                    {errors.email && (
                                                        <FormFeedback type="invalid">{errors.email.message}</FormFeedback>
                                                    )}
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="password-input">Mât khẩu</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            {...registerRs('password')}
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Mât khẩu"
                                                            invalid={!!errors.password}
                                                        />
                                                        {errors.password && (
                                                            <FormFeedback type="invalid">{errors.password.message}</FormFeedback>
                                                        )}
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                {/* <div className="form-check">
                                                    <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                    <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                </div> */}

                                                <div className="mt-4">
                                                    <Button color="primary"
                                                        disabled={loading && true}
                                                        className="btn btn-primary w-100" type="submit">
                                                        {loading && <Spinner size="sm" className='me-2'> Đang xử lý... </Spinner>}
                                                        Đăng nhập
                                                    </Button>
                                                </div>

                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);