import React, { ReactChild, ReactNode } from 'react'
import FeatherIcon from "feather-icons-react";

type Props = {
    children: ReactNode,
    iconLeft?: string
    iconRight?: string
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

const Button = (props: Props) => {
    return (
        <button {...props} className={`${props.iconLeft || props.iconRight ? 'd-flex align-items-center justify-content-center gap-2' : ''} ${props.className}`}>
            {props.iconLeft &&
                <FeatherIcon icon={props.iconLeft} className="icon-sm" />
            }
            <span>
                {props.children}
            </span>
            {props.iconRight &&
                <FeatherIcon icon={props.iconRight} className="icon-sm" />
            }
        </button>
    )
}

export default Button