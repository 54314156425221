import { createAsyncThunk } from "@reduxjs/toolkit";
import { getProvinces, getDistricts, getWards, getRoles, getBranchs, getManagers, getSaleByBranchs, getSchools, getUsersByBranchAndRole } from "helpers/api/common";
import { transferRoleBykey } from "helpers/function";
import { TGetUserByBranchAndRoleInput } from "types/Common/Common";
import { IUser } from "types/User/User";

export const getListProvince = createAsyncThunk("Common/getListProvince", async () => {
    try {
        const response = await getProvinces();
        return response.data.provinces.map((province) => ({ label: province.name, value: province.id }));
    } catch (error) {
        return error;
    }
});

export const getListDistrict = createAsyncThunk("Common/getListDistrict", async (province_id: number) => {
    try {
        const response = await getDistricts(province_id);
        return response.data.districts.map((district) => ({ label: district.name, value: district.id }));
    } catch (error) {
        return error;
    }
});

export const getListWard = createAsyncThunk("Common/getListWard", async (district_id: number) => {
    try {
        const response = await getWards(district_id);
        return response.data.wards.map((ward) => ({ label: ward.name, value: ward.id }));
    } catch (error) {
        return error;
    }
});

export const getListRole = createAsyncThunk("Common/getListRole", async () => {
    try {
        const response = await getRoles();
        return response.data.roles.map((role) => {
            const roleName = transferRoleBykey(role.name)
            return { label: roleName, value: role.id }
        });
    } catch (error) {
        return error;
    }
});

export const getListBranch = createAsyncThunk("Common/getListBranch", async () => {
    try {
        const response = await getBranchs();
        return response.data.branches.map((branch) => ({ label: branch.name, value: branch.id }));
    } catch (error) {
        return error;
    }
});

export const getListManager = createAsyncThunk("Common/getListManager", async (branchId: string) => {
    try {
        const response = await getManagers(branchId);
        return response.data.users.map((user) => ({ label: user.name, value: user.id }));
    } catch (error) {
        return error;
    }
});

export const getListSaleByBranch = createAsyncThunk("Common/getListSaleByBranch", async () => {
    try {
        const response = await getSaleByBranchs();
        return response.data.sales.map((sale) => ({ label: sale.name, value: sale.id }));
    } catch (error) {
        return error;
    }
});

export const getListSchoolByType = createAsyncThunk("Common/getListSchoolByType", async (params?: any) => {
    try {
        const response = await getSchools(params);
        return response.data.schools.map((school) => ({ label: school.name, value: school.id, type: school.type }));
    } catch (error) {
        return error;
    }
});

export const getListUserByBranchAndRole = createAsyncThunk("Common/getListUserByBranchAndRole", async (params: {
    customRespon?: (user: IUser) => any
} & TGetUserByBranchAndRoleInput) => {
    try {
        const response = await getUsersByBranchAndRole(params);
        if (response.data.users) {
            if (params.customRespon) {
                return response.data.users.map(params.customRespon);
            } else {
                return response.data.users.map((user) => ({ label: user.name, value: user.id }));
            }
        } else {
            return []
        }
    } catch (error) {
        return error;
    }
});
