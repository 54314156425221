export const REGEX_PHONE = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const OPTIONS_GENDER = [{
    label: 'Nam',
    value: '1'
}, {
    label: 'Nữ',
    value: '0'
}]

export const ROLE_ADMIN = {
    key: 'admin',
    id: 1
};
export const ROLE_BRANCH_MANAGER = {
    key: 'branch_manager',
    id: 2
};
export const ROLE_MANAGER = {
    key: 'manager',
    id: 3
};
export const ROLE_SALE = {
    key: 'sale',
    id: 4
};
export const ROLE_COLLABORATORS = {
    key: 'collaborators',
    id: 5
};
export const ROLE_PRINCIPAL = {
    key: 'principal',
    id: 6
};

/**
 *  id: '0',
    key: 'to_do',
    label: 'Mới',
    color: 'success'
 */
export const TASK_STATUS_TODO = {
    id: '0',
    key: 'to_do',
    label: 'Mới',
    color: 'success'
}
/**
 *  id: '1',
    key: 'in_progress',
    label: 'Đang làm',
    color: 'success'
 */
export const TASK_STATUS_IN_PROGRESS = {
    id: '1',
    key: 'in_progress',
    label: 'Đang làm',
    color: 'success'
}
/**
 *  id: '2',
    key: 'review',
    label: 'Đang duyệt',
    color: 'success'
 */
export const TASK_STATUS_REVIEW = {
    id: '2',
    key: 'review',
    label: 'Đang duyệt',
    color: 'success'
}
/**
 *  id: '3',
    key: 'completed',
    label: 'Hoàn thành',
    color: 'success'
 */
export const TASK_STATUS_COMPLETED = {
    id: '3',
    key: 'completed',
    label: 'Hoàn thành',
    color: 'success'
}
/**
 * [TASK_STATUS_TODO, TASK_STATUS_IN_PROGRESS, TASK_STATUS_REVIEW, TASK_STATUS_COMPLETED]
 */
export const TASK_STATUSES = [TASK_STATUS_TODO, TASK_STATUS_IN_PROGRESS, TASK_STATUS_REVIEW, TASK_STATUS_COMPLETED]
export const TASK_STATUSES_KEY = [TASK_STATUS_TODO.key, TASK_STATUS_IN_PROGRESS.key, TASK_STATUS_REVIEW.key, TASK_STATUS_COMPLETED.key]

/**
 * 0: tạo mới,
 */
export const STUDENT_STATUS_0 = {
    id: '0',
    label: 'Tạo mới',
    color: 'bg-info-subtle'
}

/**
 * 1: Tư vấn,
 */
export const STUDENT_STATUS_1 = {
    id: '1',
    label: 'Tư vấn',
    color: 'bg-info-subtle'
}
/**
 * 2: Huỷ tư vấn,
 */
export const STUDENT_STATUS_2 = {
    id: '2',
    label: 'Huỷ tư vấn',
    color: 'bg-danger-subtle'
}
/**
 * 3: Chốt tư vấn,
 */
export const STUDENT_STATUS_3 = {
    id: '3',
    label: 'Chốt tư vấn',
    color: 'bg-warning-subtle'
}
/**
 * 4: Huỷ tư vấn lần 2,
 */
export const STUDENT_STATUS_4 = {
    id: '4',
    label: 'Huỷ tư vấn lần 2',
    color: 'bg-danger-subtle'
}
/**
 * 5: Chốt hợp đồng,
 */
export const STUDENT_STATUS_5 = {
    id: '5',
    label: 'Chốt hợp đồng',
    color: 'bg-success-subtle'
}
/**
 * 6: Huỷ hợp đồng,
 */
export const STUDENT_STATUS_6 = {
    id: '6',
    label: 'Huỷ hợp đồng',
    color: 'bg-danger-subtle'
}
/**
 * 7: Xuất cảnh,
 */
export const STUDENT_STATUS_7 = {
    id: '7',
    label: 'Xuất cảnh',
    color: 'bg-success-subtle'
}
/**
 * [STUDENT_STATUS_0,
    STUDENT_STATUS_1,
    STUDENT_STATUS_2,
    STUDENT_STATUS_3,
    STUDENT_STATUS_4,
    STUDENT_STATUS_5,
    STUDENT_STATUS_6,
    STUDENT_STATUS_7,]
 */
export const STUDENT_STATUSES = [
    STUDENT_STATUS_0,
    STUDENT_STATUS_1,
    STUDENT_STATUS_2,
    STUDENT_STATUS_3,
    STUDENT_STATUS_4,
    STUDENT_STATUS_5,
    STUDENT_STATUS_6,
    STUDENT_STATUS_7,
]

export const USER_STATUSES = [{
    label: 'Hoạt động', value: '1',

}, {
    label: 'Không hoạt động', value: '0',
}]

