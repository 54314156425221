import classnames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, TabContent, Table, TabPane, UncontrolledDropdown } from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import ThumbFile from 'Components/widgets/ThumbFile/ThumbFile';
import config from 'config';
import { downloadFile, getDateTimeView, getDateView, getFileType } from 'helpers/function';
import { ITask } from 'types/Task/Task';
import avatar7 from "../../../../assets/images/users/avatar-7.jpg";
import FormComment from './FormComment';
type Props = {
    task: ITask
}
const Comments = ({ task }: Props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab: any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    return (
        <React.Fragment>
            <Card>
                <CardHeader>
                    <div>
                        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                            <NavItem>
                                <NavLink
                                    className={'cursor-pointer ' + classnames({ active: activeTab === '1' })}
                                    onClick={(e) => { e.preventDefault(); toggleTab('1'); }}
                                >
                                    Nhận xét ({task.comments?.length})
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={'cursor-pointer ' + classnames({ active: activeTab === '2' })}
                                    onClick={(e) => { e.preventDefault(); toggleTab('2'); }}
                                >
                                    Tài liêu ({task.media?.length})
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </CardHeader>
                <CardBody>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <h5 className="card-title mb-4">Nhận xét</h5>
                            <SimpleBar style={{ height: "508px" }} className="px-3 mx-n3 mb-2">
                                {
                                    task.comments && task.comments?.map(comment => (
                                        <div className="d-flex mb-2" key={`comment-${comment.id}`}>
                                            <div className="flex-shrink-0">
                                                <img src={avatar7} alt="" className="avatar-xs rounded-circle" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5 className="fs-14 text-body"><Link to="/pages-profile" className='text-reset'>{comment?.user?.name ?? '--'}</Link> <small className="text-muted">{getDateTimeView(comment.created_at)}</small></h5>
                                                <p className="text-muted">{comment.content}</p>
                                            </div>
                                        </div>
                                    ))
                                }

                            </SimpleBar>
                            <div className="mt4">
                                <FormComment task={task} />
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="table-responsive table-card" style={{ height: "508px" }}>
                                <Table className="table-borderless align-middle mb-0">
                                    <thead className="table-light text-muted">
                                        <tr>
                                            <th scope="col">Tên tài liêu</th>
                                            <th scope="col">Loại</th>
                                            <th scope="col">Ngày tạo</th>
                                            <th scope="col">Hành động</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            task.media && task.media.map((item) => (
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="avatar-sm">
                                                                <ThumbFile file={{ path: item.path, full_path: config.api.API_URL_STORAGE + item.path, file_name: item.name }} />
                                                            </div>
                                                            <div className="ms-3 flex-grow-1">
                                                                <h6 className="fs-15 mb-0"><Link to="#">{item.name}</Link></h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{getFileType(item.path)}</td>
                                                    <td>{getDateView(item.created_at)}</td>
                                                    <td>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle tag="a" href="#" className="btn btn-light btn-icon">
                                                                <i className="ri-equalizer-fill"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-end" style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(0px, 23px)" }}>
                                                                {/* <li><DropdownItem><i className="ri-eye-fill me-2 align-middle text-muted"></i>Xem</DropdownItem></li> */}
                                                                <li><DropdownItem onClick={() => downloadFile(config.api.API_URL_STORAGE + item.path, item.name)}><i className="ri-download-2-fill me-2 align-middle text-muted"></i>Tải xuống</DropdownItem></li>
                                                                {/* <li className="dropdown-divider"></li>
                                                                <li><DropdownItem><i className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>Xoá</DropdownItem></li> */}
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default Comments;