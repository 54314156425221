
import config from "config";

type Props = {
    avatar?: string
    name?: string
}

const Avatar = ({ avatar, name }: Props) => {
    return (
        <>
            {avatar ? <img
                src={config.api.API_URL_STORAGE + avatar}
                alt={avatar}
                className="avatar-xs rounded-circle me-2"
            /> :
                <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                        {name && name.charAt(0) + name.split(" ").slice(-1).toString().charAt(0)}
                    </div>
                </div>}

        </>
    )
}

export default Avatar